import { useNavigate } from 'react-router-dom';
import useUserContext from '../../../api/UserContext';
import Headline from '../../../components/ui/Headline';
import { useLoadingContext } from '../../../context/LoadingContext';
import routes from '../../../config/routes';
import { set } from 'lodash';

const FreeTrialRequest = () => {
	const { startFreeTrial } = useUserContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const navigateTo = useNavigate();
	const isFreeTrialActive = false;
	const isFreeTrialExpired = false;

	const handleFreeTrialRequest = () => {
		setLoadingMessage('Un momento por favor...');
		setLoading(true);
		startFreeTrial()
			.then((isActive) => {
				if (isActive) {
					setLoadingMessage('Te redireccionaremos en unos segundos...');
					setLoading(true);
					setTimeout(() => {
						navigateTo(routes.APP.INDEX);
					}, 5000);
				} else {
					setLoading(false);
					// TODO: HANDLE ERROR
					// TODO: I need to setup an error modal portal or modify the spinner to show error messages and change color to red
				}
			})
			.catch((error) => {
				console.error('Error updating user document: ', error);
			});
	};
	if (isFreeTrialExpired) {
		return (
			<p>
				<strong>Nota:</strong> Tu período de prueba ha terminado. Por favor
				completa tu pago para continuar usando nuestra plataforma.
			</p>
		);
	}

	if (isFreeTrialActive) {
		return (
			<p>
				<strong>Nota:</strong> La modalidad de tu cuenta es de prueba y está
				limitada a hacer simulaciones de un solo ENURM (2020). Debes completar
				tu pago para usar tener acceso al contenido premium y continuar usando
				nuestra plataforma sin interrumpciones.
			</p>
		);
	}

	return (
		<div className='flex flex-col justify-center my-4'>
			<Headline title='¿No estás list@ para inscribirte?' type='h6' />
			<p>
				Puedes probar nuestra plataforma <strong>completamente GRATIS</strong>{' '}
				pero trandrás la limitacion de que{' '}
				<strong> solo podrás hacer simulaciones del ENURM 2020</strong>. Además,
				puedes perder la oportunidad de aprovechar la oferta actual. ¡No dejes
				pasar la oferta!
			</p>
			<div className='flex justify-end'>
				<button onClick={handleFreeTrialRequest} className='btn bg-brand-500'>
					Prueba de Acceso Gratis
				</button>
			</div>
		</div>
	);
};
export default FreeTrialRequest;
