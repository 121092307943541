interface AnswerProps {
	isSelectedAnswerCorrect: boolean;
	selectedAnswer: string;
	correctAnswer: string;
}
const Answer = ({
	isSelectedAnswerCorrect,
	correctAnswer,
	selectedAnswer
}: AnswerProps) => {
	return (
		<div>
			<p
				className={
					isSelectedAnswerCorrect
						? 'flex items-center text-green-700'
						: 'flex items-center text-red-600'
				}>
				<i className='material-icons mr-2'>
					{isSelectedAnswerCorrect ? 'check_box' : 'error'}
				</i>
				<strong className='mr-2'>Tu selección:</strong> {selectedAnswer}
			</p>
			{!isSelectedAnswerCorrect && (
				<p className='flex items-center'>
					<i className='material-icons mr-2'>forward</i>
					<strong className='mr-2'>Respuesta Correcta:</strong> {correctAnswer}
				</p>
			)}
		</div>
	);
};
export default Answer;
