import MaterialIcons from './icons/MaterialIcons';

// //tailwindcomponents.com/component/buttons
interface ButtonTypes {
	classes?: string;
	bgColor: string;
	hoverBgColor: string;
	icon?: string | null;
	iconFam?: string;
	iconPosition?: 'left' | 'right';
	isDisabled?: boolean;
	textColor?: string;
	title: string;
	type?: 'button' | 'submit' | 'reset';
	handleClick: (value: any) => void;
}

const Button = ({
	classes = '',
	bgColor,
	hoverBgColor,
	icon = null,
	iconFam = 'material-icons',
	iconPosition = 'left',
	isDisabled = false,
	textColor = '',
	type = 'button',
	handleClick,
	title
}: ButtonTypes) => {
	// ;
	// TODO: use this video to conditionally pass material icon data or other icon data: https://www.youtube.com/watch?v=9i38FPugxB8
	const ringClasses = 'focus:ring-2 focus:ring-offset-1 focus:ring-brand-300';

	if (icon !== null && iconFam === 'material-icons') {
		if (iconPosition === 'left') {
			return (
				<button
					className={`flex items-center ${bgColor} ${hoverBgColor} ${textColor} ${classes} ${ringClasses}`}
					onClick={handleClick}
					type={type}
					disabled={isDisabled}>
					<MaterialIcons icon={icon} classes='mr-2' />
					{title}
				</button>
			);
		}

		if (icon === 'send' && iconPosition === 'right') {
			return (
				<button
					className={`flex items-center ${bgColor} ${hoverBgColor} ${textColor} ${classes} ${ringClasses}`}
					onClick={handleClick}
					type={type}
					disabled={isDisabled}>
					{title}
					<svg
						className='ml-2'
						fill='currentColor'
						xmlns='http://www.w3.org/2000/svg'
						height='24px'
						viewBox='0 -960 960 960'
						width='24px'>
						<path d='M120-160v-240l320-80-320-80v-240l760 320-760 320Z' />
					</svg>
				</button>
			);
		}
		return (
			<button
				className={`flex items-center ${bgColor} ${hoverBgColor} ${textColor} ${classes} ${ringClasses}`}
				onClick={handleClick}
				type={type}
				disabled={isDisabled}>
				{title}
			</button>
		);
	}
	return (
		<button
			className={`flex items-center ${bgColor} ${hoverBgColor} ${textColor} ${classes} ${ringClasses}`}
			onClick={handleClick}
			type={type}
			disabled={isDisabled}>
			{title}
		</button>
	);
};

export default Button;
