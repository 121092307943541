import parseHTML from 'html-react-parser';
import { removeEmptyParagraphsbyQuill } from '../../../../utils/helpers';
import AudioExplanation from '../AudioExplanation';
import ImageDisplay from '../ImageDisplay';
import Fuente from './Fuente';
interface ExplanationProps {
	id: string;
	explanation: string;
	fuentes?: string;
	audioFileName: string;
	imagePathName: string;
	showImageInQuestion: boolean;
}

const Explanation = ({
	explanation,
	fuentes,
	audioFileName,
	imagePathName,
	showImageInQuestion
}: ExplanationProps) => {
	return (
		<details>
			<summary className='text-right'>Ver Explicación</summary>
			{/* <h5>Explicación:</h5> */}
			<AudioExplanation audioFileName={audioFileName} />
			{!showImageInQuestion && <ImageDisplay imagePathName={imagePathName} />}
			<div className='explanation'>
				{parseHTML(removeEmptyParagraphsbyQuill(explanation))}
			</div>
			<Fuente fuentes={fuentes} />
		</details>
	);
};
export default Explanation;
