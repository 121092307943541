import { type RefObject, useEffect, useRef, useState } from 'react';
import { useFlashcardContext } from '../../../../api/FlashcardsContext';
import MainSection from '../../../../layout/app/dashboard/MainSection';
import { useLoadingContext } from '../../../../context/LoadingContext';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import useUserContext from '../../../../api/UserContext';
import type { Flashcard } from '../../../../data/interface-flashcard';
import TextInputForm from '../../../../components/forms/TextInputForm';
import type { Category } from '../../../../data/interface-question';
import CountCard from '../../../../components/ui/CountCard';
import { Link } from 'react-router-dom';
import {
	InformationCircleIcon,
	PlusCircleIcon
} from '@heroicons/react/24/outline';
import Headline from '../../../../components/ui/Headline';
import ListOfFlashcards from './ListOfFlashcards';
import routes from '../../../../config/routes';

// ...

const Manager = () => {
	const userSearchRef: RefObject<HTMLInputElement> = useRef(null);
	const { currentUser } = useUserContext();
	const { categories, getCategories } = useQuestionBankContext();
	const { flashcards, getFlashcards, getAllFlashcards } = useFlashcardContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();

	const [showCategoryCount, setShowCategoryCount] = useState(false);
	const [filteredFlashcards, setFilteredFlashcards] = useState<Flashcard[]>([]);
	const [availableFlashcards, setAvailableFlashcards] = useState<Flashcard[]>(
		[]
	);
	const [userID, setUserID] = useState('');

	useEffect(() => {
		setLoadingMessage('Cargando flashcards...');
		setLoading(true);
		loadRequiredData()
			.then(() => {
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
			});

		// eslint-disable-next-line
	}, []);

	const loadRequiredData = async () => {
		await getCategories();
		await getFlashcards();

		// if (currentUser !== null) {
		// if (currentUser.uid !== undefined) {
		// getFlashcardsByUser(currentUser.uid);
		// }
		// }
		loadFlashcards();
	};

	const handleGetAll = () => {
		setLoadingMessage('Cargando flashcards...');
		setLoading(true);
		getAllFlashcards()
			.then(() => {
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				console.error(error);
			});
	};

	const loadFlashcards = () => {
		if (flashcards === undefined) return;
		// const cards = flashcards.filter((f: Flashcard) => {
		// return f.authorID === uid || f.createdBy === 'PrepMedRD';
		// });
		setAvailableFlashcards(flashcards);
		setFilteredFlashcards(flashcards);
	};

	const getFlashcardsByUser = () => {
		localStorage.setItem('flashcard-user', JSON.stringify(userID));
		handleFlashcardsByUser()
			.then(() => {
				loadFlashcards();
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const handleFlashcardsByUser = async () => {
		setLoadingMessage('Cargando flashcards...');
		setLoading(true);
		await getFlashcards(userID);
	};

	const getNonPrepMedFlashcards = () => {
		localStorage.removeItem('flashcard-user');
		setUserID('');

		const nonPrepMedCards = availableFlashcards.filter((f: Flashcard) => {
			return f.createdBy !== 'PrepMedRD';
		});

		setAvailableFlashcards(nonPrepMedCards);
		setFilteredFlashcards(nonPrepMedCards);
	};

	const clearUser = () => {
		localStorage.removeItem('flashcard-user');
		setUserID('');
		setAvailableFlashcards([]);
		// getFlashcards('clear-flashcards');
	};

	const filterBy = (s: string) => {
		const arr: Flashcard[] = availableFlashcards.filter((f) => {
			return f.front.includes(s) || f.back.includes(s);
		});
		setFilteredFlashcards(arr);
	};

	// if (flashcards === undefined || categories === undefined) return null;

	return (
		<MainSection title='Crea o Edita tus Flashcards'>
			<div className='flex flex-col sm:flex-row justify-between items-center sm:items-stretch gap-y-4'>
				<CountCard title='Flashcards' number={availableFlashcards.length} />
				<div className='flex flex-col items-center md:items-end md:mt-4 gap-y-4'>
					<Link
						to={routes.APP.FLASHCARDS.CREATE}
						className='inline-flex items-center gap-x-1.5 rounded-md bg-brand-600 px-3 py-2   text-white shadow-sm hover:bg-brand-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600 h-10'>
						<PlusCircleIcon className='-ml-0.5 h-5 w-5' aria-hidden='true' />
						Crea tus Flashcards
					</Link>

					<button
						className='inline-flex items-center gap-x-1.5 rounded-md bg-brand-50 px-2.5 py-1.5 text-sm font-semibold text-brand-600 shadow-sm hover:bg-brand-100 h-10'
						onClick={() => setShowCategoryCount(!showCategoryCount)}>
						<InformationCircleIcon
							className='-ml-0.5 h-5 w-5'
							aria-hidden='true'
						/>
						{showCategoryCount ? 'Esconder Conteo' : 'Ver Conteo por Categoría'}
					</button>
				</div>
			</div>

			{/* CATEGORY COUNT */}
			{showCategoryCount && (
				<div>
					<Headline title='Flashcards por Categoría' type='h6' />
					<div className='grid grid-cols-2 md:grid-cols-4 gap-4 xl:flex justify-evenly'>
						{categories !== undefined &&
							categories.length > 0 &&
							categories
								.filter(
									(category: Category) => category.id !== 'M8rTWnWd2Wh9WLZtsMQn'
								) // Evaluacion diagnostica 1
								.filter(
									(category: Category) => category.id !== 'DpVZVQbCpngLsSuCXX8J'
								) // Evaluacion diagnostica 2
								.sort((a: Category, b: Category) => {
									if (a.name < b.name) {
										return -1;
									}
									if (a.name > b.name) {
										return 1;
									}
									return 0;
								})
								.map((c: Category) => {
									return (
										<div
											key={c.id}
											className='inline-flex items-center rounded-full bg-brand-50 px-4 py-2 text-xs font-medium text-brand-800 ring-1 ring-inset ring-brand-700/10 mt-4 m-auto'>
											{c.name}
											{': '}
											<span>
												{
													availableFlashcards?.filter((f) =>
														f.categories?.includes(c.id)
													).length
												}
											</span>
										</div>
									);
								})}
					</div>
				</div>
			)}

			<hr className='w-3/4 mx-auto my-8' />
			<div className='flex flex-col '>
				<TextInputForm
					id='searchFlashcards'
					placeholder='Buscar Flashcards'
					handleChange={(e: string) => filterBy(e)}
				/>
				{/* ADMIN SEARCH */}
				{(currentUser?.isAdmin ?? false) && (
					<>
						<hr className='w-3/4 mx-auto my-8' />
						<div className='flex justify-between items-center mt-2 mb-0'>
							<label
								htmlFor='search'
								className='flex justify-center items-center mr-4'>
								<i className='material-icons mr-2'>search</i> Filter by user
							</label>
							<input
								type='text'
								name='filter-by-user'
								id='search'
								className='mt-1 py-2 px-3 block w-3/4 rounded-md border border-gray-300 leading-tight shadow-sm text-gray-600 focus:ring-brand-200 focus:border-brand-200'
								ref={userSearchRef}
								placeholder='Paste user ID here...'
								onChange={(e) => setUserID(e.target.value)}
								// onFocus={() => setSearching(true)}
								// onBlur={() => setSearching(false)}
								defaultValue={userID}
								autoComplete='off'
							/>
						</div>
						<div className='flex justify-end mt-4'>
							<button
								className='btn bg-brand-600 mr-2'
								onClick={() => getFlashcardsByUser()}>
								Search by User
							</button>
							<button
								className='bg-brand-100 text-sm text-brand-600 shadow-sm hover:bg-brand-200 hover:text-brand-700 hover:font-semibold h-10 mr-2 px-2.5 py-1.5 '
								onClick={() => getNonPrepMedFlashcards()}>
								Get Non PM
							</button>
							<button className='btn bg-orange-500 mr-2' onClick={handleGetAll}>
								Get All
							</button>
							<button className='btn bg-red-500' onClick={() => clearUser()}>
								Clear User
							</button>
						</div>
						<hr className='w-3/4 mx-auto my-8' />
					</>
				)}
				<ListOfFlashcards
					flashcards={filteredFlashcards}
					categories={
						categories !== undefined && categories.length > 0 ? categories : []
					}
					isAdmin={currentUser?.isAdmin ?? false}
				/>
			</div>
		</MainSection>
	);
};
export default Manager;
