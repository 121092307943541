import Swal from 'sweetalert2';
import Modal from '../../../components/modals/Modal';
import useQuestionBankContext from '../../../api/QuestionBankContext';
import { useLoadingContext } from '../../../context/LoadingContext';
import type {
	IQuestionIssues,
	Question
} from '../../../data/interface-question';
import { useState } from 'react';
import FormLabel from '../../../components/forms/FormLabel';

interface ReportQuestionIssueProps {
	question: Question | undefined;
}

const ReportQuestionIssue = ({ question }: ReportQuestionIssueProps) => {
	const { updateQuestionbyKey } = useQuestionBankContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const [showModal, setShowModal] = useState(false);
	const [message, setMessage] = useState<string>('');
	const [issuesList, setIssuesList] = useState<string[]>(
		question?.issues?.list ?? []
	);
	if (question === undefined) return null;

	const handleChange = (id: string, checked: boolean) => {
		if (checked) {
			setIssuesList([...issuesList, id]);
		} else {
			setIssuesList(issuesList.filter((issue) => issue !== id));
		}
	};

	const reportIssue = () => {
		setLoadingMessage('Reportando problema...');
		setLoading(true);
		let msg: string;

		if (issuesList.length === 0 && message === '') {
			Swal.fire({
				title: 'Error al reportar pregunta',
				text: 'Por favor, selecciona al menos un problema o escribe una nota.',
				icon: 'error',
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				customClass: {
					confirmButton: 'bg-brand-500 hover:bg-brand-700'
				}
			})
				.then(() => {
					setLoading(false);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			if (typeof question.issues?.message === 'string' ?? false) {
				msg = message + 'Prev. Note:' + (question.issues?.message ?? '');
			} else {
				msg = message;
			}

			let issues: IQuestionIssues = {};
			if (issuesList.length > 0 && msg === '') {
				issues = {
					list: issuesList
				};
			}

			if (issuesList.length === 0 && msg !== '') {
				issues = {
					message: msg
				};
			}

			if (issuesList.length > 0 && msg !== '') {
				issues = {
					list: issuesList,
					message: msg
				};
			}

			if (
				(issues?.list?.length !== undefined ?? false) ||
				(issues.message !== undefined ?? false)
			) {
				updateQuestionbyKey(question.id, 'issues', issues)
					.then(() => {
						Swal.fire({
							title: 'Gracias por reportar el problema',
							text: 'Tu reporte ha sido enviado con éxito.',
							icon: 'success',
							allowOutsideClick: false,
							allowEscapeKey: false,
							allowEnterKey: false,
							customClass: {
								confirmButton: 'bg-brand-500 hover:bg-brand-700'
							}
						})
							.then(() => {
								setLoading(false);
								setShowModal(false);
							})
							.catch((error) => {
								console.error(error);
							});
					})
					.catch((err: any) => {
						setLoading(false);
						Swal.fire({
							title: 'Error al reportar pregunta',
							text: 'Hubo un error al reportar la pregunta. Por favor, intenta de nuevo.',
							icon: 'error',
							allowOutsideClick: false,
							allowEscapeKey: false,
							allowEnterKey: false,
							customClass: {
								confirmButton: 'bg-brand-500 hover:bg-brand-700'
							}
						})
							.then(() => {
								setLoading(false);
								setShowModal(false);
							})
							.catch((error) => {
								console.error(err, error);
							});
					});
			} else {
				setLoading(false);
				Swal.fire({
					title: 'Error al reportar pregunta',
					text: 'Por favor, selecciona al menos un problema o escribe una nota.',
					icon: 'error',
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
					customClass: {
						confirmButton: 'bg-brand-500 hover:bg-brand-700'
					}
				})
					.then(() => {
						setLoading(false);
					})
					.catch((error) => {
						console.error(error);
					});
			}
		}
	};

	return (
		<div id='modal-button-wrapper' style={{ float: 'right' }} className='mx-4'>
			<button
				className='bg-white text-red-500 hover:bg-red-500 hover:text-white flex items-center p-1'
				onClick={() => setShowModal(true)}>
				<i className='material-icons'>feedback</i>
			</button>
			<Modal
				headline={'¿Qué quieres reportar sobre esta pregunta?'}
				actionBtnText={'Reportar'}
				handleAction={reportIssue}
				isOpen={showModal}
				onClose={() => setShowModal(false)}>
				<fieldset>
					<div id='missing-question' className='relative flex items-start'>
						<div className='flex h-6 items-center'>
							<input
								name='isMissingQuestion'
								id='isMissingQuestion'
								type='checkbox'
								checked={issuesList?.includes('isMissingQuestion')}
								onChange={(e) => handleChange(e.target.id, e.target.checked)}
								aria-describedby='missing-question'
								className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
							/>
						</div>
						<div className='ml-3 text-sm/6'>
							<label htmlFor='isMissingQuestion'>No hay pregunta</label>
						</div>
					</div>

					<div id='missing-answers' className='relative flex items-start'>
						<div className='flex h-6 items-center'>
							<input
								name='isMissingAnswers'
								id='isMissingAnswers'
								type='checkbox'
								checked={issuesList?.includes('isMissingAnswers')}
								onChange={(e) => handleChange(e.target.id, e.target.checked)}
								aria-describedby='missing-answers'
								className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
							/>
						</div>
						<div className='ml-3 text-sm/6'>
							<label htmlFor='isMissingAnswers'>Faltan respuestas</label>
						</div>
					</div>

					<div id='missing-explanation' className='relative flex items-start'>
						<div className='flex h-6 items-center'>
							<input
								name='isMissingExplanation'
								id='isMissingExplanation'
								type='checkbox'
								checked={issuesList?.includes('isMissingExplanation')}
								onChange={(e) => handleChange(e.target.id, e.target.checked)}
								aria-describedby='missing-explanation'
								className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
							/>
						</div>
						<div className='ml-3 text-sm/6'>
							<label htmlFor='isMissingExplanation'>No hay explicación</label>
						</div>
					</div>

					<div id='missing-exp-source' className='relative flex items-start'>
						<div className='flex h-6 items-center'>
							<input
								name='isMissingExplanationSource'
								id='isMissingExplanationSource'
								type='checkbox'
								checked={issuesList?.includes('isMissingExplanationSource')}
								onChange={(e) => handleChange(e.target.id, e.target.checked)}
								aria-describedby='missing-exp-source'
								className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
							/>
						</div>
						<div className='ml-3 text-sm/6'>
							<label htmlFor='isMissingExplanationSource'>
								No hay fuente de explicación
							</label>
						</div>
					</div>

					<div id='has-gramatical-error' className='relative flex items-start'>
						<div className='flex h-6 items-center'>
							<input
								name='hasGramaticalError'
								id='hasGramaticalError'
								type='checkbox'
								checked={issuesList?.includes('hasGramaticalError')}
								onChange={(e) => handleChange(e.target.id, e.target.checked)}
								aria-describedby='has-gramatical-error'
								className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
							/>
						</div>
						<div className='ml-3 text-sm/6'>
							<label htmlFor='hasGramaticalError'>
								Hay faltas ortográficas
							</label>
						</div>
					</div>

					<div id='has-formatting-issue' className='relative flex items-start'>
						<div className='flex h-6 items-center'>
							<input
								name='hasFormattingIssue'
								id='hasFormattingIssue'
								type='checkbox'
								checked={issuesList?.includes('hasFormattingIssue')}
								onChange={(e) => handleChange(e.target.id, e.target.checked)}
								aria-describedby='has-formatting-issue'
								className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
							/>
						</div>
						<div className='ml-3 text-sm/6'>
							<label htmlFor='hasFormattingIssue'>
								Hay un problema con el formato
							</label>
						</div>
					</div>
				</fieldset>

				{/* Notas */}
				<div className='flex flex-col space-y-4 mt-8'>
					<label htmlFor='report-issue' className='flex items-center'>
						<i className='material-icons mr-2'>insert_comment</i>
						Más información
						<span className='ml-2'>(Opcional)</span>
					</label>
					<textarea
						id='issueMessage'
						name='issueMessage'
						rows={4}
						placeholder='Describe el problema con la pregunta de ser necesario...'
						className='w-full p-2 border border-gray-300 rounded-md'
						onChange={(e) => setMessage(e.target.value)}></textarea>
				</div>
			</Modal>
		</div>
	);
};
export default ReportQuestionIssue;
