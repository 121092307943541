import { useEffect, useState } from 'react';
import Card from '../../../../components/ui/Card';
import type { Question } from '../../../../data/interface-question';
import { removeEmptyParagraphsbyQuill } from '../../../../utils/helpers';
import parseHTML from 'html-react-parser';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import { useLoadingContext } from '../../../../context/LoadingContext';
import AudioExplanation from '../AudioExplanation';

interface ExplanationProps {
	showExplanation: boolean;
	question: Question;
}
const Explanation = ({ showExplanation, question }: ExplanationProps) => {
	const { getQuestionImageURL } = useQuestionBankContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const [imageURL, setImageURL] = useState<string | undefined>();
	const explanation = removeEmptyParagraphsbyQuill(question.explanation);

	const checkForImg = async () => {
		if (question.imageFileName !== undefined) {
			// setLoadingMessage('');
			// setLoading(true);
			if (question.showImageInQuestion !== true) {
				// setLoadingMessage('Cargando imagen...');
				const url = await getQuestionImageURL(question.imageFileName);
				// console.log('Image URL:', url, question.question);
				setImageURL(url);
			} else {
				setLoading(false);
			}
			return false;
		} else {
			return false;
		}
	};

	useEffect(() => {
		checkForImg()
			.then(() => {
				// console.log('Image URL:', imageURL);
			})
			.catch((err) => console.error(err));
		return () => {
			setImageURL(undefined);
		};
	}, [question]);

	useEffect(() => {
		setLoading(false);
	}, [imageURL]);

	if (showExplanation) {
		return (
			<div>
				{showExplanation && (
					<Card classes='bg-white shadow-md sm:rounded-md p-8 mb-8 mx-1'>
						<h5>Explicación:</h5>
						<>
							<AudioExplanation audioFileName={question.audioFileName} />
							<div className='explanation'>{parseHTML(explanation)}</div>
						</>

						{/* // TODO: Enhancement - Make this img width: 50% and make if clickable so it expands to full screen */}
						{imageURL !== undefined && (
							<div className='flex justify-center mt-2'>
								{/* // TODO: Enable below once the image is available through firestore */}
								<img className='responsive-img' src={imageURL} alt={imageURL} />
							</div>
						)}
					</Card>
				)}
			</div>
		);
	}

	return null;
};
export default Explanation;
