import Announcement from './Announcement';
import Features from '../../../layout/features/Features';
import BlurredTrianglesBottom from '../../../components/ui/shapes/BlurredTriangles';
import BlurredTrianglesTop from '../../../components/ui/shapes/BlurredTrianglesTop';
import {
	AcademicCapIcon,
	ArrowPathIcon,
	CloudArrowUpIcon,
	LockClosedIcon,
	PresentationChartLineIcon
} from '@heroicons/react/20/solid';
import routes from '../../../config/routes';
import CTA from './CTA';

const content = {
	title: 'Descubre la mejor herramienta de estudio para el ENURM',
	description:
		'Con nosotros puedes alcanzar tu sueño de obtener una formación médica especializada en la República Dominicana.',
	cta: 'Crea tu Cuenta',
	link: 'Conoce Más'
};

const features = [
	{
		name: 'Flexible',
		description:
			'Accede a información actualizada y prepárate online, 24/7 desde la comodidad de tu hogar o de donde prefieras',
		href: '#',
		icon: CloudArrowUpIcon
	},
	{
		name: 'Completa',
		description:
			'Estudia de preguntas con respuestas, explicaciones y fuentes investigadas y verificadas que validarán tus conocimientos',
		href: '#',
		icon: PresentationChartLineIcon
	},
	{
		name: 'Inteligente',
		description:
			'Mide tu progreso con estadísticas de desempeño y genera pruebas con los temas específicos a tu necesidad',
		href: '#',
		icon: AcademicCapIcon
	}
];

// const exams = ['ENURM', 'CMF', 'Dermatología'];

export default function Example() {
	return (
		<>
			<div className='relative isolate px-6 pt-14 lg:px-8'>
				<BlurredTrianglesTop />
				<div className='mx-auto max-w-2xl py-32 sm:py-48 lg:py-56'>
					<Announcement />
					<div className='text-center'>
						<h1 className='text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>
							{content.title}
						</h1>
						<p className='mt-6 text-lg leading-8 text-gray-600'>
							{content.description}
						</p>
						<div className='mt-10 flex items-center justify-center gap-x-6'>
							<a
								href={routes.AUTHENTICATION.REGISTER}
								className='rounded-md bg-brand-500 px-3.5 py-2.5 text-sm font-semibold text-white hover:text-white shadow-sm hover:bg-brand-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'>
								{content.cta}
							</a>
							<a
								href='#'
								className='text-sm font-semibold leading-6 text-gray-900'>
								{content.link} <span aria-hidden='true'>→</span>
							</a>
						</div>
					</div>
				</div>
				<BlurredTrianglesBottom />
			</div>
			<Features features={features} />
			<CTA />
		</>
	);
}
