import ImgWithFallback from './ImgWithFallBack';
import light_logo_source from '../assets/logos/prepmed_white.png';
import light_logo_fallback from '../assets/logos/prepmed_white.webp';
import dark_logo_source from '../assets/logos/prepmed_blue.png';
import dark_logo_fallback from '../assets/logos/prepmed_blue.webp';
import lightSVG from '../assets/logos/prepmed-light.svg';
import darkSVG from '../assets/logos/prepmed-dark.svg';
import lightPNG from '../assets/logos/prepmed-light.png';
import darkPNG from '../assets/logos/prepmed-dark.png';

interface LogoProps {
	useDarkLogo?: boolean;
	classes?: string;
	height?: string;
	handleClick?: () => void;
}

const Logo = ({
	useDarkLogo = false,
	classes = 'ml-4 h-12',
	handleClick
}: LogoProps) => {
	if (handleClick !== undefined) {
		return (
			<div onClick={handleClick}>
				<ImgWithFallback
					altText='prepmed-logo'
					classes={`${classes} cursor-pointer`}
					src={useDarkLogo ? darkPNG : lightPNG}
					fallback={useDarkLogo ? dark_logo_fallback : light_logo_fallback}
				/>
				{/* <ImgWithFallback
					altText='prepmed-logo'
					classes={`${classes} ${height} cursor-pointer`}
					src={useDarkLogo ? dark_logo_source : light_logo_source}
					fallback={useDarkLogo ? dark_logo_fallback : light_logo_fallback}
				/> */}
			</div>
		);
	}

	return (
		// <svg width='200' height='200' xmlns='http://www.w3.org/2000/svg'>
		// <image href='mdn_logo_only_color.png' height='200' width='200' />
		// </svg>

		<ImgWithFallback
			altText='prepmed-logo'
			classes={`${classes} `}
			src={useDarkLogo ? lightPNG : darkPNG}
			fallback={useDarkLogo ? dark_logo_fallback : light_logo_fallback}
		/>

		// <ImgWithFallback
		// altText='prepmed-logo'
		// classes={`${classes} ${height}`}
		// src={useDarkLogo ? dark_logo_source : light_logo_source}
		// fallback={useDarkLogo ? dark_logo_fallback : light_logo_fallback}
		// />
	);
};

export default Logo;
