interface TestProgressProps {
	current: number;
	total: number;
}

const TestProgress = ({ current, total }: TestProgressProps) => {
	return (
		<span className='text-white'>
			<span style={{ fontSize: 'larger', fontWeight: '600' }}>
				{current + 1}{' '}
			</span>{' '}
			/ <span style={{ fontSize: 'smaller' }}>{total}</span>
		</span>
	);
};

export default TestProgress;
