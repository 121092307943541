import { useEffect, useState } from 'react';
import ProgressBar from '../../components/utils/ProgressBar';
import useQuestionBankContext from '../../api/QuestionBankContext';
import type { QuestionRecord } from '../../data/interface-question';
import debugThis from '../../utils/helpers';

const PreguntasRealizadas = () => {
	const { questions, testRecords } = useQuestionBankContext();
	// TODO: Remove all 'any' and 'any[]' types

	const [uniqueQuestions, setUniqueQuestions] = useState<Set<string>>();
	const [percent, setPercent] = useState<number>(0);
	const [isValidPercent, setIsValidPercent] = useState<boolean>(false);

	const getIDsOfQuestionsTaken = () => {
		if (testRecords === null) return null;
		const arrayOfQuestions: QuestionRecord[] = [];
		testRecords.forEach((r) => {
			arrayOfQuestions.push(...r.questions);
		});
		getQuestionIDs(arrayOfQuestions);
	};

	const getQuestionIDs = (allQuestions: QuestionRecord[]) => {
		const arrayOfIDs: string[] = [];
		allQuestions.forEach((q) => {
			arrayOfIDs.push(q.id);
		});
		const set = new Set(arrayOfIDs);
		setUniqueQuestions(set);
	};

	const calculatePercent = (): void => {
		if (uniqueQuestions === undefined || questions === undefined) {
			// TODO: 'Hubo un error de carga de datos. Por favor, inténtalo de nuevo.'
			setIsValidPercent(false);
		} else {
			const value = uniqueQuestions.size / questions.length;
			const round = Math.round(value * 100);
			if (!isValidPercent && typeof round === 'number') {
				setIsValidPercent(true);
				setPercent(round);
			}
		}
	};

	useEffect(() => {
		uniqueQuestions !== null && calculatePercent();
	}, [uniqueQuestions, questions]);

	useEffect(() => {
		getIDsOfQuestionsTaken();
	}, [testRecords]);

	return (
		<section>
			<h2>Preguntas Realizadas</h2>

			{isValidPercent ? (
				<p>
					Porcentaje del banco de preguntas que has completado hasta la fecha:{' '}
					<strong>{percent}%</strong>
				</p>
			) : (
				<p>
					¿Aún no has completado un examen? ¡Hazlo ahora para empezar a ver tu
					progreso!
				</p>
			)}

			{/* PROGRESS BAR */}
			{percent > 0 && <ProgressBar width={100} percent={percent} />}
		</section>
	);
};
export default PreguntasRealizadas;
