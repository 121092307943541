import paypal from '../../../assets/images/badges/secured-payment-paypal.png';
import stripe from '../../../assets/images/badges/secured-payment-stripe.png';
import stripeAndPaypal from '../../../assets/images/badges/paypal_stripe.png';

// COMPONENTS
import ImgWithFallback from '../../../components/ImgWithFallBack';

const TrustBadges = () => {
	return (
		<>
			<div className='hidden md:flex justify-between mb-4 w-full'>
				<ImgWithFallback
					classes='w-[20rem]'
					src={stripe}
					fallback={stripe}
					altText='Stripe Secure'
				/>

				<ImgWithFallback
					classes='w-[20rem]'
					src={paypal}
					fallback={paypal}
					altText='PayPal Acceptance Mark'
				/>
			</div>
			<div className='block md:hidden center mb-4'>
				<ImgWithFallback
					src={stripeAndPaypal}
					fallback={stripeAndPaypal}
					altText='Stripe and PayPal'
				/>
			</div>
		</>
	);
};
export default TrustBadges;
