import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.css';
import PrepMed from './PrepMed';
import ErrorBoundary from './ErrorBoundary';
// CONTEXT
import LoadingContextProvider from './context/LoadingContext';
import { QuestionBankContextProvider } from './api/QuestionBankContext';
import { UserContextProvider } from './api/UserContext';
import AuthContextProvider from './api/AuthContext';
import BusinessContextProvider from './api/BusinessContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import type { ReactPayPalScriptOptions } from '@paypal/react-paypal-js';
import Checkout from './pages/private/sales/Checkout';
import CheckoutWithPayPal from './pages/private/sales/CheckoutWithPayPal';
import AdminContextProvider from './api/AdminContext';
import FlashcardContextProvider from './api/FlashcardsContext';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const PAYPAL_URL = process.env.REACT_APP_PAYPAL_PUBLIC as string;
const initialOptions: ReactPayPalScriptOptions = {
	clientId: PAYPAL_URL,
	currency: 'USD',
	intent: 'capture'
};
// import reportWebVitals from './reportWebVitals';
// ReactDOM.createRoot(document.getElementById('modal') as HTMLElement);

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

LogRocket.init('mc4xns/prepmed');
setupLogRocketReact(LogRocket);

const queryClient = new QueryClient();
root.render(
	<React.StrictMode>
		<LoadingContextProvider>
			<BrowserRouter>
				<UserContextProvider>
					<ErrorBoundary>
						<BusinessContextProvider>
							<QuestionBankContextProvider>
								<FlashcardContextProvider>
									<AdminContextProvider>
										<AuthContextProvider>
											<QueryClientProvider client={queryClient}>
												<PayPalScriptProvider options={initialOptions}>
													<PrepMed />
												</PayPalScriptProvider>
												<ReactQueryDevtools />
											</QueryClientProvider>
										</AuthContextProvider>
									</AdminContextProvider>
								</FlashcardContextProvider>
							</QuestionBankContextProvider>
						</BusinessContextProvider>
					</ErrorBoundary>
				</UserContextProvider>
			</BrowserRouter>
		</LoadingContextProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
