type StaticImageData = {
	src: string;
	height: number;
	width: number;
	blurDataURL?: string;
};

interface ImgWithFallBackProps {
	altText?: string;
	classes?: string;
	delegated?: React.ReactNode; // accepts everything React can render
	fallback: string;
	src: string;
	type?: string;
}

/**
 * Best way to render webp images
 * https://www.joshwcomeau.com/performance/embracing-modern-image-formats/
 */
const ImgWithFallback = ({
	altText = '',
	classes = '',
	src,
	fallback,
	type = 'image/webp',
	...delegated
}: ImgWithFallBackProps) => {
	return (
		<picture>
			<source srcSet={src} type={type} />
			<img alt={altText} className={classes} src={fallback} {...delegated} />
		</picture>
	);
};

export default ImgWithFallback;
