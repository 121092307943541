import { useEffect, type SyntheticEvent, useState } from 'react';
import type { Question as IQuestion } from '../../../../data/interface-question';
import { shuffle } from '../../../../utils/helpers';

interface AnswersProps {
	question: IQuestion;
	isSelected: boolean;
	isTutorMode: boolean;
	selectedAnswer: string;
	evaluateSelection: (option: string) => void;
}

interface AnswerLiProps {
	evaluateSelection: (option: string) => void;
	answer: string;
	option: string;
	selectedAnswer: string;
	isSelected: boolean;
	isTutorMode: boolean;
	disableButton: boolean;
}

// #region ICONS
const squareCheckIcon: JSX.Element = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		// className='fill-gray-600'
		height='24px'
		width='24px'
		// fill='#e8eaed'
		fill='currentColor'
		viewBox='0 0 448 512'>
		<path d='M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z' />
	</svg>
);

const squareXIcon: JSX.Element = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 448 512 ' // className='fill-gray-600'
		height='24px'
		width='24px'
		// fill='#e8eaed'
		fill='currentColor'>
		<path d='M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm79 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z' />
	</svg>
);

const indeterminateCheckBox = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		// className='fill-gray-600'
		height='24px'
		width='24px'
		// fill='#e8eaed'
		fill='currentColor'
		viewBox='0 -960 960 960'>
		<path d='M280-440h400v-80H280v80Zm-80 320q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z' />
	</svg>
);

const checkBoxOutlineBlank: JSX.Element = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		// className='fill-gray-500'
		height='24px'
		width='24px'
		// fill='#e8eaed'
		fill='currentColor'
		viewBox='0 -960 960 960'>
		<path d='M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Z' />
	</svg>
);

const wrongAnswerIcon: JSX.Element = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		height='24px'
		width='24px'
		// fill='#e8eaed'
		fill='currentColor'
		viewBox='0 -960 960 960'>
		<path d='m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z' />
	</svg>
);

const correctAnswerIcon: JSX.Element = (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		height='24px'
		viewBox='0 -960 960 960'
		width='24px'
		fill='currentColor'>
		<path d='M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z' />
	</svg>
);
// #endregion

const AnswerLi = ({
	evaluateSelection,
	option,
	isSelected,
	answer,
	selectedAnswer,
	isTutorMode,
	disableButton
}: AnswerLiProps) => {
	const [id, setID] = useState('');
	const handleClick = (e: SyntheticEvent) => {
		evaluateSelection(option);
		// change child icon to check_box
		// const firstChild = (e.target as HTMLButtonElement).children[0];
		// setID((e.target as HTMLButtonElement).id);
		// if (firstChild !== null && firstChild !== undefined) {
		// firstChild.innerHTML = 'check-box';
		// }
	};

	return (
		<li
			className={`border focus:ring-brand-200 focus:border-brand-200 shadow rounded-md bg-white answer z-depth-1 outline  ${
				isSelected && selectedAnswer === option
					? 'outline-4 outline-offset-2 outline-brand-500'
					: 'outline-2 outline-offset-2 outline-brand-500/50 hover:outline-4 hover:outline-yellow-500/90'
			}`}>
			<button
				data-clicked='No'
				className='no-scaling cursor-pointer flex items-center justify-start w-full px-4 py-2 text-left text-base'
				id={option}
				onClick={handleClick}
				disabled={disableButton}>
				{/* {isSelected ? (
					<i className='material-icons'>
						{id === option ? 'check_box' : 'indeterminate_check_box'}
					</i>
				) : (
					// <i className='material-icons'>{check}</i>
					<i className='material-icons text-gray-500'>
						check_box_outline_blank
					</i>
				)} */}

				{isSelected && selectedAnswer === option
					? indeterminateCheckBox
					: checkBoxOutlineBlank}
				<span className='ml-2 text-base font-normal'>{option}</span>
			</button>
		</li>
	);
};

const TutorModeAnswerLi = ({
	evaluateSelection,
	option,
	isSelected,
	answer,
	selectedAnswer,
	isTutorMode,
	disableButton
}: AnswerLiProps) => {
	const [id, setID] = useState('');
	let outline;
	let evalClass = 'outline-2 ';
	const handleClick = (e: SyntheticEvent) => {
		evaluateSelection(option);
		// change child icon to check_box
		// const firstChild = (e.target as HTMLButtonElement).children[0];
		// setID((e.target as HTMLButtonElement).id);
		// if (firstChild !== null && firstChild !== undefined) {
		// firstChild.innerHTML = 'check-box';
		// }
	};

	if (answer === option) {
		evalClass = 'right-answer';
		outline = `${
			isSelected && selectedAnswer === option ? 'outline-4' : 'outline-1'
		} outline-green-500/70 hover:outline-green-500/80`;
	} else {
		evalClass = 'wrong-answer';
		outline = `${
			isSelected && selectedAnswer === option ? 'outline-4' : 'outline-1'
		} outline-red-500/70 hover:outline-red-500/80`;
	}

	const defualt =
		'border focus:ring-brand-200 focus:border-brand-200 shadow rounded-md';

	return (
		<li
			className={`${defualt} bg-white answer outline outline-offset-2  ${
				isSelected
					? evalClass + ' ' + outline
					: 'outline-brand-400/90 hover:outline-4 hover:outline-yellow-400/80'
			}`}>
			<button
				data-clicked='No'
				className='no-scaling cursor-pointer flex items-center justify-start w-full px-4 py-2 text-left text-base'
				id={option}
				onClick={handleClick}
				disabled={disableButton}>
				{isSelected
					? answer === option
						? selectedAnswer === option
							? squareCheckIcon
							: correctAnswerIcon
						: selectedAnswer === option
						? squareXIcon
						: wrongAnswerIcon
					: checkBoxOutlineBlank}
				<span className='ml-2 text-base font-normal'>{option}</span>
			</button>
		</li>
	);
};

const Answers = ({
	isSelected,
	isTutorMode,
	question,
	selectedAnswer,
	evaluateSelection
}: AnswersProps) => {
	const [answerOptions, setAnswerOptions] = useState<string[]>([]);

	useEffect(() => {
		if (question !== null) {
			const options = shuffle([
				question.answer,
				question.option1,
				question.option2,
				question.option3
			]);
			setAnswerOptions(options);
		}
		return () => {
			setAnswerOptions([]);
		};
		/* eslint-disable-next-line */
	}, [question]);

	if (question === null) return null;
	return (
		<ul className='answers-box'>
			{answerOptions.map((option) => {
				return isTutorMode ? (
					<TutorModeAnswerLi
						option={option}
						key={option}
						evaluateSelection={evaluateSelection}
						isSelected={isSelected}
						answer={question.answer}
						selectedAnswer={selectedAnswer}
						isTutorMode={isTutorMode}
						disableButton={isTutorMode && isSelected}
					/>
				) : (
					<AnswerLi
						option={option}
						key={option}
						evaluateSelection={evaluateSelection}
						isSelected={isSelected}
						answer={question.answer}
						selectedAnswer={selectedAnswer}
						isTutorMode={isTutorMode}
						disableButton={isTutorMode && isSelected}
					/>
				);
			})}
		</ul>
	);
};
export default Answers;
