import { Component } from 'react';
import type { ReactNode, ErrorInfo } from 'react';
import Logo from './components/Logo';
import { deleteAllCookies } from './utils/helpers';
import { handleEventLog } from './gcp/config';
import LogRocket from 'logrocket';
// import logo from '../assets/logo/LOGO PREP MED-04 (WHITE).png';
// import { deleteAllCookies, isMobileScreen } from '../utils/helpers';
// import { logEvent, logError } from '../utils/store/config/fbConfig';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	// constructor(props) {
	// super(props);
	// this.state = { hasError: false };
	// }
	public state: State = {
		hasError: false
	};

	public static getDerivedStateFromError(_: Error): State {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
		LogRocket.log('ERROR', { ...error, ...errorInfo });
		handleEventLog('ERROR BOUNDARY', error);
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
		// logEvent('Reached Error Boundary');
		// logEvent(`ErrBoundary: ${window.location.pathname}`);
		// logError(error, errorInfo, window.location.pathname, true);
	}

	clearBrowser = () => {
		deleteAllCookies();
	};

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className='bg-gray-800 h-screen flex items-center'>
					<div className='container'>
						<div className='flex items-center'>
							<Logo classes='m-0' height='h-12 md:h-16 ' />
						</div>
						<div className='center mt-4'>
							<h2 className='text-red-500 flex items-center mb-8'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									height='24px'
									viewBox='0 -960 960 960'
									width='24px'
									fill='currentColor'>
									<path d='m336-280 144-144 144 144 56-56-144-144 144-144-56-56-144 144-144-144-56 56 144 144-144 144 56 56ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z' />
								</svg>
								{/* <i className='material-icons mr-2 text-5xl'>cancel</i> */}
								¡Importante!
							</h2>
							<div
								className='text-white mt-3 text-left'
								style={{
									// maxWidth: isMobileScreen ? '90%' : '70%',
									margin: '0 auto'
								}}>
								<p>
									Por favor intenta denuevo, hubo un error y es necesario
									recargar la aplicación.
								</p>
								<p>
									<strong>Importante:</strong> Antes de recargar, si tienes un
									momento, por favor dejanos saber que pasos tomaste antes de
									encontrar este error. Esta información es vital para mejorar
									la plataforma y solo toma un segundo contactarnos usando este
									enlace:
								</p>
							</div>
							<div className='flex justify-between items-center pt-4'>
								<a
									href='./contactanos'
									className='rounded bg-indigo-50 py-2 px-4 text-sm font-semibold text-brand-600 shadow-sm hover:bg-indigo-100'>
									Contactanos
								</a>

								<button
									className='btn btn-large bg-brand-400 flex justify-between items-center text-white hover:bg-brand-5'
									onClick={this.clearBrowser}>
									<svg
										className='mr-2'
										xmlns='http://www.w3.org/2000/svg'
										height='24px'
										viewBox='0 -960 960 960'
										width='24px'
										fill='currentColor'>
										<path d='M482-160q-134 0-228-93t-94-227v-7l-64 64-56-56 160-160 160 160-56 56-64-64v7q0 100 70.5 170T482-240q26 0 51-6t49-18l60 60q-38 22-78 33t-82 11Zm278-161L600-481l56-56 64 64v-7q0-100-70.5-170T478-720q-26 0-51 6t-49 18l-60-60q38-22 78-33t82-11q134 0 228 93t94 227v7l64-64 56 56-160 160Z' />
									</svg>
									Recargar App
								</button>
							</div>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
