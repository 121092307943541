// import { CheckCircleIcon } from '@heroicons/react/20/solid';
import Logo from '../../../components/Logo';
import MainTag from '../../../layout/Main';
import StepsWithDots from '../../../components/steps/StepsWithDots';
import SignupSteps from '../../public/signup/SignupSteps';
import type {
	SignupStep,
	SignupStepsIDs
} from '../../../data/interface-signup-steps';
import { useEffect, useState } from 'react';
import useHideElementOnMount from '../../../hooks/useHideElementOnMount';

const tiers = [
	{
		name: 'Basic',
		id: 'tier-basic',
		href: '#',
		price: { monthly: '$15', annually: '$12' },
		description: 'Everything necessary to get started.',
		features: [
			'5 products',
			'Up to 1,000 subscribers',
			'Basic analytics',
			'48-hour support response time'
		]
	},
	{
		name: 'Essential',
		id: 'tier-essential',
		href: '#',
		price: { monthly: '$30', annually: '$24' },
		description:
			'Everything in Basic, plus essential tools for growing your business.',
		features: [
			'25 products',
			'Up to 10,000 subscribers',
			'Advanced analytics',
			'24-hour support response time',
			'Marketing automations'
		]
	},
	{
		name: 'Growth',
		id: 'tier-growth',
		href: '#',
		price: { monthly: '$60', annually: '$48' },
		description:
			'Everything in Essential, plus collaboration tools and deeper insights.',
		features: [
			'Unlimited products',
			'Unlimited subscribers',
			'Advanced analytics',
			'1-hour, dedicated support response time',
			'Marketing automations',
			'Custom reporting tools'
		]
	}
];

// https://tailwindui.com/components/marketing/sections/pricing
// Look for "Three tiers with dividers" section
const SelectAMembership = () => {
	useHideElementOnMount('header');
	useHideElementOnMount('footer');
	const [currentStep, setNextStep] =
		useState<SignupStepsIDs>('elige-tu-examen');
	const [stepsCompleted, setStepsCompleted] = useState<SignupStepsIDs[]>([]);
	const [steps, setSteps] = useState<SignupStep[]>([
		{
			id: 'crea-tu-cuenta',
			name: 'Crea tu Cuenta',
			description: 'Configura tus credenciales',
			status: 'upcoming'
		},
		{
			id: 'cuenta-creada',
			name: 'Cuenta Creada',
			description: '',
			status: 'upcoming'
		},
		{
			id: 'detalles-personales',
			name: 'Detalles Personales',
			description: '',
			status: 'upcoming'
		},
		{
			id: 'elige-tu-examen',
			name: 'Elige tu Examen',
			description: '',
			status: 'upcoming'
		},
		{
			id: 'informacion-adicional',
			name: 'Información Adicional',
			description: '',
			status: 'upcoming'
		},
		{
			id: 'completa-tu-pago',
			name: 'Completa tu Pago',
			description: '',
			status: 'upcoming'
		}
	]);

	useEffect(() => {
		if (currentStep === null || currentStep === undefined) {
			handleNextStep('crea-tu-cuenta');
		}
	}, [currentStep]);

	const handleNextStep = (nextStep: SignupStepsIDs) => {
		if (nextStep === null || nextStep === undefined) {
			setNextStep(nextStep);
		} else {
			steps.forEach((step) => {
				const currentPassed =
					(step?.id === currentStep && nextStep !== currentStep) ||
					stepsCompleted.includes(step?.id);

				if (currentPassed) {
					step.status = 'complete';
					setStepsCompleted([...stepsCompleted, step.id]);
				} else if (step.id === nextStep) {
					step.status = 'current';
					setNextStep(nextStep);
				} else if (step.status !== 'complete') {
					step.status = 'upcoming';
				} else {
					step.status = 'upcoming';
				}
			});

			setSteps([...steps]);
		}
	};

	return (
		<>
			<div className='px-8 pt-10 bg-brand-50 w-full '>
				<Logo useDarkLogo={true} classes='h-10' />
			</div>
			<MainTag>
				{/* <StepsComponent /> */}
				<div className='flex flex-col items-center mb-4 mt-10'>
					<StepsWithDots steps={steps} />
					<SignupSteps
						currentStep={currentStep}
						nextStep={(x: SignupStepsIDs) => handleNextStep(x)}
					/>
				</div>
			</MainTag>
		</>
	);

	// TODO: The design below is better than the above
	// return (
	// <div className='bg-white py-24 sm:py-32'>
	// <div className='mx-auto max-w-7xl px-6 lg:px-8'>
	// <div className='mx-auto max-w-4xl sm:text-center'>
	// <h2 className='text-base font-semibold leading-7 text-indigo-600'>
	// Pricing
	// </h2>
	// <p className='mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
	// Choose the right plan for&nbsp;you
	// </p>
	// </div>
	// <p className='mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 sm:text-center'>
	// Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et
	// quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.
	// </p>
	// <div className='mt-20 flow-root'>
	// <div className='isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-100 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-3 lg:divide-x lg:divide-y-0 xl:-mx-4'>
	// {tiers.map((tier) => (
	// <div key={tier.id} className='pt-16 lg:px-8 lg:pt-0 xl:px-14'>
	// <h3
	// id={tier.id}
	// className='text-base font-semibold leading-7 text-gray-900'>
	// {tier.name}
	// </h3>
	// <p className='mt-6 flex items-baseline gap-x-1'>
	// <span className='text-5xl font-bold tracking-tight text-gray-900'>
	// {tier.price.monthly}
	// </span>
	// <span className='text-sm font-semibold leading-6 text-gray-600'>
	// /month
	// </span>
	// </p>
	// <p className='mt-3 text-sm leading-6 text-gray-500'>
	// {tier.price.annually} per month if paid annually
	// </p>
	// <a
	// href={tier.href}
	// aria-describedby={tier.id}
	// className='mt-10 block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
	// Buy plan
	// </a>
	// <p className='mt-10 text-sm font-semibold leading-6 text-gray-900'>
	// {tier.description}
	// </p>
	// <ul
	// role='list'
	// className='mt-6 space-y-3 text-sm leading-6 text-gray-600'>
	// {tier.features.map((feature) => (
	// <li key={feature} className='flex gap-x-3'>
	// <CheckCircleIcon
	// className='h-6 w-5 flex-none text-indigo-600'
	// aria-hidden='true'
	// />
	// {feature}
	// </li>
	// ))}
	// </ul>
	// </div>
	// ))}
	// </div>
	// </div>
	// </div>
	// </div>
	// );
};
export default SelectAMembership;
