import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';

import { db, handleEventLog } from '../gcp/config';

import type { FirebaseError } from 'firebase/app';

import useUserContext from './UserContext';
import { useLoadingContext } from '../context/LoadingContext';
import { useNavigate } from 'react-router-dom';
import type { User } from '../data/interface-user';

import {
	collection,
	doc,
	getDoc,
	getDocs,
	addDoc,
	setDoc,
	updateDoc,
	query,
	where
} from 'firebase/firestore';
import type { QuestionBankID } from '../data/interface-question-bank';
import { buildUserObject } from './xBuildObjectUtils';

export type AdminContextType = {
	getUserList: (
		productID: QuestionBankID,
		targetYear: number
	) => Promise<User[]>;
	users: User[] | undefined;
	setUsers: (users: User[]) => void;
};
export const AdminContext = createContext<AdminContextType | null>(null);
export const AdminContextProvider = ({ children }: { children: ReactNode }) => {
	const navigateTo = useNavigate();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { getProductByID, currentUser, setCurrentUser, createUserInFirestore } =
		useUserContext();
	const [users, setUsers] = useState<User[]>();

	const validateAdmin = () => {
		if (currentUser === null) return false; // TODO: Redirect to login page / HANDLE THIS CASE HERE
		return currentUser.isAdmin ?? false;
	};

	const getUserList = async (productID: QuestionBankID, targetYear: number) => {
		const isAdmin = validateAdmin();
		if (!isAdmin) return [];
		setLoading(true);
		setLoadingMessage('Fetching user list...');
		if (users !== undefined && users?.length > 0) return users;
		const userList = await getUsersFromFirestore(productID, targetYear);
		setLoading(false);
		return userList;
	};

	const getUsersFromFirestore = async (
		productID: QuestionBankID,
		targetYear: number
	): Promise<User[]> => {
		const userList: User[] = [];
		const usersCollection = 'users';

		const recordsRef = collection(db, usersCollection);
		const q = query(
			recordsRef,
			where('examTarget', '==', productID),
			where('examTargetYear', '==', targetYear)
		);

		const querySnapshot = await getDocs(q);
		querySnapshot?.forEach((record) => {
			const r = buildUserObject(record.id, record.data());
			if (r !== null) userList.push(r);
		});

		setUsers(userList);
		return userList;
	};

	return (
		<AdminContext.Provider value={{ getUserList, users, setUsers }}>
			{children}
		</AdminContext.Provider>
	);
};
export const useAdminContext = () => {
	const context = useContext(AdminContext);
	if (context === undefined || context === null) {
		throw new Error(
			'useAdminContext must be used within a AdminContextProvider'
		);
	}
	return context;
};
export default AdminContextProvider;
