import { openDB } from 'idb';
import type { IDBPDatabase } from 'idb';
// github.com/jakearchibald/idb#opendb

// export const loadItemFromIndexedDB = (storeName, objectStore, id) =>
// let db: any;
export const createObjectStore = async (
	database: string,
	version: number,
	tableNamesArray: string[]
) => {
	// console.info({ database, version, tableNamesArray });
	try {
		return await openDB(database, version, {
			upgrade(store) {
				console.info('DB open request UPGRADE NEEDED');
				for (const tableName of tableNamesArray) {
					if (store.objectStoreNames.contains(tableName)) {
						continue;
					}
					store.createObjectStore(tableName, {
						autoIncrement: true,
						keyPath: 'id'
					});
				}
				// db = store;
			},
			blocked() {
				console.info(
					'DB open request BLOCKED: older versions of the database open on the origin. '
				);
				// …
			},
			blocking() {
				console.info(
					'DB open request BLOCKING: this connection is blocking a future version of the database from opening'
				);
				// …
			},
			terminated() {
				console.info('DB open request TERMINATED...');
				// …
			}
		});
	} catch (error) {
		return undefined;
	}
};

export const getValue = async (
	db: IDBPDatabase<unknown>,
	tableName: string,
	id: string
) => {
	const tx = db.transaction(tableName, 'readonly');
	const store = tx.objectStore(tableName);
	const result = await store.get(id);
	return result;
};

export const getAllValues = async (
	db: IDBPDatabase<unknown>,
	tableName: string
) => {
	const tx = db.transaction(tableName, 'readonly');
	const store = tx.objectStore(tableName);
	const result = await store.getAll();
	// console.log('Get All Data', JSON.stringify(result));
	return result;
};

export const putValue = async (
	db: IDBPDatabase<unknown>,
	tableName: string,
	value: any
) => {
	const tx = db.transaction(tableName, 'readwrite');
	const store = tx.objectStore(tableName);
	const result = await store.put(value);
	return result;
};

export const putBulkValues = async (
	db: IDBPDatabase<unknown>,
	tableName: string,
	values: any
) => {
	const tx = db.transaction(tableName, 'readwrite');
	const store = tx.objectStore(tableName);
	for (const value of values) {
		const result = await store.put(value);
	}
	return await getAllValues(db, tableName);
};

export const deleteValue = async (
	db: IDBPDatabase<unknown>,
	tableName: string,
	id: string
) => {
	const tx = db.transaction(tableName, 'readwrite');
	const store = tx.objectStore(tableName);
	const result = await store.get(id);
	if (result !== undefined && result !== null) {
		return result;
	}
	await store.delete(id);
	return id;
};

export const deleteDB = async (database: string) => {
	const DBDeleteRequest = window.indexedDB.deleteDatabase(database);

	DBDeleteRequest.onerror = function (event) {
		console.error('Error deleting database.');
	};

	DBDeleteRequest.onsuccess = function (event) {
		console.info('Database deleted successfully');
	};
	return DBDeleteRequest;
};

// ~ HOW TO USE IT
// const [book, setBook] = useState();
// const [books, setAllBooks] = useState();
// useEffect(() => {
// const runIndexDb = async () => {
// const indexedDb = new indexedDBHelper('test', 1);
// await indexedDBHelper.createObjectStore(['books', 'students']);
// await indexedDBHelper.putValue('books', { name: 'A Game of Thrones' });
// await indexedDBHelper.putBulkValue('books', [
// { name: 'A Song of Fire and Ice' },
// { name: 'Harry Potter and the Chamber of Secrets' },
// ]);
// await indexedDBHelper.getValue('books', 1);
// await indexedDBHelper.getAllValues('books');
// await indexedDBHelper.deleteValue('books', 1);
// setBook(await indexedDBHelperHelper.getValue('books', 1));
// setAllBooks(await indexedDBHelperHelper.getAllValues('books'));
// };
// runIndexDb();
// }, []);
