import { useState } from 'react';
import Modal from './Modal';

interface ToggleModalProps {
	actionBtnText?: string;
	handleAction?: () => void;
	buttonText: string;
	buttonClass?: string;
	isDisabled?: boolean;
	isOpen: boolean;
	children: React.ReactNode;
	headline: string;
	onClose?: () => void;
	handleVisibility: (b: boolean) => void;
}

export default function ToggleModal({
	actionBtnText,
	handleAction,
	handleVisibility,
	buttonText,
	buttonClass,
	isDisabled = false,
	isOpen,
	children,
	headline,
	onClose
}: ToggleModalProps) {
	// const [isVisible, setIsVisible] = useState<boolean>(isOpen);
	return (
		<>
			<div id='modal-button-wrapper'>
				<button
					className={buttonClass}
					onClick={() => handleVisibility(true)}
					disabled={isDisabled}>
					{buttonText}
				</button>

				<Modal
					actionBtnText={actionBtnText}
					handleAction={handleAction}
					headline={headline}
					isOpen={isOpen}
					onClose={() => handleVisibility(false)}>
					{children}
				</Modal>
			</div>
		</>
	);
}
