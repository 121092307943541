import { useRef, useState } from 'react';
import GuideMessage from './GuideMessage';
import ErrorMessage from './ErrorMessage';
import FormLabel from './FormLabel';

interface NumberInputTypes {
	errorMessage?: string;
	guideMessage?: string;
	icon?: string | null;
	iconFam?: string;
	iconPosition?: 'left' | 'right';
	id: string;
	infoMessage?: string;
	inlineElement?: boolean;
	inputClasses?: string;
	label: string;
	min?: number;
	max?: number;
	parentClass?: string;
	pattern?: string;
	placeholder?: string;
	isRequired?: boolean;
	handleChange: (value: number) => void;
	handleValidity?: (value: boolean) => void;
}

const NumberInput = ({
	errorMessage = '',
	guideMessage = '',
	icon = null,
	iconPosition = 'left',
	iconFam = 'material-icons',
	id,
	infoMessage,
	inlineElement = false,
	inputClasses = '',
	label,
	min,
	max,
	parentClass = 'w-full mb-4',
	pattern,
	placeholder,
	isRequired = false,
	handleChange,
	handleValidity
}: NumberInputTypes) => {
	const numberInputRef = useRef<HTMLInputElement>(null);
	// const [hideInfoMessage, setHideInfoMessage] = useState<boolean>(true);
	const [isOnFocus, setIsOnFocus] = useState<boolean>(true);
	const [isValid, setIsValid] = useState<boolean>(false);

	const validateInput = (): boolean => {
		const value = Number(numberInputRef.current?.value);
		const valid = numberInputRef.current?.validity?.valid;
		const isValidLength =
			max !== undefined && min !== undefined && value < max && value > min;
		if (typeof valid === 'boolean' && isValidLength) {
			return valid;
		} else {
			return false;
		}
	};

	const handleAction = (e: React.ChangeEvent<HTMLInputElement>): void => {
		handleChange(Number(e.target.value));
		const validation = validateInput();
		if (handleValidity !== null) {
			handleValidity?.(validation);
			setIsValid(validation);
		}
	};

	const ringStyles = 'focus:ring focus:ring-opacity-50';

	return (
		<div className={parentClass}>
			{!inlineElement && icon !== null && iconFam === 'material-icons' ? (
				<FormLabel
					label={label}
					isRequired={isRequired}
					icon={icon}
					iconFam='material-icons'
					iconPosition={iconPosition}
				/>
			) : (
				!inlineElement && <FormLabel label={label} isRequired={isRequired} />
			)}
			<input
				autoComplete='off'
				type='number'
				id={id}
				className={`mt-1
                    block
                    w-full
                    rounded-md
                    border-gray-300
                    shadow-sm
                     ${inputClasses} ${ringStyles}  ${
					isValid !== null
						? isValid
							? 'focus:ring-green-200 focus:border-green-400'
							: 'focus:ring-red-400 focus:border-red-200'
						: 'focus:ring-brand-200 focus:border-brand-200'
				}`}
				minLength={min}
				maxLength={max}
				placeholder={placeholder !== undefined ? placeholder : label}
				ref={numberInputRef}
				required
				onBlur={() => setIsOnFocus(false)}
				onChange={(e) => handleAction(e)}
				onFocus={() => setIsOnFocus(true)}
				// onFocus={() =>
				// numberInputRef.current &&
				// numberInputRef.current.value.length > 0 &&
				// setHideInfoMessage(false)
				// }
			/>
			{isOnFocus && (
				<>
					<ErrorMessage
						error={errorMessage}
						showError={errorMessage !== '' && !isValid}
					/>
					{guideMessage !== '' && <GuideMessage message={guideMessage} />}
				</>
			)}
		</div>
	);
};

export default NumberInput;
