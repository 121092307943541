const ContentSection = () => {
	const navigation = [
		{ name: 'Product', href: '#' },
		{ name: 'Features', href: '#' },
		{ name: 'Resources', href: '#' },
		{ name: 'Company', href: '#' }
	];
	const stats = [
		{ label: 'Transactions every 24 hours', value: '44 million' },
		{ label: 'Assets under holding', value: '$119 trillion' },
		{ label: 'New users annually', value: '46,000' }
	];
	const values = [
		{
			name: 'Be world-class',
			description:
				'Aut illo quae. Ut et harum ea animi natus. Culpa maiores et sed sint et magnam exercitationem quia. Ullam voluptas nihil vitae dicta molestiae et. Aliquid velit porro vero.'
		},
		{
			name: 'Share everything you know',
			description:
				'Mollitia delectus a omnis. Quae velit aliquid. Qui nulla maxime adipisci illo id molestiae. Cumque cum ut minus rerum architecto magnam consequatur. Quia quaerat minima.'
		},
		{
			name: 'Always learning',
			description:
				'Aut repellendus et officiis dolor possimus. Deserunt velit quasi sunt fuga error labore quia ipsum. Commodi autem voluptatem nam. Quos voluptatem totam.'
		},
		{
			name: 'Be supportive',
			description:
				'Magnam provident veritatis odit. Vitae eligendi repellat non. Eum fugit impedit veritatis ducimus. Non qui aspernatur laudantium modi. Praesentium rerum error deserunt harum.'
		},
		{
			name: 'Take responsibility',
			description:
				'Sit minus expedita quam in ullam molestiae dignissimos in harum. Tenetur dolorem iure. Non nesciunt dolorem veniam necessitatibus laboriosam voluptas perspiciatis error.'
		},
		{
			name: 'Enjoy downtime',
			description:
				'Ipsa in earum deserunt aut. Quos minus aut animi et soluta. Ipsum dicta ut quia eius. Possimus reprehenderit iste aspernatur ut est velit consequatur distinctio.'
		}
	];
	const team = [
		{
			name: 'Michael Foster',
			role: 'Co-Founder / CTO',
			imageUrl:
				'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80'
		}
		// More people...
	];
	// const blogPosts = [
	// {
	// id: 1,
	// title: 'Vel expedita assumenda placeat aut nisi optio voluptates quas',
	// href: '#',
	// description:
	// 'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
	// imageUrl:
	// 'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
	// date: 'Mar 16, 2020',
	// datetime: '2020-03-16',
	// author: {
	// name: 'Michael Foster',
	// imageUrl:
	// 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
	// }
	// }
	// // More posts...
	// ];

	return (
		<div className='mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8'>
			<div className='mx-auto max-w-2xl lg:mx-0 lg:max-w-none'>
				<h2 className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
					Our mission
				</h2>
				<div className='mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row'>
					<div className='lg:w-full lg:max-w-2xl lg:flex-auto'>
						<p className='text-xl leading-8 text-gray-600'>
							Aliquet nec orci mattis amet quisque ullamcorper neque, nibh sem.
							At arcu, sit dui mi, nibh dui, diam eget aliquam. Quisque id at
							vitae feugiat egestas ac. Diam nulla orci at in viverra
							scelerisque eget. Eleifend egestas fringilla sapien.
						</p>
						<div className='mt-10 max-w-xl text-base leading-7 text-gray-700'>
							<p>
								Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget
								risus enim. Mattis mauris semper sed amet vitae sed turpis id.
								Id dolor praesent donec est. Odio penatibus risus viverra tellus
								varius sit neque erat velit. Faucibus commodo massa rhoncus,
								volutpat. Dignissim sed eget risus enim. Mattis mauris semper
								sed amet vitae sed turpis id.
							</p>
							<p className='mt-10'>
								Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis
								odio id et. Id blandit molestie auctor fermentum dignissim.
								Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate
								et ultrices hac adipiscing egestas. Iaculis convallis ac tempor
								et ut. Ac lorem vel integer orci.
							</p>
						</div>
					</div>
					<div className='lg:flex lg:flex-auto lg:justify-center'>
						<dl className='w-64 space-y-8 xl:w-80'>
							{stats.map((stat) => (
								<div key={stat.label} className='flex flex-col-reverse gap-y-4'>
									<dt className='text-base leading-7 text-gray-600'>
										{stat.label}
									</dt>
									<dd className='text-5xl font-semibold tracking-tight text-gray-900'>
										{stat.value}
									</dd>
								</div>
							))}
						</dl>
					</div>
				</div>
			</div>
		</div>
	);
};
export default ContentSection;
