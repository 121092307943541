import type { Timestamp } from 'firebase/firestore';
import type {
	Question,
	QuestionRecord,
	TestRecord,
	TestRecordTotals
} from '../data/interface-question';
import { debug } from 'console';
// import type { PreferredDateFormat } from '../data/interface-question-bank';

export default function debugThis() {
	// eslint-disable-next-line no-debugger
	debugger;
}

export const redirectTo = (path: string) => {
	window.location.replace(path);
};

export const reloadPage = () => {
	window.location.reload();
};

export const shuffle = (array: any[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};
const shuffle2 = (array: any[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}

	return array;
};
export function removeEmptyParagraphsbyQuill(str: string) {
	return str.replace(/(<p><br><\/p>)/g, '');
}
export function removeHTMLTags(str: string) {
	return str.replace(/<[^>]*>?/gm, '');
}

export const scrollToTop = () => {
	window.scrollTo({
		top: 0,
		behavior: 'smooth'
	});
};
/**
 * Formats dates in spanish
 */
export const customDateFormatInSpanish = (date: Date): string => {
	const monthsList = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre'
	];
	const days = ['Dom', 'Lun', 'Mar', 'Mier', 'Jue', 'Vie', 'Sab'];

	const d = new Date(date);
	const year = d.getFullYear();
	const dayNum = d.getDate();
	const monthName = monthsList[d.getMonth()];
	const dayName = days[d.getDay()];

	const newDate = `${dayName}, ${dayNum} ${monthName} ${year}`;
	return newDate;
};

export const preferredDateFormat = (date: Date): string => {
	const d = new Date(date);
	const year = d.getFullYear();
	const month = d.getMonth() + 1;
	const day = d.getDate();
	const newDate = `${year}/${month}/${day}`;
	return newDate;
};

export function secondsToHoursMinutesSeconds(totalTimeInSeconds: number) {
	totalTimeInSeconds = Number(totalTimeInSeconds);
	const h = Math.floor(totalTimeInSeconds / 3600);
	const m = Math.floor((totalTimeInSeconds % 3600) / 60);
	const s = Math.floor((totalTimeInSeconds % 3600) % 60);

	// let hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : '';
	// let mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : '';
	// let sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
	const hoursDisplay = h > 0 ? `${h}:` : '';
	const minutesDisplay = m < 10 ? `0${m}:` : m > 0 ? `${m}:` : '';
	const secondsDisplay = (s < 10 ? `0${s}` : s > 0 ? s : '').toString();
	return hoursDisplay + minutesDisplay + secondsDisplay;
}

export const convertFirebaseTSToDate = (timestamp: Date | Timestamp): Date => {
	try {
		if (timestamp instanceof Date) {
			return timestamp;
		}
		const fireBaseTime = new Date(
			timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
		);
		return fireBaseTime;
	} catch (error) {
		console.error('Error converting Firebase timestamp to date', error);
		return new Date();
	}
};
export const convertFirebaseTSToDateString = (
	timestamp: Date | Timestamp
): string => {
	try {
		if (timestamp instanceof Date) {
			return timestamp.toLocaleDateString();
		}
		const fireBaseTime = new Date(
			timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
		);
		const date = fireBaseTime.toDateString();
		const atTime = fireBaseTime.toLocaleTimeString();

		console.log(date, atTime);
		return `${date} at ${atTime}`;
	} catch (error) {
		console.error('Error converting Firebase timestamp to date', error);
		return '';
	}
};
export const convertFirebaseTSToTime = (
	timestamp: Date | Timestamp
): string => {
	try {
		if (timestamp instanceof Date) {
			return timestamp.toLocaleDateString();
		}
		const fireBaseTime = new Date(
			timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
		);
		const time = fireBaseTime.toLocaleTimeString();
		return time;
	} catch (error) {
		console.error('Error converting Firebase timestamp to date', error);
		return '';
	}
};
// #region Local Storage
export const deleteAllCookies = () => {
	const cookies = document.cookie.split(';');

	for (let i = 0; i < cookies.length; i++) {
		const cookie = cookies[i];
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
	}

	localStorage.clear();
	sessionStorage.clear();
	clearIndexedDB('firebaseLocalStorageDb');
	clearIndexedDB('firebase-installations-database');
	clearIndexedDB('prepmed-questions');
	clearIndexedDB('prepmed-flashcards');
	clearIndexedDB('idbpmv1');
	window.location.reload();
};

/**
 * Helper function for deleteAllCookies() - clears indexed databases
 */
const clearIndexedDB = (db: string) => {
	const req = indexedDB.deleteDatabase(db);
	req.onsuccess = function () {
		console.log(db, 'Deleted database successfully');
	};
	req.onerror = function () {
		console.log(db, "Couldn't delete database");
	};
	req.onblocked = function () {
		console.log(
			db,
			"Couldn't delete database due to the operation being blocked"
		);
	};
};
// #endregion

export const getTotalCorrectAnswers = async (
	qnALog: QuestionRecord[],
	questions: Question[]
): Promise<number> => {
	return await new Promise((resolve) => {
		let totalCorrectas = 0;
		qnALog.forEach((qna) => {
			const question = questions.find((q) => q.id === qna.id);
			if (question !== undefined && qna.selectedAnswer === question.answer) {
				totalCorrectas++;
			}
		});
		resolve(totalCorrectas);
	});
};

/**
 * Calculates the average grade of all tests. You can pass 'TestRecordTotals' as the second argument to avoid recalculating the totals if you already have them.
 */
export const getAverageGrade = async (
	alltests: TestRecord[],
	totals: TestRecordTotals | null = null
): Promise<number> => {
	if (totals === null) {
		totals = await getTestRecordsCorrectAndQuestionTotals(alltests);
	}

	return await new Promise((resolve) => {
		let average = 0;
		if (alltests !== null && totals !== null) {
			average = Math.round(
				((totals.totalCorrect + Number.EPSILON) / totals.totalQuestions) * 100
			);
		} else {
			average = 0;
		}
		resolve(average);
	});
};

export const getTestRecordsCorrectAndQuestionTotals = async (
	alltests: TestRecord[]
): Promise<TestRecordTotals> => {
	return await new Promise((resolve) => {
		let totalCorrect = 0;
		let totalQuestions = 0;
		if (alltests.length === 1) {
			totalCorrect = alltests[0].correctAnswers;
			totalQuestions = alltests[0].testLength;
		} else if (alltests.length > 0) {
			alltests.forEach((test) => {
				totalCorrect += test.correctAnswers;
				totalQuestions += test.testLength;
			});
		}
		resolve({ totalCorrect, totalQuestions });
	});
};
export const calcTestGrade = (
	totalCorrectAnswers: number,
	totalQuestions: number
): string => {
	if (totalQuestions === totalCorrectAnswers) {
		return '100.00';
	} else if (totalCorrectAnswers === 0) {
		return '0.00';
	} else {
		const testScore = (
			((totalCorrectAnswers + Number.EPSILON) / totalQuestions) *
			100
		).toFixed(2);
		return testScore;
	}
};
