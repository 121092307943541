import { Link } from 'react-router-dom';
import routes from '../../config/routes';

interface LinkToProps {
	title: string;
	route: string;
}

const FooterLink = ({ title, route }: LinkToProps) => {
	return (
		<Link
			to={route}
			className='my-3 block text-gray-300 hover:text-gray-100 text-sm font-medium duration-700'>
			{title}
		</Link>
	);
};
export default FooterLink;
