import { useState } from 'react';
import type { SyntheticEvent } from 'react';
import { useAdminContext } from '../../api/AdminContext';
import type { User } from '../../data/interface-user';
import MainSection from '../../layout/app/dashboard/MainSection';
import { convertFirebaseTSToDateString } from '../../utils/helpers';
import type { QuestionBankID } from '../../data/interface-question-bank';

interface Tab {
	id: QuestionBankID;
	name: string;
	href: string;
	current: boolean;
}
const UserReport = () => {
	const targetYear = 2025; // TODO: Make target year selectable from a dropdown list
	const { getUserList, users, setUsers } = useAdminContext();
	const [bankID, setbankID] = useState<QuestionBankID>('enurm');
	const [tabs, setTabs] = useState<Tab[]>([
		{ id: 'enurm', name: 'ENURM', href: '#', current: true },
		{ id: 'maxilofacial', name: 'ENURM-CMF', href: '#', current: false },
		{ id: 'dermatología', name: 'PrepDerm', href: '#', current: false }
		// { id: 'enurm', name: 'Billing', href: '#', current: false }
	]);

	function classNames(...classes: string[]) {
		return classes.filter(Boolean).join(' ');
	}

	const handleUserRequest = () => {
		getUserList(bankID, targetYear)
			.then((userList) => {
				console.log('User list:', userList);
			})
			.catch((error) => {
				console.error('Error fetching user list:', error);
			});
	};

	const handleTabChange = (tabID: QuestionBankID) => {
		setUsers([]);
		const newTabs = tabs.map((tab) => {
			if (tab.id === tabID) {
				return { ...tab, current: true };
			} else {
				return { ...tab, current: false };
			}
		});
		setTabs(newTabs);
		setbankID(tabID);
	};

	return (
		<MainSection title='User Report'>
			<div className='my-8 w-full'>
				<div className='px-4 sm:px-6 lg:px-8'>
					<div id='tabs' className='border-b'>
						<div className='sm:hidden'>
							<label htmlFor='tabs' className='sr-only'>
								Select a tab
							</label>
							{/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
							<select
								id='tabs'
								name='tabs'
								className='block w-full rounded-md border-gray-300 focus:border-brand-500 focus:ring-brand-500'
								onChange={(e) =>
									handleTabChange(e.target.value as QuestionBankID)
								}
								// defaultValue={
								// tabs?.find((tab: Tab) => tab?.current)?.name ?? ''
								// }
								defaultValue={bankID}>
								{tabs.map((tab) => (
									<option key={tab.id} value={tab.id}>
										{tab.name}
									</option>
								))}
							</select>
						</div>
						<div className='hidden sm:block'>
							<nav className='flex space-x-4' aria-label='Tabs'>
								{tabs.map((tab) => (
									<button
										key={tab.id}
										id={tab.id}
										className={classNames(
											tab.current
												? 'bg-brand-100 text-brand-700'
												: 'text-gray-500 hover:text-gray-700',
											'rounded-md px-3 py-2 text-sm font-medium'
										)}
										aria-current={tab.current ? 'page' : undefined}
										onClick={(e) => handleTabChange(tab.id)}>
										{tab.name}
									</button>
									// <a
									// key={tab.id}
									// href={tab.href}
									// className={classNames(
									// tab.current
									// ? 'bg-brand-100 text-brand-700'
									// : 'text-gray-500 hover:text-gray-700',
									// 'rounded-md px-3 py-2 text-sm font-medium'
									// )}
									// aria-current={tab.current ? 'page' : undefined}>
									// {tab.name}
									// </a>
								))}
							</nav>
						</div>
					</div>

					<div id='description' className='sm:flex sm:items-center'>
						<div className='sm:flex-auto'>
							<h1 className='text-base font-semibold leading-6 text-gray-900'>
								All Users
							</h1>
							<p className='mt-2 text-sm text-gray-700'>
								A list of all the users in our system account including their
								name, membership status, email and sign up date.
							</p>
						</div>
						<div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
							<button
								type='button'
								onClick={handleUserRequest}
								className='block rounded-md bg-brand-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600'>
								Get users
							</button>
						</div>
					</div>

					<div id='table' className='mt-8 flow-root'>
						<div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
							<div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
								<table className='min-w-full divide-y divide-gray-300'>
									<thead>
										<tr>
											<th
												scope='col'
												className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
												FirstName
											</th>
											<th
												scope='col'
												className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
												MiddleName
											</th>
											<th
												scope='col'
												className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
												LastName
											</th>
											<th
												scope='col'
												className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0'>
												SecondLastName
											</th>
											<th
												scope='col'
												className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
												Email
											</th>
											<th
												scope='col'
												className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
												Signup
											</th>
											<th
												scope='col'
												className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
												Customer
											</th>
											<th
												scope='col'
												className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
												Exam Target
											</th>
											<th
												scope='col'
												className='relative py-3.5 pl-3 pr-4 sm:pr-0'>
												<span className='sr-only'>Edit</span>
											</th>
										</tr>
									</thead>
									<tbody className='divide-y divide-gray-200'>
										{users !== undefined &&
											users.length > 0 &&
											users.map((user: User) => (
												<tr key={user.id}>
													<td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
														{user?.person?.firstName}
													</td>
													<td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
														{user?.person?.middleName}
													</td>
													<td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
														{user?.person?.lastName}
													</td>
													<td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0'>
														{user?.person?.secondLastName}
													</td>
													<td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
														{user.email}
													</td>
													<td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
														{convertFirebaseTSToDateString(user.signUpDate)}
													</td>
													<td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
														{user[`membership_${targetYear}`]?.isPaid ?? false
															? 'Paid'
															: ''}
														{/* // TODO:  */}
													</td>
													<td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
														{user.examTarget?.toUpperCase() ?? ''}
													</td>
													<td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
														{/* // TODO: Add edit functionality */}
														{/* <a
													href='#'
													className='text-brand-600 hover:text-brand-900'>
													Edit
													<span className='sr-only'>, {user.person.name}</span>
												</a> */}
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</MainSection>
	);
};
export default UserReport;
