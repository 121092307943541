import ImgWithFallback from '../components/ImgWithFallBack';

// ASSETS
import source from '../assets/images/coming-soon/coming-soon.webp';
import fallback from '../assets/images/coming-soon/coming-soon.jpg';

const ComingSoon = () => {
	return (
		<div className='container mx-auto'>
			<div className='w-1/2 mx-auto'>
				<ImgWithFallback src={source} fallback={fallback} />
			</div>
			<div className='hidden'>
				<h1>INICIAR SESION!</h1>
				<h2>INICIAR SESION!</h2>
				<h3>INICIAR SESION!</h3>
				<h4>INICIAR SESION!</h4>
				<h5>INICIAR SESION!</h5>
			</div>
			<div className='hidden'>
				<div className='w-1/5'>
					<div className='h-10 bg-blue-50'></div>
					<div className='h-10 bg-blue-100'></div>
					<div className='h-10 bg-blue-150'></div>
					<div className='h-10 bg-blue-200'></div>
					<div className='h-10 bg-blue-250'></div>
					<div className='h-10 bg-blue-300'></div>
					<div className='h-10 bg-blue-350'></div>
					<div className='h-10 bg-blue-400'></div>
					<div className='h-10 bg-blue-450'></div>
					<div className='h-10 bg-blue-500'></div>
					<div className='h-10 bg-blue-550'></div>
					<div className='h-10 bg-blue-600'></div>
					<div className='h-10 bg-blue-650'></div>
					<div className='h-10 bg-blue-700'></div>
					<div className='h-10 bg-blue-750'></div>
					<div className='h-10 bg-blue-800'></div>
					<div className='h-10 bg-blue-850'></div>
					<div className='h-10 bg-blue-900'></div>
					<div className='h-10 bg-blue-950'></div>
				</div>
				<div className='w-1/5'>
					<div className='h-10 bg-brand-50'></div>
					<div className='h-10 bg-brand-100'></div>
					<div className='h-10 bg-brand-150'></div>
					<div className='h-10 bg-brand-200'></div>
					<div className='h-10 bg-brand-250'></div>
					<div className='h-10 bg-brand-300'></div>
					<div className='h-10 bg-brand-350'></div>
					<div className='h-10 bg-brand-400'></div>
					<div className='h-10 bg-brand-500'></div>
					<div className='h-10 bg-brand-500'></div>
					<div className='h-10 bg-brand-550'></div>
					<div className='h-10 bg-brand-600'></div>
					<div className='h-10 bg-brand-650'></div>
					<div className='h-10 bg-brand-700'></div>
					<div className='h-10 bg-brand-750'></div>
					<div className='h-10 bg-brand-800'></div>
					<div className='h-10 bg-brand-850'></div>
					<div className='h-10 bg-brand-900'></div>
					<div className='h-10 bg-brand-950'></div>
				</div>
				<div className='w-1/5'>
					<div className='h-10 bg-alt1-50'></div>
					<div className='h-10 bg-alt1-100'></div>
					<div className='h-10 bg-alt1-150'></div>
					<div className='h-10 bg-alt1-200'></div>
					<div className='h-10 bg-alt1-250'></div>
					<div className='h-10 bg-alt1-300'></div>
					<div className='h-10 bg-alt1-350'></div>
					<div className='h-10 bg-alt1-400'></div>
					<div className='h-10 bg-alt1-450'></div>
					<div className='h-10 bg-alt1-500'></div>
					<div className='h-10 bg-alt1-550'></div>
					<div className='h-10 bg-alt1-600'></div>
					<div className='h-10 bg-alt1-650'></div>
					<div className='h-10 bg-alt1-700'></div>
					<div className='h-10 bg-alt1-750'></div>
					<div className='h-10 bg-alt1-800'></div>
					<div className='h-10 bg-alt1-850'></div>
					<div className='h-10 bg-alt1-900'></div>
					<div className='h-10 bg-alt1-950'></div>
				</div>
				<div className='w-1/5'>
					<div className='h-10 bg-alt2-50'></div>
					<div className='h-10 bg-alt2-100'></div>
					<div className='h-10 bg-alt2-150'></div>
					<div className='h-10 bg-alt2-200'></div>
					<div className='h-10 bg-alt2-250'></div>
					<div className='h-10 bg-alt2-300'></div>
					<div className='h-10 bg-alt2-350'></div>
					<div className='h-10 bg-alt2-400'></div>
					<div className='h-10 bg-alt2-450'></div>
					<div className='h-10 bg-alt2-500'></div>
					<div className='h-10 bg-alt2-550'></div>
					<div className='h-10 bg-alt2-600'></div>
					<div className='h-10 bg-alt2-650'></div>
					<div className='h-10 bg-alt2-700'></div>
					<div className='h-10 bg-alt2-750'></div>
					<div className='h-10 bg-alt2-800'></div>
					<div className='h-10 bg-alt2-850'></div>
					<div className='h-10 bg-alt2-900'></div>
					<div className='h-10 bg-alt2-950'></div>
				</div>
				<div className='w-1/5'>
					<div className='h-10 bg-alt3-50'></div>
					<div className='h-10 bg-alt3-100'></div>
					<div className='h-10 bg-alt3-150'></div>
					<div className='h-10 bg-alt3-200'></div>
					<div className='h-10 bg-alt3-250'></div>
					<div className='h-10 bg-alt3-300'></div>
					<div className='h-10 bg-alt3-350'></div>
					<div className='h-10 bg-alt3-400'></div>
					<div className='h-10 bg-alt3-450'></div>
					<div className='h-10 bg-alt3-500'></div>
					<div className='h-10 bg-alt3-550'></div>
					<div className='h-10 bg-alt3-600'></div>
					<div className='h-10 bg-alt3-650'></div>
					<div className='h-10 bg-alt3-700'></div>
					<div className='h-10 bg-alt3-750'></div>
					<div className='h-10 bg-alt3-800'></div>
					<div className='h-10 bg-alt3-850'></div>
					<div className='h-10 bg-alt3-900'></div>
					<div className='h-10 bg-alt3-950'></div>
				</div>
			</div>
		</div>
	);
};
export default ComingSoon;
