import TestProgress from './TestProgress';
import TestTimer from './TestTimer';

interface TestTimerAndProgressContainerProps {
	currentQuestion: number;
	total: number;
	useTimer: boolean;
	testDuration: number;
	stopTest: () => void;
	timeElapsed: number;
	setStartTime: (time: number) => void;
	updateTimeElapsed: (time: number) => void;
}
const TestTimerAndProgressContainer = ({
	currentQuestion,
	total,
	useTimer,
	stopTest,
	testDuration,
	timeElapsed,
	setStartTime,
	updateTimeElapsed
}: TestTimerAndProgressContainerProps) => {
	return (
		<div className='text-white flex justify-end'>
			<TestProgress current={currentQuestion} total={total} />

			{useTimer && (
				<TestTimer
					testDuration={testDuration}
					startTimer={useTimer}
					updateTimeElapsed={updateTimeElapsed}
					setStartTime={setStartTime}
					stopTest={stopTest}
					timeElapsed={timeElapsed}
				/>
			)}
		</div>
	);
};
export default TestTimerAndProgressContainer;
