import { Link } from 'react-router-dom';
import { useFlashcardContext } from '../../../../api/FlashcardsContext';
import Alert from '../../../../components/ui/Alert';
import type { Flashcard } from '../../../../data/interface-flashcard';
import type { Category } from '../../../../data/interface-question';
import {
	convertFirebaseTSToDate,
	removeHTMLTags
} from '../../../../utils/helpers';
import routes from '../../../../config/routes';

interface Flashcards {
	flashcards: Flashcard[];
	categories: Category[];
	isAdmin: boolean;
}
const ListOfFlashcards = ({ flashcards, categories, isAdmin }: Flashcards) => {
	const { deleteFlashcard, makeCreatedByPrepMed } = useFlashcardContext();
	const getCategories = (card: Flashcard) => {
		const x = categories.filter((c) => card.categories.includes(c.id));
		return x;
	};

	if (flashcards === undefined || flashcards.length === 0) {
		return (
			<Alert
				type='warning'
				title='No tienes
					flashcards disponibles'>
				{/* <h5 className='txt-alternate2 mt-1'>
					<i className='material-icons fsize-larger mr-1'>info</i>
				</h5> */}
				<p className='txt-black my-1'>
					Crear tus propios flashcards es fácil, rápido y altamente recomendado.
					¡Crea tu primer flashcard hoy!
				</p>
			</Alert>
		);
	}

	return (
		<div>
			{flashcards
				.sort((a: Flashcard, b: Flashcard) => {
					return convertFirebaseTSToDate(b.createTS) >
						convertFirebaseTSToDate(a.createTS)
						? 1
						: 0;
				})
				.map((card) => {
					return (
						<div
							key={card.id}
							className='bg-white shadow-md rounded px-8 py-4 mb-4'>
							<div className='flex justify-between items-center w-full mb-4'>
								{/* CARD CONTENT */}
								<div style={{ width: '75%' }}>
									<div className='flex items-center'>
										{card.imageFileName !== null && (
											<i className='material-icons small mr-4'>image</i>
										)}
										{removeHTMLTags(card.front)}
									</div>
									{isAdmin && (
										<>
											<br />
											<span className='gray-text text-lighten-2'>
												{removeHTMLTags(card.back)}
											</span>
										</>
									)}
								</div>
								{/* ACTIONS */}
								<div
									className='flex items-center justify-end space-y-2'
									style={{ width: '25%' }}>
									{isAdmin && card?.createdBy !== 'PrepMedRD' && (
										<button
											className='bg-orange-100 text-orange-500'
											onClick={() => makeCreatedByPrepMed(card)}>
											<i className='material-icons'>file_copy</i>Own
										</button>
									)}
									<Link
										className='flex items-center bg-brand-100 text-brand-500 hover:bg-brand-200 hover:text-brand-600 px-2 py-2 rounded-md font-semibold outline-none focus:outline-none text-xs mr-2 mt-2'
										to={
											routes.APP.FLASHCARDS.FLASHCARD_DETAILS_ID + '/' + card.id
										}
										state={card}>
										<i className='material-icons'>edit</i>
									</Link>
									<button
										className='flex items-center bg-red-100 text-red-500 hover:bg-red-200 hover:text-red-600 px-2 text-xs'
										onClick={() => deleteFlashcard(card.id)}>
										<i className='material-icons'>delete</i>
									</button>
								</div>
							</div>
							<div className='my-1'>
								{getCategories(card).map((i) => {
									return (
										<span
											className='inline-flex items-center rounded-full bg-brand-50 px-4 py-2 text-xs font-medium text-brand-800 ring-1 ring-inset ring-brand-700/10 m-auto'
											key={i.id}>
											{i.name}
										</span>
									);
								})}
							</div>
						</div>
					);
				})}
		</div>
	);
};
export default ListOfFlashcards;
