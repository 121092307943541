import { createContext, useContext, useEffect, useState } from 'react';
import type { ReactNode } from 'react';

export type LoadingContextType = {
	loading: boolean;
	// setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	setLoading: (v: boolean) => void;
	message: string;
	setLoadingMessage: (v: string) => void;
	hideSpinner: () => void;
	showSpinner: (message?: string) => void;
};

// Create the context
export const LoadingContext = createContext<LoadingContextType | null>(null);

export const LoadingContextProvider = ({
	children
}: {
	children: ReactNode;
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingMessage, setLoadingMessage] = useState<string>('Cargando...');

	const hideSpinner = () => {
		setLoading(false);
		setLoadingMessage('');
	};

	const showSpinner = (message = '') => {
		setLoadingMessage(message);
		setLoading(false);
	};

	return (
		<LoadingContext.Provider
			value={{
				hideSpinner,
				loading,
				setLoading,
				message: loadingMessage,
				setLoadingMessage,
				showSpinner
			}}>
			{children}
		</LoadingContext.Provider>
	);
};
export function useLoadingContext() {
	const context = useContext(LoadingContext);
	if (context === undefined || context === null) {
		throw new Error('useLoadingContext must be used inside a CounterProvider');
	}
	return context;
}
export default LoadingContextProvider;
