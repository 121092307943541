import Button from '../../../../components/ui/Button';
import Headline from '../../../../components/ui/Headline';
import type { SignupStepsIDs } from '../../../../data/interface-signup-steps';
import Card from '../../../../components/ui/Card';
interface ConfirmAccountCreationProps {
	nextStep: (step: SignupStepsIDs) => void;
}
const ConfirmAccountCreation = ({ nextStep }: ConfirmAccountCreationProps) => {
	return (
		<div className='container'>
			<Card>
				<Headline
					classes='text-brand-700 mb-8'
					isCentered={true}
					title='¡Tu Cuenta fue Creada!'
					type='h6'
				/>
				<p>
					Gracias por registrarte en nuestra plataforma. Tu cuenta ha sido
					creada exitosamente.
				</p>
				<p>
					Ahora necesitamos ciertos detalles adicionales para completar tu
					perfil.
				</p>
				<p>Por favor has click debajo para continuar.</p>
				<div className='flex items-center justify-end'>
					<Button
						title='Continuar >'
						bgColor='bg-brand-500'
						hoverBgColor='hover:bg-brand-550'
						handleClick={() => nextStep('detalles-personales')}
					/>
				</div>
			</Card>
		</div>
	);
};
export default ConfirmAccountCreation;
