import { useState, useEffect } from 'react';
import { secondsToHoursMinutesSeconds } from '../../../../utils/helpers';

interface TestTimerProps {
	startTimer: boolean;
	testDuration: number;
	stopTest: () => void;
	timeElapsed: number;
	setStartTime: (time: number) => void;
	updateTimeElapsed: (time: number) => void;
}
const TestTimer = ({
	startTimer,
	stopTest,
	testDuration,
	timeElapsed,
	setStartTime,
	updateTimeElapsed
}: TestTimerProps) => {
	const [timer, setTimer] = useState<number>(testDuration);

	useEffect(() => {
		if (startTimer) {
			const timerID = window.setInterval(() => {
				setTimer((prevTimer) => {
					// console.info(prevTimer, prevTimer === 0);
					if (prevTimer === 0) {
						window.clearInterval(timerID);
						stopTest();
						return 0;
					}
					return prevTimer - 1;
				});
			}, 1000);
			// return () => {
			// window.clearInterval(timerID);
			// };
		}
		return undefined;
		/* eslint-disable-next-line */
	}, [startTimer]);

	useEffect(() => {
		setStartTime(Date.now());
		return () => {
			if (timeElapsed !== 0) {
				updateTimeElapsed(Date.now());
			}
		};
		/* eslint-disable-next-line */
	}, []);

	return (
		<>
			<span id='timer-separator' className='mx-4'>
				{' '}
				|{' '}
			</span>
			<span className='inline-flex items-center'>
				{/* <i className='material-icons material-small-font mr-2'>timer</i>{' '} */}
				<svg
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 24 24'
					fill='currentColor'
					className='w-6 h-6 mr-2'>
					<path
						fillRule='evenodd'
						d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 6a.75.75 0 0 0-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 0 0 0-1.5h-3.75V6Z'
						clipRule='evenodd'
					/>
				</svg>
				{secondsToHoursMinutesSeconds(timer)}
			</span>
		</>
	);
};
export default TestTimer;
