const TestSelectionsDescription = () => {
	return (
		<div className='w-full lg:w-6/12 xl:w-7/12'>
			<p>
				Para examinarte debes elegir la especificación de tus preguntas, estas
				son tus opciones:
			</p>
			<ul className='normal-bullets'>
				<li>
					Tipo &quot;Pruebín&quot;, &quot;Simulación&quot; u otra cantidad de
					preguntas
				</li>
				<li>
					Sólo preguntas del ENURM, proprietarias de PrepMed o con todas las
					preguntas
				</li>
				<li>
					{' '}
					Modo &quot;Aleatorio&quot; o &quot;Categorías Específicas&quot;
				</li>
				<li>Con o sin límite de tiempo</li>
				<li>
					Con o sin modo tutor (El modo tutor te permite ver explicaciones de
					cada pregunta una vez eliges tu respuesta.)
				</li>
			</ul>
			<p>
				Un examen tipo &quot;Pruebín&quot; te presentará 50 preguntas, una
				&quot;Simulación&quot; te presentará 100 preguntas y además tendrás la
				opción de elegir otra cantidad de preguntas.
			</p>
			<p>
				El modo &quot;Aleatorio&quot;, eligirá preguntas al azar de todas las
				categorias, todos los examenes y proprietarias de PrepMed.
			</p>
			<p>
				El modo &quot;Categorías Espacíficas&quot;, te permitirá elegir las
				categorías de tus de preguntas.
			</p>
			<p>
				<strong>NOTA:</strong> Para un examen con límite de tiempo, te
				recomendamos:
			</p>
			<ul className='normal-bullets'>
				<li>1 hora para pruebines</li>
				<li>2 horas para simulaciones</li>
				<li>2 minutos por pregunta si es personalizado</li>
			</ul>
		</div>
	);
};
export default TestSelectionsDescription;
