import { useRef, useState } from 'react';

interface PasswordInputTypes {
	id: string;
	placeholder?: string;
	handleChange: (value: string) => void;
	handleValidity?: (value: boolean) => void;
}

const PasswordInput = ({
	id,
	placeholder = '',
	handleChange,
	handleValidity
}: PasswordInputTypes) => {
	const passwordRef = useRef<HTMLInputElement>(null);
	const [isValid, setIsValid] = useState<boolean | null>(null);
	const validatePassword = () => {
		const valid =
			passwordRef.current != null &&
			passwordRef.current.value.length > 0 &&
			passwordRef.current?.validity?.valid;

		if (typeof valid === 'boolean') {
			return valid;
		}
		return false;
	};
	const handleAction = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleChange(e.target.value);
		const validation = validatePassword();
		if (handleValidity != null) {
			handleValidity(validation);
			passwordRef.current != null &&
				passwordRef.current.value.length > 6 &&
				setIsValid(validation);
		}
	};

	const ringStyles = 'focus:ring focus:ring-opacity-50';
	const placholderStyles =
		'placeholder:italic placeholder:text-gray-400 placeholder:font-light placeholder:text-sm';
	return (
		<input
			autoComplete='off'
			type='password'
			id={id}
			className={`mt-1 mb-3
			py-2 px-3
			block
			w-full
			rounded-md
			border border-gray-300
			leading-tight
			shadow-sm
			text-gray-600 ${placholderStyles} ${ringStyles}  ${
				isValid !== null
					? isValid
						? 'focus:ring-brand-200 focus:border-brand-400'
						: 'focus:ring-red-400 focus:border-red-200'
					: 'focus:ring-brand-200 focus:border-brand-200'
			}`}
			placeholder={placeholder}
			pattern='^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$'
			ref={passwordRef}
			required
			// onBlur={() => setHideInfoMessage(true)}
			onChange={handleAction}
			// onFocus={() => setHideInfoMessage(false)}
		/>
	);
};

export default PasswordInput;
