import parseHTML from 'html-react-parser';
import {
	calcTestGrade,
	secondsToHoursMinutesSeconds
} from '../../../../utils/helpers';
import type { TestRecord } from '../../../../data/interface-question';

interface TestReviewSummaryProps {
	record: TestRecord;
	correctAnswers: number;
}

// Capitalize the first letter of the test type
const capitalize = (str: string) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
};

const TestReviewSummary = ({
	correctAnswers,
	record
}: TestReviewSummaryProps) => {
	return (
		<div className='bg-white shadow-xl w-full lg:w-3/4 mx-auto rounded-xl border border-gray-200 py-10 px-8 my-8 mt-10'>
			<div className='flex flex-col md:flex-row justify-around items-center mb-4'>
				<blockquote
					className='border-l-4 border-l-brand-500 pl-4 pt-2 md:pt-0 md:pb-2 mb-4 md:mb-0 md:ml-4'
					style={{ minHeight: '45px' }}>
					<b>Tipo de Examen:</b> {capitalize(record.testType)}
				</blockquote>
				<p className='ml-4 pt-2 md:pt-0'>
					{parseHTML(
						`En este examen viste un total de <strong>${record.testLength}</strong> preguntas para una nota final de:`
					)}
				</p>
			</div>
			<div className='flex justify-around'>
				<div className='flex' style={{ flexDirection: 'column' }}>
					<strong
						style={{ fontSize: 'xx-large' }}
						className={record.grade > 70 ? '' : 'text-red-500'}>
						{/* {record.grade}% */}
						{calcTestGrade(correctAnswers, record.questions.length)}%
					</strong>
					<small>Nota</small>
				</div>
				<div
					className='col s6 center d-flex'
					style={{ flexDirection: 'column' }}>
					<div>
						<strong style={{ fontSize: 'xx-large' }}>{correctAnswers}</strong>
						<span className='mx-2'>/</span>
						{/* {record.testLength}  */}
						{record.questions.length}
					</div>
					<small>Fracción</small>
				</div>
			</div>
			{record.useTimer && (
				<p className='mt-8'>
					Terminaste tu{' '}
					{record.testType === 'personalizado'
						? 'examen personalizado'
						: record.testType}{' '}
					en un tiempo total de:{' '}
					{secondsToHoursMinutesSeconds(record.timeElapsedInSeconds)}.
				</p>
			)}
		</div>
	);
};
export default TestReviewSummary;
