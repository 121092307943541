import { useEffect, useState } from 'react';
import '../../styles/ProgressBar.css';
// TODO: Add animated progress bar
// https://medium.com/swlh/creating-an-animated-progress-bar-in-react-5e85e8f6ec16

interface ProgressBarProps {
	width: number;
	percent: number;
}
const ProgressBar = ({ width, percent }: ProgressBarProps) => {
	// let progress = percent * width;
	const [value, setValue] = useState<number>(0);

	useEffect(() => {
		setTimeout(() => {
			setValue(percent);
		}, 500);
	}, []);

	return (
		<div className='my-4'>
			<div className='progress-div' style={{ width: `${width}%` }}>
				<div style={{ width: `${value}%` }} className='progress-bar' />
			</div>
		</div>
	);
};

export default ProgressBar;
