import { useEffect, useState } from 'react';
import FormContainer from '../../../../components/forms/FormContainer';
import Headline from '../../../../components/ui/Headline';
import type { SignupStepsIDs } from '../../../../data/interface-signup-steps';
import type { PersonName } from '../../../../data/interface-user';
import TextInputForm from '../../../../components/forms/TextInputForm';
import Button from '../../../../components/ui/Button';
import { useLoadingContext } from '../../../../context/LoadingContext';
import useUserContext from '../../../../api/UserContext';
import ErrorMessage from '../../../../components/forms/ErrorMessage';
import NumberInput from '../../../../components/forms/NumberInput';

interface SetupDetallesPersonalesProps {
	nextStep: (step: SignupStepsIDs) => void;
}
const SetupDetallesPersonales = ({
	nextStep
}: SetupDetallesPersonalesProps) => {
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { currentUser, updatePersonName } = useUserContext();
	const [isFormValid, setIsFormValid] = useState(false);
	const [name, setName] = useState<PersonName | null>(null);
	const [firstName, setFirstName] = useState<string | null>(null);
	const [middleName, setMiddleName] = useState<string>('');
	const [lastName, setLastName] = useState<string | null>(null);
	const [secondLastName, setSecondLastName] = useState<string>('');
	// DATE OF BIRTH
	const [dob, setDob] = useState<string | null>(null);
	const [isDobValid, setIsDobValid] = useState(false);
	// CEDULA
	const [cedula, setCedula] = useState<string>('');
	const [cedulaConf, setCedulaConf] = useState<string>('');
	const [isCedulaValid, setCedulaValida] = useState(false);
	// ERROR
	const [error, setError] = useState<string>('');

	useEffect(() => {
		if (
			firstName != null &&
			lastName != null &&
			isCedulaValid &&
			cedula !== '000-0000000-0' &&
			cedula !== '00000000000' &&
			dob != null &&
			isDobValid
		) {
			setName({
				firstName,
				middleName,
				lastName,
				secondLastName,
				initials: `${firstName.charAt(0)}${secondLastName.charAt(0)}`,
				cedula,
				dob
			});
		} else {
			isFormValid && setIsFormValid(false);
		}
	}, [
		firstName,
		middleName,
		lastName,
		secondLastName,
		cedula,
		cedulaConf,
		dob
	]);

	useEffect(() => {
		if (cedula !== cedulaConf) {
			setError('Las cédulas ingresadas no coinciden.');
			setIsFormValid(false);
		} else {
			setError('');
		}
	}, [cedulaConf]);

	useEffect(() => {
		if (name != null) {
			setIsFormValid(true);
		}
	}, [name]);

	const handleSignup = async () => {
		const userID = currentUser?.uid ?? '';
		if (userID !== '' && name != null) {
			const result = await updatePersonName(userID, name);
			if (result === 'user-updated') {
				nextStep('elige-tu-examen');
			} else {
				setError(result);
				setLoading(false);
			}
		}
	};

	const handleSubmit = (
		e:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.FormEvent<HTMLFormElement>
	) => {
		setLoadingMessage('Actualizando datos personales...');
		setLoading(true);

		e.preventDefault();
		handleSignup()
			.then(() => {
				setTimeout(() => {
					setLoading(false);
				}, 1000);
			})
			.catch((err) => console.error('Error creating user: ', err));
	};

	return (
		<FormContainer handleSubmit={(e) => handleSubmit(e)}>
			<Headline
				classes='text-brand-700 mb-8'
				isCentered={true}
				title='Datos Personales'
				type='h6'
			/>
			<TextInputForm
				id='firstName'
				isRequired={true}
				label='Primer Nombre'
				placeholder='¿Cuál es tu primer nombre?'
				handleChange={(e: string) => setFirstName(e)}
			/>
			<TextInputForm
				id='middleName'
				label='Segundo Nombre'
				placeholder='¿Cuál es tu segundo nombre?'
				handleChange={(e: string) => setMiddleName(e)}
			/>
			<TextInputForm
				id='lastName'
				isRequired={true}
				label='Apellido'
				placeholder='¿Cuál es tu apellido?'
				handleChange={(e: string) => setLastName(e)}
			/>
			<TextInputForm
				id='secondLastName'
				label='Segundo Apellido'
				placeholder='¿Cuál es tu segundo apellido?'
				handleChange={(e: string) => setSecondLastName(e)}
			/>
			<TextInputForm
				guideMessage='El formato debe ser: 000-0000000-0 - EJEMPLO: 001-1234567-1'
				id='cedula'
				isRequired={true}
				label='Cédula'
				pattern='^[0-9]{3}-[0-9]{7}-[0-9]$|^[0-9]{11}$'
				placeholder='¿Cuál es tu numero de cédula?'
				handleChange={(e: string) => setCedula(e)}
				handleValidity={(e: boolean) => setCedulaValida(e)}
			/>
			<TextInputForm
				guideMessage='Debes confirmar tu numero de cédula para continuar.'
				id='cedula-conf'
				isRequired={true}
				label='Confirma tu Cédula'
				pattern='^[0-9]{3}-[0-9]{7}-[0-9]$|^[0-9]{11}$'
				placeholder='Confirma tu numero de cédula'
				handleChange={(e: string) => setCedulaConf(e)}
			/>
			<TextInputForm
				guideMessage='El formato debe ser: MM/DD/AAAA - EJEMPLO: 01/01/2000'
				id='dob'
				isRequired={true}
				label='Fecha de Nacimiento'
				placeholder='¿Cuál es tu fecha de nacimiento?'
				handleChange={(e: string) => setDob(e)}
				handleValidity={(e: boolean) => setIsDobValid(e)}
				pattern='(\d){2}/(\d){2}/(1|2)(\d){3}'
				maxLength={10}
			/>

			{firstName != null &&
				lastName != null &&
				firstName !== '' &&
				lastName !== '' &&
				isCedulaValid &&
				cedula === cedulaConf &&
				isDobValid &&
				isFormValid && (
					<div className='flex items-center justify-end mt-4'>
						<Button
							title='Actualiza tus Datos'
							bgColor='bg-brand-500'
							hoverBgColor='hover:bg-brand-550'
							handleClick={(e) => handleSubmit(e)}
							type='submit'
						/>
					</div>
				)}

			<ErrorMessage error={error} showError={error !== ''} />
		</FormContainer>
	);
};
export default SetupDetallesPersonales;
