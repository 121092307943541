// import { useState, SyntheticEvent } from 'react';

import Card from '../ui/Card';

interface FormContainerTypes {
	children: React.ReactNode;
	handleSubmit: (
		value:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.FormEvent<HTMLFormElement>
	) => void;
}
const FormContainer = ({ children, handleSubmit }: FormContainerTypes) => {
	// const [error, setError] = useState<string | null>();

	// const handleSubmit = async () => {
	// let response = await onSubmit();
	// setError(response);
	// };

	const onSubmit = (
		e:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.FormEvent<HTMLFormElement>
	) => {
		e.preventDefault();
		handleSubmit(e);
	};

	return (
		// <form className='w-full md:w-2/5 mx-auto mt-12'>
		<form className='container' onSubmit={(e) => onSubmit(e)}>
			<Card>{children}</Card>
			{/* {error && <p>{error}</p>} */}
		</form>
	);
};

export default FormContainer;
