import { useState } from 'react';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import useUserContext from '../../../../api/UserContext';
import { useLoadingContext } from '../../../../context/LoadingContext';
import MainSection from '../../../../layout/app/dashboard/MainSection';
import type { Category } from '../../../../data/interface-question';
import SelectFlashcardsDescription from './SelectFlashcardsDescription';
import SelectFlashcardsForm from './SelectFlashcardsForm';

const SelectFlashcards = () => {
	// #region CONTEXT IMPORTS
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { currentUser } = useUserContext();
	// #endregion

	const isFreeTrialUser = currentUser?.freeTrial?.isActive ?? false;

	if (isFreeTrialUser) {
		return (
			<MainSection title='Examínate'>
				<div>
					Las cuentas de prueba no tienen acceso a esta función. Por favor
					actualiza tu cuenta para continuar.
				</div>
			</MainSection>
		);
	}

	if (currentUser !== null) {
		return (
			<MainSection title='Elige tus Flashcards'>
				<div className='flex flex-col lg:flex-row justify-between'>
					{/* ===== DESCRIPTION ===== */}
					<SelectFlashcardsDescription />
					{/* ===== FORM ===== */}
					<SelectFlashcardsForm />
				</div>
			</MainSection>
		);
	}
	return (
		<MainSection title='Examínate'>
			<div>
				Hubo un error de carga, por favor recarga la pagina o contacta soporte
				al cliente.
			</div>
		</MainSection>
	);
};
export default SelectFlashcards;
