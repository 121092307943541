import { useEffect } from 'react';
import type { SignupStepsIDs } from '../../../data/interface-signup-steps';
import ChooseYourTest from './steps/ChooseYourTest';
import ConfirmAccountCreation from './steps/ConfirmAccountCreation';
import InformacionAdicional from './steps/InformacionAdicional';
import SetupCredentials from './steps/SetupCredentials';
import SetupDetallesPersonales from './steps/SetupDetallesPersonales';
import { useNavigate } from 'react-router-dom';
import routes from '../../../config/routes';
interface SignupStepProps {
	currentStep: SignupStepsIDs;
	nextStep: (step: SignupStepsIDs) => void;
}

const SignupSteps = ({
	currentStep,
	nextStep
}: SignupStepProps): JSX.Element => {
	const navigateTo = useNavigate();
	// TODO: implement this style of "steps":
	// https://youtu.be/C-Loox86XGY?list=PLG-Mk4wQm9_LyKE5EwoZz2_GGXR-zJ5Ml&t=284

	useEffect(() => {
		if (currentStep === 'completa-tu-pago') {
			navigateTo(routes.CHECKOUT.CHECKOUT);
		}
	}, [currentStep]);
	return (
		<>
			{/* {currentStep === 'crea-tu-cuenta' && (
				<StepSetupCredentials nextStep={(x: SignupStepsIDs) => nextStep(x)} />
			)} */}
			{currentStep === 'crea-tu-cuenta' && (
				<SetupCredentials nextStep={(x: SignupStepsIDs) => nextStep(x)} />
			)}
			{currentStep === 'cuenta-creada' && (
				<ConfirmAccountCreation nextStep={(x: SignupStepsIDs) => nextStep(x)} />
			)}
			{currentStep === 'detalles-personales' && (
				<SetupDetallesPersonales
					nextStep={(x: SignupStepsIDs) => nextStep(x)}
				/>
			)}
			{currentStep === 'elige-tu-examen' && (
				<ChooseYourTest nextStep={(x: SignupStepsIDs) => nextStep(x)} />
			)}
			{currentStep === 'informacion-adicional' && (
				<InformacionAdicional nextStep={(x: SignupStepsIDs) => nextStep(x)} />
			)}
		</>
	);
};
export default SignupSteps;
