export const universities = Object.freeze({
	DR: [
		{ id: 'INTEC', value: 'INTEC' },
		{ id: 'PUCMM', value: 'PUCMM' },
		{ id: 'O&M', value: 'O&M' },
		{ id: 'UASD', value: 'UASD' },
		{ id: 'UCATECI', value: 'UCATECI' },
		{ id: 'UCE', value: 'UCE' },
		{ id: 'UCNE', value: 'UCNE' },
		{ id: 'UNIBE', value: 'UNIBE' },
		{ id: 'UNPHU', value: 'UNPHU' },
		{ id: 'UTESA', value: 'UTESA' },
		{ id: 'otra', value: 'Otra universidad' }
	]
});
