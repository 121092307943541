import { CheckIcon } from '@heroicons/react/20/solid';
import type { SignupSteps } from '../../data/interface-signup-steps';

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

const StepsWithDots = ({ steps }: SignupSteps) => {
	return (
		<>
			<nav aria-label='Progress' className='py-10 '>
				<ol role='list' className='flex items-center '>
					{steps.map((step, stepIdx) => (
						<li
							key={step.name}
							className={classNames(
								stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-14' : '',
								'relative'
							)}>
							{step.status === 'complete' ? (
								<>
									<div
										className='absolute inset-0 flex items-center'
										aria-hidden='true'>
										<div className='h-0.5 w-full bg-brand-400' />
									</div>
									<div className='relative flex h-8 w-8 items-center justify-center rounded-full bg-brand-400 '>
										<CheckIcon
											className='h-5 w-5 text-white'
											aria-hidden='true'
										/>
										<span className='sr-only'>{step.name}</span>
									</div>
								</>
							) : step.status === 'current' ? (
								<>
									<div
										className='absolute inset-0 flex items-center'
										aria-hidden='true'>
										<div className='h-0.5 w-full bg-gray-200' />
									</div>
									<div
										className='relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-brand-400 bg-white'
										aria-current='step'>
										<span
											className='h-2.5 w-2.5 rounded-full bg-brand-400'
											aria-hidden='true'
										/>
										<span className='sr-only'>{step.name}</span>
									</div>
								</>
							) : (
								<>
									<div
										className='absolute inset-0 flex items-center'
										aria-hidden='true'>
										<div className='h-0.5 w-full bg-gray-200' />
									</div>
									<div className='group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400'>
										<span
											className='h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300'
											aria-hidden='true'
										/>
										<span className='sr-only'>{step.name}</span>
									</div>
								</>
							)}
						</li>
					))}
				</ol>
			</nav>
		</>
	);
};
export default StepsWithDots;
