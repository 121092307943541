import { SyntheticEvent, useRef, useState } from 'react';

interface TextInputTypes {
	id: string;
	placeholder: string;
	handleChange: (value: string) => void;
	handleValidity?: (value: boolean) => void;
}

/**
 * Takes the label (and icons) as children
 */
const EmailInput = ({
	id,
	placeholder = '¿Cuál es tu correo electrónico de preferencia?',
	handleChange,
	handleValidity
}: TextInputTypes) => {
	const emailRef = useRef<HTMLInputElement>(null);
	const [isValid, setIsValid] = useState<boolean | null>(null);
	const validateEmail = (): boolean => {
		const valid =
			emailRef.current != null &&
			emailRef.current.value.length > 0 &&
			emailRef.current?.validity?.valid;
		if (typeof valid === 'boolean') {
			return valid;
		}
		return false;
	};

	const handleAction = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const value = e.target.value.toLowerCase().trim();
		handleChange(value);
		emailRef.current != null && (emailRef.current.value = value);
		const validation = validateEmail();
		if (handleValidity !== null) {
			handleValidity?.(validation);
			emailRef.current != null &&
				emailRef?.current?.value.includes('@') &&
				setIsValid(validation);
		}
	};

	const ringStyles = 'focus:ring focus:ring-opacity-50';
	const placholderStyles =
		'placeholder:italic placeholder:text-gray-400 placeholder:font-light placeholder:text-sm';
	return (
		<input
			// autoComplete='off'
			type='email'
			id={id}
			className={`mt-1 mb-3
			py-2 px-3
			block
			w-full
			rounded-md
			border border-gray-300
			leading-tight
			shadow-sm
			text-gray-600 ${placholderStyles} ${ringStyles}  ${
				isValid !== null
					? isValid
						? 'focus:ring-brand-200 focus:border-brand-400'
						: 'focus:ring-red-400 focus:border-red-200'
					: 'focus:ring-brand-200 focus:border-brand-200'
			}`}
			placeholder={placeholder}
			pattern='[\S]+@[\S]+\.[\S]+'
			// pattern={patternValidation.EMAIL}
			ref={emailRef}
			// onBlur={() =>
			// emailRef.current &&
			// emailRef.current.value.length > 0 &&
			// assignValidityClasses()
			// }
			// onChange={(e) => handleChange(e.target.value)}
			onChange={handleAction}
			// onFocus={() =>
			// emailRef.current &&
			// emailRef.current.value.length > 0 &&
			// setShowInfoMessage(false)
			// }
			required
		/>
	);
};

export default EmailInput;
