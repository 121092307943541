import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../../hooks/usePagination';
// import './pagination.scss';

interface PaginationTypes {
	onPageChange: (v: number) => void;
	totalCount: number;
	siblingCount: number;
	currentPage: number;
	pageSize: number;
	className: string;
}

// TODO: IMPROVE LOOK AND FEEL WITH TAILWIND CSS: https://tailwindui.com/components/application-ui/navigation/pagination
const Pagination = ({
	onPageChange,
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize,
	className
}: PaginationTypes) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize
	});
	const validPagination =
		paginationRange !== undefined && paginationRange?.length < 2;
	if (validPagination || currentPage === 0) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	const lastPage =
		paginationRange !== undefined &&
		paginationRange[paginationRange.length - 1];

	if (paginationRange === undefined) return null;
	return (
		<ul
			className={classnames('pagination-container', {
				[className]: className
			})}>
			<li
				className={classnames('pagination-item', {
					disabled: currentPage === 1
				})}
				onClick={onPrevious}>
				<div className='arrow left' />
			</li>

			{paginationRange.map((pageNumber: number | string) => {
				if (pageNumber === DOTS) {
					return (
						<li className='pagination-item dots' key={pageNumber}>
							&#8230;
						</li>
					);
				}

				if (typeof pageNumber === 'number') {
					return (
						<li
							className={classnames('pagination-item', {
								selected: pageNumber === currentPage
							})}
							onClick={() => onPageChange(pageNumber)}
							key={pageNumber}>
							{pageNumber}
						</li>
					);
				}

				return null;
			})}
			<li
				className={classnames('pagination-item', {
					disabled: currentPage === lastPage
				})}
				onClick={onNext}>
				<div className='arrow right' />
			</li>
		</ul>
	);
};

export default Pagination;
