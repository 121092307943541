import { useEffect, useState } from 'react';
import { Form, Link, useNavigate } from 'react-router-dom';
import Headline from '../../components/ui/Headline';
import Logo from '../../components/Logo';
import routes from '../../config/routes';
import useHideElementOnMount from '../../hooks/useHideElementOnMount';
import { useLoadingContext } from '../../context/LoadingContext';
import ErrorMessage from '../../components/forms/ErrorMessage';
import PasswordInput from '../../components/forms/PasswordInput';
import EmailInput from '../../components/forms/EmailInput';
import FormLabel from '../../components/forms/FormLabel';
import Button from '../../components/ui/Button';
import FormContainer from '../../components/forms/FormContainer';
import MainTag from '../../layout/Main';
import { useAuthContext } from '../../api/AuthContext';

const Login = () => {
	useHideElementOnMount('header');
	useHideElementOnMount('footer');
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { loginUser } = useAuthContext();
	const navigateTo = useNavigate();

	// EMAIL
	const [email, setEmail] = useState<string>('');
	const [isValidEmail, setEmailValidity] = useState<boolean>(false);

	// PASSWORD
	const [password, setPassword] = useState<string>('');
	const [isValidPassword, setPasswordValidity] = useState<boolean>(false);

	// ERROR
	const [error, setError] = useState<string>('');

	const handleSubmit = (
		e:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.FormEvent<HTMLFormElement>
	) => {
		e.preventDefault();

		handleLogin()
			.then((result) => {
				const { isLoggedIn, message, isRedirectNeeded, redirectPath } = result;

				if (isLoggedIn) {
					if (isRedirectNeeded) {
						navigateTo(redirectPath);
					} else {
						setLoading(false);
						setError(message);
					}
				} else {
					setLoading(false);
					setError(message);
				}
			})
			.catch((err) => {
				console.info(err);
				setLoading(false);
				setError(
					'Error: Correo o Contraseña Incorrectos. Por favor intenta de nuevo.'
				);
			});
	};

	const handleLogin = async () => {
		const result = await loginUser(email, password);
		return result;
	};

	return (
		<MainTag>
			<div className='flex flex-col items-center mb-4 mt-10'>
				<Link to={routes.HOME} className='mb-4'>
					<Logo useDarkLogo={true} classes='h-16' />
				</Link>

				<FormContainer handleSubmit={(e) => handleSubmit(e)}>
					<Headline
						classes='text-brand-700 mb-8 uppercase'
						isCentered={true}
						title='Iniciar Sesión'
						type='h6'
					/>
					<div className='mb-4'>
						<FormLabel label='Correo Electrónico' />
						<EmailInput
							id='email'
							placeholder='¿Cuál es tu correo electrónico de preferencia?'
							handleChange={(e) => setEmail(e)}
							handleValidity={(v: boolean) => setEmailValidity(v)}
						/>
					</div>
					<div className='mb-6'>
						<FormLabel label='Contraseña' />
						<PasswordInput
							id='password'
							placeholder='Ingresa tu contraseña'
							handleChange={(v: string) => setPassword(v)}
							handleValidity={(v: boolean) => setPasswordValidity(v)}
						/>
					</div>
					<ErrorMessage
						classes='mt-4'
						error='El correo no está en un formato aceptado'
						showError={email !== '' && !isValidEmail}
					/>
					<ErrorMessage
						classes='mt-4'
						error='La contraseña debe tener al menos 1 letra mayúscula, 1 minúscula, 1 número y un mínimo de 6 caracteres. '
						showError={password !== '' && !isValidPassword}
					/>
					<div className='flex items-center justify-between flex-wrap'>
						<Button
							bgColor='bg-brand-500'
							hoverBgColor='hover:bg-brand-550'
							isDisabled={!(isValidEmail && isValidPassword)}
							handleClick={(e) => handleSubmit(e)}
							title='Acceder'
							type='submit'
						/>
						<a
							className='inline-block align-baseline text-sm'
							href={routes.AUTHENTICATION.NEW_PASSWORD}>
							¿Olvidaste tu Contraseña?
						</a>
					</div>

					<ErrorMessage classes='mt-4' error={error} showError={error !== ''} />
				</FormContainer>

				<p className='text-center text-gray-500 text-xs'>
					Si aún no tienes cuenta,{' '}
					<Link to={routes.AUTHENTICATION.SIGNUP}>¡registrala hoy!</Link>
				</p>
			</div>
		</MainTag>
	);
};

export default Login;
