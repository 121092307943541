import type { OptionObjMustHaves } from '../interface-utils';

export const quantities: OptionObjMustHaves[] = [
	{ id: '10', value: '10 Flashcards' },
	{ id: '20', value: '20 Flashcards' },
	{ id: '30', value: '30 Flashcards' },
	{ id: '40', value: '40 Flashcards' },
	{ id: '50', value: '50 Flashcards' },
	{ id: 'otra-cantidad', value: 'Elige otra cantidad' }
];

export const bank: OptionObjMustHaves[] = [
	{ id: 'todos', value: 'Todas las flashcards' },
	{ id: 'prepmed', value: 'Flashcards de PrepMed' },
	{ id: 'personales', value: 'Tus flashcards' }
];

export const modo: OptionObjMustHaves[] = [
	{ id: 'aleatorio', value: 'Modo aleatorio' },
	{ id: 'por-categoria', value: 'Categoría' }
];
