import type {
	IReducerAction,
	IQuestionBankReducerState,
	Question,
	QuestionRecord,
	TestType
} from '../data/interface-question';
import useUserContext from './UserContext';

export const qbrTypes = {
	ADD_QUESTION: 'add_question',
	DELETE_FROM_QUESTION_KEY: 'deleteKeyFromQuestion',
	GET_QUESTION_BANK: 'get_question_bank',
	GET_QUESTIONS: 'get_questions',
	GET_ESPECIALIDADES: 'GET_ESPECIALIDADES',
	GET_CATEGORIES: 'get_categories',
	FILTER_QUESTIONS: 'filter_questions',
	FILTER_QUESTIONS_BY_SUBCATEGORIES: 'filter_questions_by_subcategories',
	LOAD_CORRECT_INCORRECT_QUESTIONS: 'load_correct_incorrect_questions',
	SUBCATEGORY_LIST_ADD: 'subcategory_list_add',
	SUBCATEGORY_LIST_REMOVE: 'subcategory_list_remove',
	TEST_LABEL: 'test_label',
	TEST_MODE: 'test_mode',
	TEST_QUESTIONS_TYPE_CHANGE: 'test_questions_type_change',
	TEST_TIMER_DURATION: 'test_timer_duration',
	TEST_TOPIC_SUBCATEGORIES_CHANGE: 'test_topic_subcategories',
	TEST_TYPE: 'test_type',
	TEST_TYPE_CUSTOM_LENGTH: 'test_type_custom_length',
	TEST_TOPICS_CHANGE: 'test_topics',
	TOPIC_ADD: 'topic_add',
	TOPIC_REMOVE: 'topic_remove',
	UPDATE_QUESTIONS: 'update_questions',
	UPDATE_SCORE: 'update_score',
	UPDATE_TIMER: 'update_timer',
	UPDATE_TIMER_START: 'update_timer_start',
	USE_TIMER: 'use_timer',
	USE_TUTOR_MODE: 'use_tutor_mode'
};

// const QuestionBankReducer = async (
// https://stackoverflow.com/questions/60279256/async-await-in-switch-case-statement-does-not-work
const QuestionBankReducer = (
	state: IQuestionBankReducerState,
	action: IReducerAction
) => {
	const { currentUser } = useUserContext();
	const {
		ADD_QUESTION,
		DELETE_FROM_QUESTION_KEY,
		GET_QUESTIONS,
		GET_ESPECIALIDADES,
		GET_CATEGORIES,
		LOAD_CORRECT_INCORRECT_QUESTIONS,
		TEST_LABEL,
		TEST_MODE,
		TEST_QUESTIONS_TYPE_CHANGE,
		TEST_TIMER_DURATION,
		SUBCATEGORY_LIST_ADD,
		SUBCATEGORY_LIST_REMOVE,
		TEST_TYPE,
		TEST_TYPE_CUSTOM_LENGTH,
		// TEST_TOPICS_CHANGE,
		TOPIC_ADD,
		TOPIC_REMOVE,
		UPDATE_QUESTIONS,
		UPDATE_SCORE,
		UPDATE_TIMER,
		UPDATE_TIMER_START,
		USE_TIMER,
		USE_TUTOR_MODE
	} = qbrTypes;

	const newSetOfQuestions: string[] = [];

	const uid = currentUser?.uid;
	const testRecords = state.testRecords;

	const getFilteredCategories = () => {
		return state.categories
			.filter((category) => category.id !== 'M8rTWnWd2Wh9WLZtsMQn') // Evaluacion diagnostica 1
			.filter((category) => category.id !== 'DpVZVQbCpngLsSuCXX8J') // Evaluacion diagnostica 2
			.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
	};

	const filterQuestionsByTopic = (id: string, isChecked: boolean) => {
		let newQuestions: Question[];
		if (isChecked) {
			newQuestions = state.questions.filter((question) => {
				if (question.categoryID === id) return true;
				return state.topics.some((each) => each === question.categoryID);
			});
		} else {
			newQuestions = filterQuestionsTopicRemove(id);
		}
		// if (state.topics.includes(topic)) {
		// newQuestions = state.allQuestions.filter((question) => {
		// return state.topics.some((each) => each === question.categoryID);
		// });
		// } else {
		// newQuestions = filterQuestionsTopicRemove(topic);
		// }
		return newQuestions;
	};

	const filterQuestionsTopicRemove = (lastAdded: string) => {
		const newTopics = [
			...state.topics.filter((value) => {
				return value !== lastAdded;
			})
		];

		if (newTopics.length > 0) {
			return [
				...state.questions.filter((question) => {
					return newTopics.some((each) => each === question.categoryID);
				})
			];
		} else {
			return [...state.allQuestions];
		}
	};

	const filterQuestionsBySubCategory = (subCategory: string) => {
		const newQuestions: Question[] = state.questions.filter((question) => {
			return [...state.topicSubcategories, subCategory].some((topic) =>
				question.subCategoryList?.includes(topic)
			);
		});
		return newQuestions;
	};

	const filterQuestionsTopicSubCategoryRemove = (subCategory: string) => {
		const newTopicsSubcategories = state.topicSubcategories.filter((value) => {
			return value !== subCategory;
		});

		if (newTopicsSubcategories.length > 0) {
			return state.questions.filter((question) => {
				return newTopicsSubcategories.some((t) =>
					question.subCategoryList?.includes(t)
				);
			});
		} else {
			return state.allQuestions;
		}
	};

	const getTestlength = (testType: TestType | null): number => {
		if (testType === 'pruebín') {
			return 50;
		} else if (testType === 'simulación') {
			return 100;
		} else {
			return 0;
		}
	};

	const questionsTaken: QuestionRecord[] = [];
	let idsOfQuestionsTaken: string[] = [];

	// const filterTopicsList = (
	// topics: string[],
	// categoryID: string,
	// isChecked: boolean
	// ): string[] => {
	// let topicList: string[];
	// if (isChecked) {
	// topicList = [...topics, categoryID];
	// } else {
	// topicList = [
	// ...topics.filter((v) => {
	// return v !== categoryID;
	// })
	// ];
	// }

	// // if (payload.includes(categoryID)) {
	// // topicList = [
	// // ...payload.filter((v) => {
	// // return v !== action.payload;
	// // })
	// // ];
	// // } else {
	// // topicList = [...payload, categoryID];
	// // }
	// return topicList;
	// };

	const filterSubCatList = (subCat: string[], categoryID: string): string[] => {
		let subcategoryList: string[];
		if (subCat.includes(categoryID)) {
			subcategoryList = [
				...state.topicSubcategories.filter((v) => {
					return v !== action.payload;
				})
			];
		} else {
			subcategoryList = [...subCat, action.payload];
		}

		return subcategoryList;
	};

	const updateBoolean = (useTimer: boolean): boolean => {
		if (typeof action.payload === 'boolean') {
			return !useTimer;
		} else {
			return false;
		}
	};

	const getFilteredQuestions = (value: string) => {
		const result = state.allQuestions.filter((question) => {
			return action.payload === question.source;
		});
		return result;
	};

	let questionID: string,
		keyToChange,
		newValue,
		thisQuestion,
		updatedQuestion,
		questionToDeleteKeyFrom,
		questionWithoutIssues,
		newArrayOfQuestions;

	switch (action.type) {
		case GET_CATEGORIES:
			return {
				...state,
				categories: action.payload
			};
		case GET_ESPECIALIDADES:
			return {
				...state,
				especialidades: [...state.especialidades, action.payload]
			};
		case GET_QUESTIONS:
			return {
				...state,
				allQuestions: action.payload === undefined ? [] : [...action.payload],
				questions: action.payload === undefined ? [] : [...action.payload]
			};
		case ADD_QUESTION:
			return {
				...state,
				questions: [...state.questions, action.payload]
			};
		case LOAD_CORRECT_INCORRECT_QUESTIONS:
			return {
				...state,
				userCorrectQuestions: action.payload.correctas,
				userIncorrectQuestions: action.payload.incorrectas
			};
		case TEST_LABEL:
			if (action.payload === 'correctas') {
				return {
					...state,
					questions: state.questions.filter((q) => {
						return state.userCorrectQuestions?.includes(q.id);
					}),
					testLabel: 'correctas',
					showCategorySection: true
				};
			}

			if (action.payload === 'incorrectas') {
				return {
					...state,
					questions: state.questions.filter((q) => {
						return state.userIncorrectQuestions?.includes(q.id);
					}),
					testLabel: 'incorrectas',
					showCategorySection: true
				};
			}
			if (action.payload === 'all' || action.payload === 'prepmed') {
				return {
					...state,
					questions: [...state.allQuestions],
					showCategorySection: true,
					testLabel: action.payload
				};
				// window.location.reload(false);
			} else {
				return {
					...state,
					questions: getFilteredQuestions(action.payload),
					showCategorySection:
						action.payload !== 'all' || action.payload !== 'prepmed',
					testLabel: action.payload
				};
			}
		case TEST_MODE:
			return {
				...state,
				testMode: action.payload,
				showTopicSelectionBoxes: action.payload === 'categorías',
				categorias: getFilteredCategories()
			};
		case TEST_QUESTIONS_TYPE_CHANGE:
			if (action.payload === 'nuevas') {
				state.testRecords.forEach((tr) => questionsTaken.push(...tr.questions));
				idsOfQuestionsTaken = [
					...questionsTaken
						.map((item) => item.id)
						.filter((value, index, self) => self.indexOf(value) === index)
				];

				const qs = state.questions.filter((q) => {
					return !idsOfQuestionsTaken.includes(q.id);
				});
				return {
					...state,
					questions: qs,
					testLabel: 'nuevas',
					showCategorySection: true
				};
			}

			if (action.payload === 'repetidas') {
				state.testRecords.forEach((tr) => questionsTaken.push(...tr.questions));
				idsOfQuestionsTaken = [
					...questionsTaken
						.map((item) => item.id)
						.filter((value, index, self) => self.indexOf(value) === index)
				];
				const qs = state.questions.filter((q) => {
					return idsOfQuestionsTaken.includes(q.id);
				});
				return {
					...state,
					questions: qs,
					testLabel: 'repetidas',
					showCategorySection: true
				};
			}

			return {
				...state,
				questions: [...state.allQuestions],
				showCategorySection: true,
				testLabel: action.payload
			};
		case TEST_TIMER_DURATION:
			return {
				...state,
				testDuration: action.payload
			};
		// case TEST_TOPICS_CHANGE:
		// return {
		// ...state,
		// topics: filterTopicsList(
		// state.topics,
		// action.payload.id,
		// action.payload.isChecked
		// ),
		// questions: filterQuestionsByTopic(
		// action.payload.id,
		// action.payload.isChecked
		// )
		// };
		case TOPIC_ADD:
			return {
				...state,
				topics: [...state.topics, action.payload],
				questions: filterQuestionsByTopic(action.payload, true)
			};
		case TOPIC_REMOVE:
			return {
				...state,
				topics: [
					...state.topics.filter((v) => {
						return v !== action.payload;
					})
				],
				questions: filterQuestionsByTopic(action.payload, false)
			};
		case SUBCATEGORY_LIST_ADD:
			return {
				...state,
				topicSubcategories: filterSubCatList(
					state.topicSubcategories,
					action.payload
				),
				questions: filterQuestionsBySubCategory(action.payload)
			};
		case SUBCATEGORY_LIST_REMOVE:
			return {
				...state,
				topicSubcategories: filterSubCatList(
					state.topicSubcategories,
					action.payload
				),
				questions: filterQuestionsTopicSubCategoryRemove(action.payload)
			};
		case TEST_TYPE:
			return {
				...state,
				testLength: getTestlength(action.payload),
				testType: action.payload
			};
		case TEST_TYPE_CUSTOM_LENGTH:
			return {
				...state,
				testLength: action.payload
			};
		case UPDATE_QUESTIONS:
			questionID = action.payload[0];
			keyToChange = action.payload[1];
			newValue = action.payload[2];
			thisQuestion = state.questions.filter(
				(question) => question.id === questionID
			);

			updatedQuestion = {
				...thisQuestion[0],
				[keyToChange]: newValue
			};

			// TODO: WHY IS THIS NOT IN USE?
			// newSetOfQuestions = [
			// ...state.questions.filter((q) => {
			// return q.id !== questionID;
			// }),
			// updatedQuestion
			// ];
			return {
				...state,
				questions: [
					...state.questions.filter((q) => {
						return q.id !== questionID;
					}),
					updatedQuestion
				]
			};
		case UPDATE_SCORE:
			return {
				...state,
				score: Number(action.payload) + 1
			};
		case UPDATE_TIMER:
			return {
				...state,
				timeElapsedInSeconds: Math.floor(
					(action.payload - state.timerStartTime) / 1000
				)
			};
		case UPDATE_TIMER_START:
			return {
				...state,
				timerStartTime: action.payload
			};
		case USE_TIMER:
			return {
				...state,
				useTimer: updateBoolean(action.payload)
			};
		case USE_TUTOR_MODE:
			return {
				...state,
				useTutorMode: updateBoolean(action.payload)
			};
		case DELETE_FROM_QUESTION_KEY:
			questionToDeleteKeyFrom = state.questions.filter(
				(question) => question.id === action.payload.id
			);

			questionWithoutIssues = { ...questionToDeleteKeyFrom[0] };
			// TODO: UNcomment this and fix the issue
			// delete questionWithoutIssues[action.payload.key];

			// TODO: WHY IS THIS NOT IN USE?
			newArrayOfQuestions = [
				...state.questions.filter((q) => {
					return q.id !== action.payload.id;
				}),
				questionWithoutIssues
			];

			return {
				...state,
				questions: [
					...state.questions.filter((q) => {
						return q.id !== action.payload.id;
					}),
					questionWithoutIssues
				]
			};
		default:
			return state;
	}
};

export default QuestionBankReducer;
