import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import routes from '../../config/routes';
import { Link } from 'react-router-dom';

const solutions = [
	{
		name: 'ENURM',
		description:
			'Para aspirantes a Residencias Médicas en la República Dominicana',
		href: routes.PRODUCT.ENURM
	},
	{
		name: 'CMF',
		description: 'Para aspirantes a residencias de Cirugía Buco-Maxilo-Facial',
		href: routes.PRODUCT.CMF
	},
	{
		name: 'PrepDerm',
		description: 'Para aspirantes a residencias de Dermatología',
		href: routes.PRODUCT.PREPDERM
	}
];

const Productos = () => {
	return (
		<Popover className='relative'>
			<Popover.Button className='inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 focus-visible:outline-0 hover:text-brand-700'>
				<span>Productos</span>
				<ChevronDownIcon className='h-5 w-5' aria-hidden='true' />
			</Popover.Button>

			<Transition
				as={Fragment}
				enter='transition ease-out duration-200'
				enterFrom='opacity-0 translate-y-1'
				enterTo='opacity-100 translate-y-0'
				leave='transition ease-in duration-150'
				leaveFrom='opacity-100 translate-y-0'
				leaveTo='opacity-0 translate-y-1'>
				<Popover.Panel className='absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4'>
					<div className='w-screen max-w-sm flex-auto rounded-3xl bg-white p-4 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5'>
						{solutions.map((item) => (
							<div
								key={item.name}
								className='relative rounded-lg p-4 hover:bg-gray-50'>
								<Link to={item.href} className='font-semibold text-gray-900 '>
									{item.name}
									<span className='absolute inset-0' />
								</Link>
								<p className='mt-1 text-gray-600'>{item.description}</p>
							</div>
						))}
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	);
};
export default Productos;
