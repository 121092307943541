import { createContext, useContext, useState } from 'react';
import type { ReactNode } from 'react';

import { db, handleEventLog } from '../gcp/config';

import type { FirebaseError } from 'firebase/app';
import {
	getStorage,
	ref,
	getDownloadURL,
	uploadBytesResumable
} from 'firebase/storage';
import useUserContext from './UserContext';
import {
	addDoc,
	collection,
	getDocs,
	query,
	where,
	doc,
	updateDoc,
	deleteDoc,
	type Query,
	type DocumentData
} from 'firebase/firestore';
import { buildFlashcardObject } from './xBuildObjectUtils';
import type {
	Flashcard,
	FlashcardData,
	FlashcardSettings
} from '../data/interface-flashcard';
import type { IDBPDatabase } from 'idb';
import {
	createObjectStore,
	getAllValues,
	putBulkValues,
	deleteDB,
	putValue,
	deleteValue
} from '../data/indexedDBHelper';
import { set } from 'lodash';

export type FlashcardContextType = {
	createFlashcard: (card: FlashcardData) => Promise<void>;
	flashcards: Flashcard[] | undefined;
	getFlashcards: (uid?: string) => Promise<Flashcard[] | null>;
	getAllFlashcards: () => Promise<void>;
	flashcardSettings: FlashcardSettings | undefined;
	setFlashcardSettings: (settings: FlashcardSettings | undefined) => void;
	getFlashcardImageURL: (path: string) => Promise<string>;
	saveFlashcardStudyResults: (results: any) => Promise<boolean> | undefined;
	makeCreatedByPrepMed: (card: Flashcard) => void;
	deleteFlashcard: (id: string) => void;
	uploaderProgress: number;
	setUploaderProgress: (progress: number) => void;
	uploadFlashcardImage: (image: File, imageName: string) => Promise<void>;
	updateFlashcard: (card: Flashcard) => Promise<void>;
};
export const FlashcardContext = createContext<FlashcardContextType | null>(
	null
);
export const FlashcardContextProvider = ({
	children
}: {
	children: ReactNode;
}) => {
	const { currentUser } = useUserContext();
	const [flashcards, setFlashcards] = useState<Flashcard[]>();
	const [flashcardSettings, setFlashcardSettings] =
		useState<FlashcardSettings>();
	const [uploaderProgress, setUploaderProgress] = useState(0);

	// CONSTANTS
	// TODO: Move these to a constants file
	const TODAY = Date.now();
	const ONE_DAY_IN_SECONDS = 86400;
	const THIRTY_DAYS = ONE_DAY_IN_SECONDS * 30;
	// TODO: Re-enable webAppVersion when it's available
	// let version = webAppVersion.number * 100;
	const VERSION = 2 * 100;
	const FLASHCARDS_DB_NAME = 'idbpmv1'; // TODO: MOVE THIS TO QUESTIONS
	const IDB_TABLE = 'flashcards';
	const FLASHCARDS_LAST_PULL_DATE = 'prepmed-last-flashcards-pull-date';
	// #region IDBP HELPERS
	/**
	 * Opens an IndexedDB connection
	 */
	const openDBConnection = async (
		dbName = FLASHCARDS_DB_NAME,
		tableNamesArray = [IDB_TABLE]
	) => {
		let getDB: IDBPDatabase<unknown> | undefined;
		if (VERSION > 0) {
			getDB = await createObjectStore(dbName, VERSION, tableNamesArray);
		}
		return getDB;
	};
	// #endregion

	const getAllFlashcards = async (): Promise<void> => {
		localStorage.removeItem(FLASHCARDS_LAST_PULL_DATE);
		await deleteDB(FLASHCARDS_DB_NAME);

		// GET FLASHCARDS FROM FIRESTORE
		const data: Flashcard[] = await getFlashcardsCollection(true);
		setFlashcards(data);
		const thisDB = await openDBConnection();
		if (thisDB !== undefined && data.length > 0) {
			await putBulkValues(thisDB, IDB_TABLE, data);
		}
	};
	/**
	 * Main logic to get all questions
	 */
	const getFlashcards = async (uid?: string): Promise<Flashcard[] | null> => {
		let data: Flashcard[] = [];
		if (currentUser === null) return data;
		const localDate: string | null = localStorage.getItem(
			FLASHCARDS_LAST_PULL_DATE
		);
		const lastPullDate: number | null =
			localDate !== null ? Number(JSON.parse(localDate)) : null;

		// DEFINES WHEN TO CLEAR LOCAL STORAGE
		const clearLocalStorage = async () => {
			if (lastPullDate !== null) {
				if (currentUser?.isAdmin ?? false) {
					if ((TODAY - lastPullDate) / 1000 > ONE_DAY_IN_SECONDS) {
						localStorage.removeItem(FLASHCARDS_LAST_PULL_DATE);
						await deleteDB(FLASHCARDS_DB_NAME);
					}
				} else {
					if ((TODAY - lastPullDate) / 1000 > THIRTY_DAYS) {
						localStorage.removeItem(FLASHCARDS_LAST_PULL_DATE);
						await deleteDB(FLASHCARDS_DB_NAME);
					}
				}
			}
			return localStorage;
		};
		await clearLocalStorage();

		// GET THE QUESTIONS
		const thisDB = await openDBConnection();
		if (thisDB !== undefined) {
			data = await getAllValues(thisDB, IDB_TABLE);
		}
		const hasLocalData = data.length > 0;
		const isFreeTrialUser: boolean = currentUser.freeTrial?.isActive ?? false;
		if (!hasLocalData) {
			if (flashcards !== undefined && flashcards.length > 0) {
				// GET FLASHCARDS FROM USESTATE
				data = flashcards;
			} else {
				// GET FLASHCARDS FROM FIRESTORE
				try {
					data = await getFlashcardsCollection(false, uid);
				} catch (error) {
					console.error('Error getting flashcards from Firestore', error);
					// handleEventLog('error', error);
				}
			}
		}

		// FOR NOW THIS IS NOT NEEDED FOR FLASHCARDS
		// SET QUESTIONS TO STATE, REDUCER AND LOCAL STORAGE
		// if (isFreeTrialUser) {
		// data = filterFreeTrialQuestions(data);
		// }

		setFlashcards(data);
		if (thisDB !== undefined && data.length > 0) {
			await putBulkValues(thisDB, IDB_TABLE, data);
		} else {
			console.error('No local data found');
		}
		//  DATA BACKUP - HAS TO BE DONE MANUALLY
		// if (DATABASE_BACKUP) {
		// fbQ = JSON.stringify([...data]);

		// let day = Date.now();
		// let today = new Date(day);
		// questionsBackupDownload(
		// fbQ,
		// `QUESTIONS_BACKUP ${
		// today.getMonth() + 1
		// }-${today.getDate()}-${today.getFullYear()}.json`,
		// 'text/plain'
		// );
		// }

		return data;
	};

	const getFlashcardsCollection = async (
		all = false,
		uid?: string
	): Promise<Flashcard[]> => {
		if (currentUser === null) return [];
		const cards: Flashcard[] = [];
		const recordsRef = collection(db, IDB_TABLE);
		let q: Query<DocumentData>;
		if (all) {
			q = query(recordsRef, where('bank', '==', currentUser.examTarget));
		} else {
			q = query(
				recordsRef,
				where('bank', '==', currentUser.examTarget)
				// where('createdBy', '==', currentUser.uid),
				// where('createdBy', '==', 'PrepMedRD')
				// TODO: fIGURE OUT COMPOUND QUERIES
			);
		}
		// and (
		// where('bank', '==', currentUser.examTarget),
		// or(
		// where('createdBy', '==', currentUser.uid),
		// where('createdBy', '==', 'PrepMedRD')
		// )
		// )
		const querySnapshot = await getDocs(q);
		querySnapshot?.forEach((record) => {
			const f = buildFlashcardObject(record.id, record.data());

			if (f !== null) {
				if (
					(f.createdBy === currentUser.uid || f.createdBy === 'PrepMedRD') &&
					!all
				) {
					cards.push(f);
				}
			}
		});

		return cards;
	};

	const getFlashcardImageURL = async (path: string) => {
		let downloadURL = '';
		const storage = getStorage();
		const pathReference = ref(storage, `flashcard-images/${path}`);

		// let thisPath = await pathReference.getDownloadURL();
		// return thisPath;
		await getDownloadURL(pathReference)
			.then((url) => {
				// `url` is the download URL for 'images/stars.jpg'
				downloadURL = url;
				// // This can be downloaded directly:
				// const xhr = new XMLHttpRequest();
				// xhr.responseType = 'blob';
				// xhr.onload = (event) => {
				// const blob = xhr.response;
				// };
				// xhr.open('GET', url);
				// xhr.send();

				// // Or inserted into an <img> element
				// const img = document.getElementById('myimg');
				// img?.setAttribute('src', url);
			})
			.catch((error) => {
				// Handle any errors
				console.error(error);
				handleEventLog('error', error);
			});

		return downloadURL;
	};

	const saveFlashcardStudyResults = async (results: any): Promise<boolean> => {
		if (currentUser !== null) {
			const recordsCollection = 'flashcardRecords';
			try {
				const testRecordRef = await addDoc(collection(db, recordsCollection), {
					...results,
					userID: currentUser.uid,
					date: new Date(),
					examTargetYear: currentUser.examTargetYear,
					examTarget: currentUser.examTarget
				});
				return true;
			} catch (err) {
				console.error('Error saving flashcard record', err);
				handleEventLog('Error savinfg flashcard study record');
				return false;
			}
		} else {
			return false;
		}
	};

	const deleteFlashcard = (id: string) => {
		const isConfirm = window.confirm(
			'Are you sure you want to delete this flashcard?'
		);

		if (isConfirm) {
			deleteDoc(doc(db, 'flashcards', id))
				.then(() => {
					// TODO: CLEAR FLASHCARDS FROM LOCAL STORAGE idb
					// let lsFlashcards = JSON.parse(
					// localStorage.getItem('prepmed-flashcards')
					// );
					// localStorage.removeItem('prepmed-flashcards');
					// let newFlashcardSet = lsFlashcards.filter((f) => f.id !== id);
					// localStorage.setItem(
					// 'prepmed-flashcards',
					// JSON.stringify(newFlashcardSet)
					// );
				})
				.catch((err) => {
					console.log('Delete flashcard failed:', err);
				});
		}
	};

	const makeCreatedByPrepMed = (card: Flashcard) => {
		if (currentUser === null || currentUser.person === undefined) return;
		const userRef = doc(db, 'flashcards', card.id);
		updateDoc(userRef, {
			originalCreator: card.createdBy,
			createdBy: 'PrepMedRD',
			lastEditDate: new Date(),
			lastEditBy:
				currentUser.person.firstName + ' ' + currentUser.person.lastName
		})
			.then(() => {
				console.log('Document successfully updated!');
			})
			.catch((error) => {
				console.error('Error updating document: ', error);
			});
	};

	const uploadFlashcardImage = async (
		image: File,
		fileName: string
	): Promise<void> => {
		const storage = getStorage();
		const storageRef = ref(storage, 'flashcard-images/' + fileName);
		const task = uploadBytesResumable(storageRef, image);
		task.on(
			'state_changed',
			(snapshot) => {
				const progress =
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				// console.log('Upload is ' + progress + '% done');
				setUploaderProgress(progress);
			},
			(error) => {
				console.error(error);
			},
			() => {
				getDownloadURL(task.snapshot.ref)
					.then((downloadURL) => {
						console.log('File available at', downloadURL);
					})
					.catch((error) => {
						console.error(error);
					});
			}
		);
	};

	const createFlashcard = async (card: FlashcardData): Promise<void> => {
		const newCard = {
			authorFirstName: currentUser?.person?.firstName ?? '',
			authorLastName: currentUser?.person?.lastName ?? '',
			authorID: currentUser?.uid ?? '',
			originalCreator: `${currentUser?.person?.firstName ?? ''} ${
				currentUser?.person?.lastName ?? ''
			}`,
			bank: currentUser?.examTarget ?? 'enurm',
			back: card.back,
			categories: card.categories,
			front: card.front,
			imageFileName: card.imageFileName,
			createTS: new Date(),
			createdBy: currentUser?.uid ?? ''
		};

		const recordsCollection = 'flashcards';
		await addDoc(collection(db, recordsCollection), newCard)
			.then(async (docRef) => {
				// console.log('Document written with ID: ', docRef.id);
				const thisDB = await openDBConnection();
				if (thisDB !== undefined) {
					await putValue(thisDB, IDB_TABLE, {
						...newCard,
						id: docRef.id
					});
					setFlashcards([...(flashcards ?? []), { ...newCard, id: docRef.id }]);
				}
			})
			.catch((error) => {
				console.error('Error adding document: ', error);
			});
	};

	const updateFlashcard = async (card: Flashcard): Promise<void> => {
		const userRef = doc(db, 'flashcards', card.id);
		await updateDoc(userRef, {
			front: card.front,
			back: card.back,
			image: card.imageFileName,
			categories: card.categories,
			lastEditDate: new Date(),
			lastEditBy: currentUser?.uid ?? ''
		});

		const newFlashcards: Flashcard[] | undefined = flashcards?.filter((f) => {
			return f.id !== card.id;
		});
		const data = [...(newFlashcards ?? []), card];
		setFlashcards(data);

		const thisDB = await openDBConnection();
		if (thisDB !== undefined && data.length > 0) {
			await deleteValue(thisDB, FLASHCARDS_DB_NAME, card.id);
			await putValue(thisDB, IDB_TABLE, card);
		}
	};

	return (
		<FlashcardContext.Provider
			value={{
				createFlashcard,
				flashcards,
				getAllFlashcards,
				getFlashcards,
				flashcardSettings,
				setFlashcardSettings,
				getFlashcardImageURL,
				saveFlashcardStudyResults,
				makeCreatedByPrepMed,
				deleteFlashcard,
				uploaderProgress,
				setUploaderProgress,
				uploadFlashcardImage,
				updateFlashcard
			}}>
			{children}
		</FlashcardContext.Provider>
	);
};
export const useFlashcardContext = () => {
	const context = useContext(FlashcardContext);
	if (context === undefined || context === null) {
		throw new Error(
			'useAdminContext must be used within a AdminContextProvider'
		);
	}
	return context;
};
export default FlashcardContextProvider;
