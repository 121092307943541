const SelectFlashcardsDescription = () => {
	return (
		<div className='w-full lg:w-6/12 xl:w-7/12'>
			<p>
				Las flashcards (tarjetas o fichas didácticas) son una herramienta de
				estudio enfocada en ayudar con la memorización de conceptos.
			</p>
			<p>
				<strong>¿Cómo funcionan?</strong>
			</p>
			<p>
				Cada flashcard tiene 2 lados, en uno de los lados de la ficha verás
				&quot;la pregunta&quot;. Mientras que, en la otra parte (la que no ves),
				verás &quot;la respuesta&quot;.
			</p>
			<p>
				El concepto se basa en que cuando veas &quot;la pregunta&quot; debes
				responderla (en tu mente o en voz alta), luego darle a continuar para
				ver la respuesta y por úlmito, marcar si acertaste con tu respuesta o
				no.
			</p>
		</div>
	);
};
export default SelectFlashcardsDescription;
