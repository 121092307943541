import { Link } from 'react-router-dom';
import Card from '../../../components/ui/Card';
import Headline from '../../../components/ui/Headline';
import useHideElementOnMount from '../../../hooks/useHideElementOnMount';
import MainTag from '../../../layout/Main';
import routes from '../../../config/routes';

const CheckoutFail = () => {
	useHideElementOnMount('header');
	useHideElementOnMount('footer');

	return (
		<MainTag>
			<div className='flex flex-col items-center mb-4 mt-10  mx-auto'>
				<div className='container'>
					<Card>
						<div className='card-content'>
							<Headline
								classes='text-red-500 mb-4'
								title='¡Atención!'
								type='h2'>
								<i className='material-icons text-4xl mr-2'>cancel</i>
							</Headline>
							<p>
								El pago fue cancelado, declinado o hubo un problema con la
								transacción. Por favor intente mas tarde o intenta completar tu
								pago con Paypal.
							</p>
							<div className='flex justify-end'>
								<Link
									to={routes.APP.PATH_TO.MEMBERSHIP.CHECKOUT}
									className='btn bg-gray-400 hover:bg-gray-500'>
									Completa tu Pago
								</Link>
							</div>
						</div>
					</Card>
				</div>
			</div>
		</MainTag>
	);
};
export default CheckoutFail;
