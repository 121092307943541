import { Link } from 'react-router-dom';
// import { customDateFormatInSpanish } from '../../../../utils/helpers';
import type { TestRecord } from '../../../../data/interface-question';
import routes from '../../../../config/routes';
import {
	calcTestGrade,
	convertFirebaseTSToDate,
	convertFirebaseTSToTime,
	customDateFormatInSpanish
} from '../../../../utils/helpers';
import useQuestionBankContext from '../../../../api/QuestionBankContext';

interface TestRecordTRProps {
	record: TestRecord;
}

// TODO: Fix record's date format {record.date &&
// customDateFormatInSpanish(new Date(record.date.seconds * 1000))
//  new Date(record.date.seconds * 1000).toLocaleTimeString()

const TestRecordTR = ({ record }: TestRecordTRProps) => {
	const hiddenClass = 'hidden px-3 py-4 text-sm text-gray-500';
	const { questions } = useQuestionBankContext();

	const calculateGrade = (): string => {
		let correctAnswers = 0;
		record.questions.forEach((r) => {
			const q = questions?.find((question) => question.id === r.id);
			if (q !== undefined && r.selectedAnswer === q.answer) {
				correctAnswers += 1;
			}
		});
		return calcTestGrade(correctAnswers, record.questions.length);
	};

	return (
		<tr className='even:bg-brand-50'>
			{/* <td>
				{/*
			</td>
			<td style={{ textAlign: 'center' }}>
				{record.date !== null && new Date(record.date).toLocaleTimeString()}
			</td>
			<td style={{ textAlign: 'center' }}>
				{record.testType.charAt(0).toUpperCase() + record.testType.slice(1)}
			</td>
			<td style={{ textAlign: 'center' }}>{record.testLength}</td>
			<td style={{ textAlign: 'center' }}>{record.grade}</td>
			<td style={{ textAlign: 'center' }}>
				{
					<Link
						to={routes.APP.QUESTIONS.TEST_REVIEW}
						state={record}
						className='txt-brand-accent'>
						Ver más...
					</Link>
				}
			</td> */}
			<td className='w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0'>
				<span className='ml-2'>
					{customDateFormatInSpanish(convertFirebaseTSToDate(record.date))}
				</span>
				<dl className='font-normal lg:hidden'>
					<dt className='sr-only'>Hora del Examen</dt>
					<dd className='mt-1 truncate text-gray-700'>
						{convertFirebaseTSToTime(record.date)}
					</dd>
					<dt className='sr-only sm:hidden'>Modo de Examen</dt>
					<dd className='mt-1 truncate text-gray-500 sm:hidden'>
						Modo{' '}
						{record.testType.charAt(0).toUpperCase() + record.testType.slice(1)}
					</dd>
					<dt className='sr-only sm:hidden'>Cantidad de Preguntas</dt>
					<dd className='mt-1 truncate text-gray-500 sm:hidden'>
						{record.testLength} Preguntas
					</dd>
				</dl>
			</td>
			<td className={`${hiddenClass} lg:table-cell`}>
				{convertFirebaseTSToTime(record.date)}
			</td>
			<td className={`${hiddenClass} sm:table-cell`}>
				{record.testType.charAt(0).toUpperCase() + record.testType.slice(1)}
			</td>
			<td className={`${hiddenClass} sm:table-cell`}>{record.testLength}</td>
			<td className='px-3 py-4 text-sm text-gray-500'>
				{/* {record.grade} */}
				{calculateGrade()}%
			</td>
			<td className='py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
				{/* // TODO: Fix this link once the TestReview component is created */}
				<Link
					to={routes.APP.PATH_TO.TEST_REVIEW}
					state={{ testRecord: record }}
					className='text-brand-600 hover:text-brand-800 hover:underline mr-4'>
					<span className='sr-only'> detalles</span>
					Detalles
				</Link>
			</td>
		</tr>
	);
};
export default TestRecordTR;
