import { useEffect, useState } from 'react';
import useQuestionBankContext from '../../../api/QuestionBankContext';
import { useLoadingContext } from '../../../context/LoadingContext';
import { handleEventLog } from '../../../gcp/config';

interface ImageDisplayProps {
	imagePathName: string;
}
const ImageDisplay = ({ imagePathName }: ImageDisplayProps) => {
	const { getQuestionImageURL } = useQuestionBankContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const [imageURL, setImageURL] = useState<string | undefined>();

	useEffect(() => {
		if (imagePathName === undefined || imagePathName === '') return;
		setLoadingMessage('Cargando imagen...');
		setLoading(true);
		getQuestionImageURL(imagePathName)
			.then((url) => {
				setImageURL(url);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				handleEventLog('IMAGE_DISPLAY_ERROR', { imagePathName, err });
				console.error('IMAGE_DISPLAY_ERROR', { imagePathName, err });
			});

		// CLEANUP
		return () => {
			setImageURL(undefined);
		};
	}, [imagePathName]);

	if (imageURL === undefined) return null;
	return (
		<div className='flex justify-center items-center md:m-10'>
			{/* // TODO: Enable below once the image is available through firestore */}
			<img className='responsive-img rounded' src={imageURL} alt={imageURL} />
		</div>
	);
};
export default ImageDisplay;
