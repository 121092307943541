import type {
	ProductData,
	QuestionBankID
} from '../data/interface-question-bank';
import type {
	TestRecord,
	Question,
	Category
} from '../data/interface-question';
import type { User } from '../data/interface-user';
import { handleEventLog } from '../gcp/config';
import type { Flashcard } from '../data/interface-flashcard';
import { preferredDateFormat } from '../utils/helpers';

export const buildFlashcardObject = (
	id: string,
	card: any
): Flashcard | null => {
	let finalFlashcard: Flashcard | null = null;

	try {
		finalFlashcard = {
			...card,
			back: card.back,
			bank: card.bank,
			categories: card.categories,
			createdBy: card.createdBy,
			createTS: card.createTS,
			front: card.front,
			id,
			imageFileName: card.imageFileName,
			lastEditBy: card.lastEditBy,
			lastEditDate: card.lastEditDate,
			originalCreator: card.originalCreator
		};
	} catch (error) {
		console.error('Error constructing flashcard object: ', error, card);
		handleEventLog('Error constructing flashcard object', card);
	}
	return finalFlashcard;
};

export const buildUserObject = (id: string, user: any): User | null => {
	let finalUser: User | null = null;

	try {
		finalUser = {
			...user,
			uid: id,
			email: user.email,
			password: user.password,
			signUpDate: user.signUpDate,
			university: user.university,
			gradYear: user.gradYear
		};
	} catch (error) {
		console.error('Error constructing user object: ', error, user);
		handleEventLog('Error constructing user object', user);
	}
	return finalUser;
};

export const buildProductDataObject = (
	id: QuestionBankID,
	bank: any
): ProductData | null => {
	let bankObj: ProductData | null = null;

	try {
		bankObj = {
			...bank,
			id,
			price: bank.price,
			fullPrice: bank.fullPrice,
			isSalesSeasonOpen: bank.isSalesSeasonOpen,
			nextLaunchDate: bank.nextLaunchDate,
			nextExamTargetYear: bank.nextExamTargetYear,
			showDiscount: bank.showDiscount,
			listaDeEspera: bank.listaDeEspera,
			officialExamName: bank.officialExamName,
			stripePriceID: bank.stripePriceID
		};
	} catch (error) {
		// TODO: Handle & log error
	}
	return bankObj;
};

export const buildCategoryObject = (
	id: string, // TODO: Change this to type "CategoryName"
	category: any
): Category | null => {
	let finalCategory: Category | null = null;

	try {
		finalCategory = {
			...category,
			id,
			name: category.name,
			subCategoryList:
				category.sub_categories === undefined
					? category.subCategoryList
					: category.sub_categories,
			totalQuestions: category.totalQuestions,
			totalAnsweredCorrectly: category.totalAnsweredCorrectly,
			totalAnsweredInQuestionRecords: category.totalAnsweredInQuestionRecords
			// TODO: Change this to JUST subCategoryList after modifying all categories in firestore
		};
	} catch (error) {
		// TODO: Handle error
	}
	return finalCategory;
};

/**
 * Builds a question object from Firestore data
 */
export const buildQuestionObject = (
	id: string,
	question: any
): Question | null => {
	try {
		const finalQuestion: Question = {
			...question,
			answer: question.answer,
			authorFirstName: question.authorFirstName,
			authorLastName: question.authorFirstName,
			authorID: question.authorFirstName,
			bank: question.bank,
			categoryID: question.categoryID,
			createdOn: question.createdOn,
			source: question.source,
			explanation: question.explanation,
			fuente: question.fuente,
			id,
			imageFileName: question.imageFileName,
			option1: question.option1,
			option2: question.option2,
			option3: question.option3,
			question: question.question,
			tags: question.tags,
			subCategoryList:
				question.subCategoryList !== undefined
					? question.subCategoryList
					: question.sub_category // TODO: Ihave to change this to subCategoryList
		};
		return finalQuestion;
	} catch (error) {
		// TODO: Log error with question ID
		// TODO: Replace console.error with a proper error handling mechanism
		console.error('Error constructing question: ', error, question);
	}

	return null;
};

/**
 * Builds a test record object from Firestore data
 */
export const buildTestRecordObject = (
	id: string | undefined,
	record: any
): TestRecord | null => {
	let finalRecord: TestRecord | null = null;
	try {
		finalRecord = {
			...record,
			date: record.date,
			questions: record.questions,
			testDuration: record.testDuration,
			testLabel: record.testLabel,
			testLength: record.testLength,
			testMode: record.testMode,
			testTopics: record.testTopics,
			testType: record.testType,
			timeElapsedInSeconds: record.timeElapsedInSeconds,
			useTimer: record.useTimer,
			useTutorMode: record.useTutorMode,
			userID: record.userID
		};

		if (finalRecord === null) return null;
		if (id !== undefined) finalRecord.id = id;
	} catch (error) {
		// TODO: Log error with user ID and record ID
		// TODO: Replace console.error with a proper error handling mechanism
		console.error('Error constructing question: ', error, record);
	}
	return finalRecord;
};
