import { useEffect, useState } from 'react';
import { useLoadingContext } from '../../../../context/LoadingContext';
import type { Flashcard } from '../../../../data/interface-flashcard';
import parseHTML from 'html-react-parser';
import { useFlashcardContext } from '../../../../api/FlashcardsContext';

interface CardContentPropTypes {
	flashcard: Flashcard;
	showBack: boolean;
}

const CardContent = ({ flashcard, showBack }: CardContentPropTypes) => {
	const { getFlashcardImageURL } = useFlashcardContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const [imageURL, setImageURL] = useState<string | undefined>();

	const checkForImg = async () => {
		if (
			flashcard.imageFileName !== undefined &&
			flashcard.imageFileName !== null &&
			flashcard.imageFileName !== ''
		) {
			setLoadingMessage('Cargando imagen...');
			setLoading(true);
			const url = await getFlashcardImageURL(flashcard.imageFileName);
			setImageURL(url);
		} else {
			return false;
		}
	};

	useEffect(() => {
		checkForImg()
			.then(() => {
				// console.log('Image URL:', imageURL);
			})
			.catch((err) => console.error(err));
		return () => {
			setImageURL(undefined);
		};
	}, [flashcard]);

	useEffect(() => {
		setLoading(false);
	}, [imageURL]);

	return (
		<div>
			{/* // TODO: Enhancement - Make this img width: 50% and make if clickable so it expands to full screen */}
			{imageURL !== undefined && (
				<div className='flex justify-center mt-2'>
					<img className='responsive-img' src={imageURL} alt={imageURL} />
				</div>
			)}

			{!showBack ? (
				<div className='mb-0'>{parseHTML(flashcard.front)}</div>
			) : (
				<div className='mb-0'>{parseHTML(flashcard.back)}</div>
			)}
		</div>
	);
};
export default CardContent;
