import { useEffect, useState } from 'react';
import routes from '../config/routes';
import Nav from './navbar/Nav';
import type { Navigation } from '../data/interface-navigation';
import MobileNav from './navbar/MobileNav';
import { useLocation } from 'react-router-dom';

// const publicNavLinks: Navigation = [
const navigation: Navigation = [
	{
		name: 'Programas',
		href: routes.PUBLIC.COMING_SOON
	},
	{
		name: 'Sobre Nosotros',
		href: routes.PUBLIC.ABOUT_US
	},

	{
		name: 'Precios',
		href: routes.PUBLIC.COMING_SOON
	},
	{
		name: 'Crea tu Cuenta',
		href: routes.AUTHENTICATION.SIGNUP,
		isSpecial: true
	}
];

const Header = () => {
	const location = useLocation();
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [showHeader, setShowHeader] = useState(true);
	const [isPrivateRoute, setIsPrivateRoute] = useState(false);

	useEffect(() => {
		if (
			location.pathname.includes('/app') ||
			location.pathname === routes.APP.PATH_TO.TEST_MODE
		) {
			setIsPrivateRoute(true);
			setShowHeader(false);
		} else {
			setShowHeader(true);
		}
	}, [location]);

	if (!showHeader) return null;

	return (
		<header className='absolute inset-x-0 top-0 z-50'>
			<Nav
				navigation={navigation}
				toggleMenu={(x: boolean) => setMobileMenuOpen(x)}
				isPrivateRoute={isPrivateRoute}
			/>

			<MobileNav
				navigation={navigation}
				isOpen={mobileMenuOpen}
				isPrivateRoute={isPrivateRoute}
				toggleMenu={(x: boolean) => setMobileMenuOpen(x)}
			/>
		</header>
	);
};

export default Header;
