interface CountCardProps {
	number: number;
	title: string;
}

const CountCard = ({ number, title }: CountCardProps) => {
	return (
		<div
			className='overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6'
			style={{ maxWidth: '120px' }}>
			<dt className='truncate text-sm font-medium text-gray-500'>{title}</dt>
			<dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
				{number}
			</dd>
		</div>
	);
};
export default CountCard;
