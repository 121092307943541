import MaterialIcons from './icons/MaterialIcons';
interface TooltipTypes {
	message?: string;
}
const Tooltip = ({ message }: TooltipTypes) => {
	return (
		<span className='group flex relative cursor-help'>
			<MaterialIcons
				icon='help'
				classes='text-gray-400 group-hover:text-gray-600'
			/>
			<span
				className='hidden group-hover:block opacity-0 group-hover:opacity-100 transition-opacity bg-gray-600 p-3 text-sm text-gray-100 rounded-md absolute left-1/2 top-0 -translate-y-full -translate-x-1/8 -mt-1 ml-2 shadow-xl'
				style={{ width: '300px' }}>
				{message}
			</span>
		</span>
	);
};
export default Tooltip;
