import isMobile from '../../utils/isMobile';

interface CardProps {
	children?: React.ReactNode; // best, accepts everything React can render
	classes?: string;
}

const Card = ({
	children,
	classes = 'bg-white shadow-lg rounded px-8 py-8 pb-8 mb-4 mt-12 min-w-3 w-auto md:w-3/4 mx-auto'
}: CardProps) => {
	return <div className={classes}>{children}</div>;
};
export default Card;
