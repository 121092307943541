import Headline from './Headline';
interface SectionProps {
	btnTitle?: string;
	btnAction?: () => void;
	children: React.ReactNode;
	subTitle?: string;
	title: string;
}

const Section = ({
	btnTitle,
	btnAction,
	children,
	subTitle,
	title
}: SectionProps) => {
	return (
		<section>
			<div className='flex flex-col sm:flex-row justify-between items-baseline mb-4'>
				<div className='sm:flex-auto'>
					{/* <h1 className='text-base font-semibold leading-6 text-gray-900'>
								Users
							</h1>
							<p className='mt-2 text-sm text-gray-700'>
								A list of all the users in your account including their name,
								title, email and role.
							</p> */}
					<Headline title={title} type='h2' />
					{subTitle !== undefined && (
						<p className='text-gray-500 mt-4'>{subTitle}</p>
					)}
				</div>

				{btnTitle !== undefined && btnAction !== undefined && (
					<div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
						<button
							type='button'
							className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'>
							{btnTitle}
						</button>
					</div>
				)}
			</div>
			<div>{children}</div>
		</section>
	);
};
export default Section;
