import { useEffect, useMemo, useState } from 'react';
import useQuestionBankContext from '../../../../api/QuestionBankContext';
import type { TestRecord } from '../../../../data/interface-question';
import Pagination from '../../../../components/ui/Pagination';
import TestRecordTR from './TestRecordTR';
import Section from '../../../../components/ui/Section';

// const PageSize = 10;
interface TestRecordsProps {
	paginationRecordsPerPage?: number;
}
const TestRecords = ({
	paginationRecordsPerPage: paginationSize = 10
}: TestRecordsProps) => {
	const { testRecords } = useQuestionBankContext();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalRecords, setTotalRecords] = useState<TestRecord[] | null>(null);

	// TODO: Pull test records directly when needed
	// const getLatestRecords = () => {
	// getUserTestRecordsFromDB();
	// };

	useEffect(() => {
		testRecords !== null && setTotalRecords(testRecords);
	}, [testRecords]);

	const currentTableData = useMemo(() => {
		const data: TestRecord[] | null = testRecords;
		setTotalRecords(testRecords);
		const firstPageIndex = (currentPage - 1) * paginationSize;
		const lastPageIndex = firstPageIndex + paginationSize;
		return data?.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, testRecords]);

	if (
		testRecords === null ||
		totalRecords === null ||
		currentTableData === undefined
	) {
		return null;
	}

	return (
		<Section
			title='Historial de Exámenes'
			subTitle={`${
				testRecords.length > 0 ? 'Consulta' : 'Aquí podrás ver'
			} un resumen detallado de todos tus exámenes.`}>
			{/* {totalRecords.length === 0 && (
				// TODO: Re-enable once you've reviewd the logic. testRecord should be undefined if there are no actual records but currently it's an empty array.
				<button
					className='btn bg-brand-500 flex'
					// onClick={getLatestRecords}
				>
					<i className='material-icons mr-2'>cached</i>Actualizar
				</button>
			)} */}
			<div className='flex justify-end'>
				{totalRecords.length > 0 && (
					<Pagination
						className='pagination-bar'
						siblingCount={1}
						currentPage={currentPage}
						totalCount={totalRecords?.length ?? 0}
						pageSize={paginationSize}
						onPageChange={(page: number) => setCurrentPage(page)}
					/>
				)}
			</div>
			{totalRecords.length > 0 && (
				// <table id='test-records'>
				// <thead>
				// <tr>
				// <td style={{ width: '20%' }}>Date</td>
				// <td style={{ width: '20%', textAlign: 'center' }}>Hora</td>
				// <td style={{ width: '20%', textAlign: 'center' }}>Modo</td>
				// <td style={{ width: '15%', textAlign: 'center' }}>Preguntas</td>
				// <td style={{ width: '10%', textAlign: 'center' }}>Puntuación</td>
				// <td style={{ width: '15%', textAlign: 'center' }}>Detalles</td>
				// </tr>
				// </thead>
				<table className='min-w-full divide-y divide-gray-300'>
					<thead>
						<tr>
							<th
								scope='col'
								className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold uppercase text-gray-500 sm:pl-0'>
								Fecha
							</th>
							<th
								scope='col'
								className='hidden px-3 py-3.5 text-left text-sm font-semibold uppercase text-gray-500 lg:table-cell'>
								Hora
							</th>
							<th
								scope='col'
								className='hidden px-3 py-3.5 text-left text-sm font-semibold uppercase text-gray-500 sm:table-cell'>
								Modo
							</th>
							<th
								scope='col'
								className='hidden px-3 py-3.5 text-left text-sm font-semibold uppercase text-gray-500 sm:table-cell'>
								Preguntas
							</th>
							<th
								scope='col'
								className='px-3 py-3.5 text-left text-sm font-semibold uppercase text-gray-500'>
								Puntuación
							</th>
							<th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-0'>
								<span className='sr-only'>Detalles</span>
							</th>
						</tr>
					</thead>
					<tbody className='divide-y divide-gray-200 bg-white'>
						{currentTableData
							.sort((a, b) => {
								return Number(b.date) - Number(a.date);
							})
							.map((record) => {
								return <TestRecordTR record={record} key={record.id} />;
							})}
					</tbody>
				</table>
			)}
		</Section>
	);
};
export default TestRecords;
