import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../../config/routes';

// COMPONENTS
import EmailInput from '../../../../components/forms/EmailInput';
import ErrorMessage from '../../../../components/forms/ErrorMessage';
import FormLabel from '../../../../components/forms/FormLabel';
import TextInput from '../../../../components/forms/TextInput';

// HOOKS
import { useLoadingContext } from '../../../../context/LoadingContext';
import Button from '../../../../components/ui/Button';
import PasswordInput from '../../../../components/forms/PasswordInput';
import type {
	CredentialSetupStep,
	SignupStepsIDs
} from '../../../../data/interface-signup-steps';
import Headline from '../../../../components/ui/Headline';
import debug from '../../../../utils/useDebugger';
import { useAuthContext } from '../../../../api/AuthContext';
import FormContainer from '../../../../components/forms/FormContainer';

interface SetupCredentialsProps {
	nextStep: (step: SignupStepsIDs) => void;
}

const SetupCredentials = ({ nextStep }: SetupCredentialsProps) => {
	const { signUpNewUser } = useAuthContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const [step, setStep] = useState<CredentialSetupStep>('get-email');
	// EMAIL
	const [email, setEmail] = useState<string>('');
	const [isValidEmail, setEmailValidity] = useState<boolean>(false);

	// EMAIL CONFIRMATION
	const [emailConfirmation, setEmailConfirmation] = useState<string>('');
	const [isMatchingEmail, setIsMatchingEmail] = useState<boolean>(false);

	// PASSWORD
	const [password, setPassword] = useState<string>('');
	const [isValidPassword, setPasswordValidity] = useState<boolean>(false);

	// PASSWORD CONFIRMATION
	const [passwordConf, setPasswordConf] = useState<string>('');
	const [isMatchingPassword, setIsMatchingPassword] = useState<boolean>(false);

	// ERROR
	const [error, setError] = useState<string>('');

	useEffect(() => {
		if (email.length > 0 && email === emailConfirmation) {
			setIsMatchingEmail(true);
		} else {
			setIsMatchingEmail(false);
		}
	}, [email, emailConfirmation]);

	useEffect(() => {
		if (password.length > 0 && password === passwordConf) {
			setIsMatchingPassword(true);
		} else {
			setIsMatchingPassword(false);
		}
	}, [password, passwordConf]);

	const handleSubmit = (
		e:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.FormEvent<HTMLFormElement>
	) => {
		e.preventDefault();
		if (isMatchingEmail && isMatchingPassword) {
			handleSignup()
				.then(() => setLoading(false))
				.catch((err) => console.error('Error creating user: ', err));
		}
	};

	const handleSignup = async () => {
		const result = await signUpNewUser(email, password);
		if (result === 'user-created') {
			nextStep('cuenta-creada');
		} else {
			setError(result);
			setLoading(false);
			console.error('Error creating user: ', result);
		}
	};

	return (
		<>
			<FormContainer handleSubmit={(e) => handleSubmit(e)}>
				<Headline
					classes='text-brand-700 mb-8'
					isCentered={true}
					title='Crea tu Cuenta'
					type='h6'
				/>

				{error === '' ? (
					<>
						{/* EMAIL */}
						{step === 'get-email' && (
							<div>
								<FormLabel label='Correo Electrónico' />
								<EmailInput
									id='email'
									placeholder='¿Cuál es tu correo electrónico de preferencia?'
									handleChange={(e: string) => setEmail(e)}
									handleValidity={(v: boolean) => setEmailValidity(v)}
								/>

								<p className='py-2 mb-4'>
									Es recomendado que el correo electrónico con el que elijas
									crear tu cuenta sea tu correo personal, no del trabajo o de la
									universidad.
								</p>
								{email.length > 0 && email.includes('@') && (
									<ErrorMessage
										error='El correo ingresado no es válido...'
										showError={!isValidEmail}
									/>
								)}
								{isValidEmail && step === 'get-email' && (
									<div className='flex items-center justify-end'>
										<Button
											title='Continuar >'
											bgColor='bg-brand-500'
											hoverBgColor='hover:bg-brand-550'
											handleClick={() => setStep('confirm-email')}
										/>
									</div>
								)}
							</div>
						)}

						{/* EMAIL CONFIRMATION */}
						{step === 'confirm-email' && (
							<div>
								<FormLabel label='Confirma tu Correo Electrónico' />
								<EmailInput
									id='email-confirmation'
									placeholder='Confirma tu correo de preferencia...'
									handleChange={(e: string) => setEmailConfirmation(e)}
								/>

								<p className='py-2 mb-4'>
									Es necesario validar que ingresaste el correo correcto. Por
									favor, ingresa tu correo una vez más.
								</p>

								{emailConfirmation.length > 0 &&
									emailConfirmation.includes('@') &&
									emailConfirmation.includes('.') && (
										<ErrorMessage
											error='Los correos no son iguales...'
											showError={!isMatchingEmail}
										/>
									)}
								{isMatchingEmail && step === 'confirm-email' && (
									<div className='flex items-center justify-end'>
										<Button
											title='Continuar >'
											bgColor='bg-brand-500'
											hoverBgColor='hover:bg-brand-550'
											handleClick={() => setStep('get-password')}
										/>
									</div>
								)}
							</div>
						)}

						{/* PASSWORD */}
						{step === 'get-password' && (
							<div>
								<FormLabel label='Elige tu Contraseña' />
								<PasswordInput
									id='password'
									placeholder='¿Cuál es tu contraseña deseada?'
									handleChange={(v: string) => setPassword(v)}
									handleValidity={(v: boolean) => setPasswordValidity(v)}
								/>

								<p className='py-2 mb-4'>
									La contraseña debe tener al menos 1 letra mayúscula, 1
									minúscula, 1 número y un mínimo de 6 caracteres.
								</p>

								{password.length > 6 && (
									<ErrorMessage
										error='La contraseña ingresada es inválida...'
										showError={!isValidPassword}
									/>
								)}
								{isValidPassword && step === 'get-password' && (
									<div className='flex items-center justify-end'>
										<Button
											title='Continuar >'
											bgColor='bg-brand-500'
											hoverBgColor='hover:bg-brand-550'
											handleClick={() => setStep('confirm-password')}
										/>
									</div>
								)}
							</div>
						)}

						{/* CONFIRM PASSWORD */}
						{step === 'confirm-password' && (
							<div>
								<FormLabel label='Confirma tu Contraseña' />
								<PasswordInput
									id='password-confirmation'
									placeholder='Confirma tu contraseña deseada...'
									handleChange={(v: string) => setPasswordConf(v)}
								/>

								<p className='py-2 mb-4'>
									Es necesario validar que ingresaste la contraseña correcta.
									Por favor, ingresa tu contraseña una vez más.
								</p>

								{passwordConf.length >= password.length && (
									<ErrorMessage
										error='Las contraseñas no son iguales...'
										showError={!isMatchingPassword}
									/>
								)}

								{passwordConf.length >= password.length &&
									!isMatchingPassword && (
										<div className='flex items-center justify-end'>
											<Button
												title='< Volver Atrás'
												bgColor='bg-gray-400'
												hoverBgColor='hover:bg-gray-500'
												handleClick={() => {
													setPasswordConf('');
													setStep('get-password');
												}}
											/>
										</div>
									)}

								{isMatchingPassword && (
									<div className='flex items-center justify-end'>
										<Button
											title='Continuar >'
											bgColor='bg-brand-500'
											hoverBgColor='hover:bg-brand-550'
											handleClick={() => setStep('createAccount')}
										/>
									</div>
								)}
							</div>
						)}

						{/* CREATE ACCOUNT */}
						{step === 'createAccount' && (
							<div className='my-8 px-2'>
								<p className='mb-2 text-xs'>
									Al presionar &quot;Crea mi Cuenta&rdquo; estás aceptando
									nuestros{' '}
									<Link
										to={routes.PUBLIC.TOS_PAGE}
										target='_blank'
										className='normal-links'>
										Términos de Servicio
									</Link>
									, y nuestras{' '}
									<Link
										to={routes.PUBLIC.PRIVACY_POLICY}
										target='_blank'
										className='normal-links'>
										Políticas de Privacidad
									</Link>
									.
								</p>
								<p className='mb-2 text-xs'>
									Tu información no será compartida, ni vendida. ¡Gracias por
									prepararte con nosotros!
								</p>

								<div className='flex items-center justify-end mt-4'>
									<Button
										title='Crea tu Cuenta'
										bgColor='bg-brand-500'
										hoverBgColor='hover:bg-brand-550'
										handleClick={(e) => handleSubmit(e)}
										type='submit'
									/>
								</div>
							</div>
						)}
					</>
				) : (
					<>
						<ErrorMessage error={error} showError={error !== ''} />
						<div className='flex items-center justify-end'>
							<Button
								title='Vuelve a Empezar'
								bgColor='bg-brand-500'
								hoverBgColor='hover:bg-brand-550'
								handleClick={() => window.location.reload()}
								type='submit'
							/>
						</div>
					</>
				)}
			</FormContainer>
			<p className='text-center text-gray-500 text-xs'>
				Si ya tienes tu cuenta ve a{' '}
				<Link to={routes.AUTHENTICATION.LOGIN}>iniciar sesión</Link>.
			</p>
			<p className='text-center text-gray-500'>
				<Link to={routes.HOME} className='mb-10 text-xs'>
					www.prepmed.org
				</Link>
			</p>
		</>
	);
};
export default SetupCredentials;
