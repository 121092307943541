import MaterialIcons from '../ui/icons/MaterialIcons';

interface FormLabelTypes {
	icon?: string | null;
	iconFam?: string;
	iconPosition?: 'left' | 'right';
	label: string;
	children?: JSX.Element;
	isRequired?: boolean;
}
/**
 * Takes in label and an icon (if required) as CHildren (JSX.Element)
 */
const FormLabel = ({
	icon = null,
	iconPosition = 'left',
	iconFam = 'material-icons',
	children,
	isRequired = false,
	label
}: FormLabelTypes) => {
	if (icon !== null && iconFam === 'material-icons') {
		return (
			<div className='flex '>
				{children}
				<label className='block w-full text-gray-500 text-sm mb-1'>
					<div className='flex items-center'>
						{icon !== null && iconPosition === 'left' && (
							<>
								<MaterialIcons icon={icon} classes='mr-2' />
								{label}
							</>
						)}
						{icon !== null && iconPosition === 'right' && (
							<>
								{label}
								<MaterialIcons icon={icon} classes='ml-2' />
							</>
						)}
						{isRequired && (
							<sub
								className='text-gray-400'
								// hidden={textInputRef.current?.validity?.valid}
							>
								(requerido)
							</sub>
						)}
					</div>
				</label>
			</div>
		);
	}
	return (
		<div className='flex '>
			{children}
			<label className='block w-full text-gray-500 text-sm mb-1'>
				<div className='flex justify-between items-center'>
					{label}
					{isRequired && (
						<sub
							className='text-gray-400'
							// hidden={textInputRef.current?.validity?.valid}
						>
							(requerido)
						</sub>
					)}
				</div>
			</label>
		</div>
	);
};
export default FormLabel;
