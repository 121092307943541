import { useState } from 'react';
import FormContainer from '../../../../components/forms/FormContainer';
import SelectElement from '../../../../components/forms/SelectElement';
import TextInputForm from '../../../../components/forms/TextInputForm';
import Headline from '../../../../components/ui/Headline';
import { useLoadingContext } from '../../../../context/LoadingContext';
import type { SignupStepsIDs } from '../../../../data/interface-signup-steps';
import { universities } from '../../../../data/universities';
import useUserContext from '../../../../api/UserContext';
import Button from '../../../../components/ui/Button';
import ErrorMessage from '../../../../components/forms/ErrorMessage';

interface InformacionAdicionalProps {
	nextStep: (step: SignupStepsIDs) => void;
}

const InformacionAdicional = ({ nextStep }: InformacionAdicionalProps) => {
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { updateEducationInfo } = useUserContext();
	const [universidad, setUniversidad] = useState<string | null>(null);
	const [universidadTyped, setUniversidadTyped] = useState<string>();
	const [gradYear, setGradYear] = useState<string | null>(null);
	const [isValidGradYear, setIsValidGradYear] = useState(false);
	// ERROR
	const [error, setError] = useState<string>('');

	const handleSignup = async () => {
		if (universidad != null && gradYear != null && isValidGradYear) {
			const result = await updateEducationInfo({
				university: universidad,
				gradYear: parseInt(gradYear)
			});

			if (result === 'user-updated') {
				nextStep('completa-tu-pago');
			} else {
				setError('Error actualizando tus datos personales');
				setLoading(false);
			}
		}
	};

	const handleSubmit = (
		e:
			| React.MouseEvent<HTMLButtonElement, MouseEvent>
			| React.FormEvent<HTMLFormElement>
	) => {
		setLoadingMessage('Actualizando datos personales...');
		setLoading(true);

		e.preventDefault();
		handleSignup()
			.then(() => {
				setTimeout(() => {
					setLoading(false);
				}, 1000);
			})
			.catch((err: string) => setError(`Error: ${err}`));
	};
	return (
		<FormContainer handleSubmit={(e) => handleSubmit(e)}>
			<Headline
				classes='text-brand-700 mb-8'
				isCentered={true}
				title='Información Adicional'
				type='h6'
			/>
			<SelectElement
				defaultOption='- Elije tu universidad -'
				id='universidad'
				infoMessage='Selecciona tu universidad'
				isInlineElem={false}
				label='Universidad'
				options={universities.DR}
				isRequired={true}
				handleChange={(value) => setUniversidad(value)}
			/>

			{universidad === 'otra' && (
				<TextInputForm
					id='universidad'
					label='Nombre de tu Universidad'
					placeholder='¿De cual universidad te graduaste?'
					handleChange={(e: string) => setUniversidadTyped(e)}
				/>
			)}

			<TextInputForm
				guideMessage='El formato debe ser: AAAA - EJEMPLO: "2010"'
				id='dob'
				isRequired={true}
				handleValidity={(e: boolean) => setIsValidGradYear(e)}
				label='Año de Graduación'
				placeholder='¿En que año te graduaste?'
				handleChange={(e: string) => setGradYear(e)}
				minLength={4}
				maxLength={4}
			/>
			{universidad != null && isValidGradYear && (
				<div className='flex items-center justify-end mt-4'>
					<Button
						title='Actualiza tus Datos'
						bgColor='bg-brand-500'
						hoverBgColor='hover:bg-brand-550'
						handleClick={(e) => handleSubmit(e)}
						type='submit'
					/>
				</div>
			)}
			<ErrorMessage error={error} showError={error !== ''} />
		</FormContainer>
	);
};
export default InformacionAdicional;
