import React, { createContext, useContext, useReducer, useState } from 'react';
import type { ReactNode } from 'react';
import { db, handleEventLog } from '../gcp/config';
import {
	collection,
	doc,
	getDoc,
	getDocs,
	setDoc,
	updateDoc
} from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import useUserContext from './UserContext';
import { deleteAllCookies } from '../utils/helpers';
import type { FirebaseError } from 'firebase/app';

export type BusinessContextType = {
	webAppVersion: number;
	setWebAppVersion: (v: number) => void; // setBool: React.Dispatch<React.SetStateAction<boolean>>;
	getLatestWebVersionNumber: () => Promise<number>;
	hasLatestWebAppVersion: () => Promise<void>;
	getRecursosDownloadURL: (path: string) => Promise<string>;
};
export const BusinessContext = createContext<BusinessContextType | null>(null);
export const BusinessContextProvider = ({
	children
}: {
	children: ReactNode;
}) => {
	const { currentUser } = useUserContext();
	// #region WEB APP VERSION
	const [webAppVersion, setWebAppVersion] = useState<number>(0);

	const getLatestWebVersionNumber = async (): Promise<number> => {
		let webAppV = 0;
		const versionRef = doc(db, 'business', 'webAppVersion');
		await getDoc(versionRef).then((v) => {
			if (v.exists()) {
				webAppV = v.data().number;
				setWebAppVersion(webAppV);
			}
		});
		return webAppV;
	};

	// TODO: Check for web app version on user loaded
	const hasLatestWebAppVersion = async () => {
		const version = await getLatestWebVersionNumber();

		const isValidUser = currentUser !== null;
		const isValidVersion = isValidUser && currentUser?.appVersionWeb !== null;
		const hasValidUserAndVersion = isValidUser && isValidVersion;
		const hasWebAppVersion =
			hasValidUserAndVersion &&
			Object.prototype.hasOwnProperty.call(currentUser, 'appVersionWeb');
		const userWebAppVersion = hasWebAppVersion
			? currentUser.appVersionWeb ?? 0
			: 0;

		if (hasWebAppVersion && version > userWebAppVersion) {
			await updateUserWebAppVersion(true, version);
			if (isValidUser && (currentUser.isAdmin ?? false)) {
				await updateUserWebAppVersion(false, version);
			}
		}

		if (hasWebAppVersion && version === 0) {
			await updateUserWebAppVersion(false, version);
		}
	};

	const updateUserWebAppVersion = async (bool: boolean, version: number) => {
		if (currentUser === null) return; // TODO: HANDLE NO USER FOUND
		if (currentUser.uid === undefined) return; // TODO: HANDLE NO UID FOUND
		const userRef = doc(db, 'users', currentUser.uid);
		if (bool) {
			await updateDoc(userRef, {
				appVersionWeb: version
			});
			deleteAllCookies();
		} else {
			await setDoc(
				userRef,
				{
					appVersionWeb: version
				},
				{ merge: true }
			);
		}
	};

	const getRecursosDownloadURL = async (path: string) => {
		let downloadURL = '';
		const storage = getStorage();
		const pathReference = ref(storage, `recursos/${path}`);

		// let thisPath = await pathReference.getDownloadURL();
		// return thisPath;
		await getDownloadURL(pathReference)
			.then((url) => {
				// `url` is the download URL for 'images/stars.jpg'
				downloadURL = url;
				// // This can be downloaded directly:
				// const xhr = new XMLHttpRequest();
				// xhr.responseType = 'blob';
				// xhr.onload = (event) => {
				// const blob = xhr.response;
				// };
				// xhr.open('GET', url);
				// xhr.send();

				// // Or inserted into an <img> element
				// const img = document.getElementById('myimg');
				// img?.setAttribute('src', url);
			})
			.catch((error: FirebaseError) => {
				// Handle any errors
				// https://firebase.google.com/docs/storage/web/handle-errors
				console.error('RECURSOS_DOWNLOAD_ERROR', {
					...error,
					fileName: path
				});
				handleEventLog('RECURSOS_DOWNLOAD_ERROR', {
					...error,
					fileName: path
				});
			});

		return downloadURL;
	};
	// #endregion

	return (
		<BusinessContext.Provider
			value={{
				webAppVersion,
				setWebAppVersion,
				getLatestWebVersionNumber,
				hasLatestWebAppVersion,
				getRecursosDownloadURL
			}}>
			{children}
		</BusinessContext.Provider>
	);
};
export const useBusinessContext = () => {
	const context = useContext(BusinessContext);
	if (context === undefined || context === null) {
		throw new Error(
			'useBusinessContext must be used within a BusinessContextProvider'
		);
	}
	return context;
};
export default BusinessContextProvider;
