import routes from '../../config/routes';
import Logo from '../../components/Logo';
import { Bars3Icon } from '@heroicons/react/24/outline';
import type { NavLink, Navigation } from '../../data/interface-navigation';
import Productos from './Productos';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../api/AuthContext';

interface NavProps {
	navigation: Navigation;
	isPrivateRoute: boolean;
	toggleMenu: (b: boolean) => void;
}

const Nav = ({ navigation, toggleMenu, isPrivateRoute }: NavProps) => {
	const { signOutCurrentUser } = useAuthContext();

	const signOut = () => {
		signOutCurrentUser()
			.then(() => {
				console.log('User signed out successfully');
			})
			.catch((error) => {
				console.error('Error signing out: ', error);
			});
		toggleMenu(false);
	};

	return (
		<nav
			className='flex items-center justify-between p-6 lg:px-8'
			aria-label='Global'>
			<div className='flex lg:flex-1'>
				<Link to={routes.HOME} className='-m-1.5 p-1.5'>
					<span className='sr-only'>PrepMedRD</span>
					<Logo useDarkLogo={true} classes='w-auto h-10' />
				</Link>
			</div>
			<div className='flex lg:hidden'>
				<button
					type='button'
					className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
					onClick={() => toggleMenu(true)}>
					<span className='sr-only'>Open main menu</span>
					<Bars3Icon className='h-6 w-6' aria-hidden='true' />
				</button>
			</div>
			<div className='hidden lg:flex items-center lg:gap-x-12'>
				{!isPrivateRoute &&
					navigation.map((item: NavLink) =>
						item.name === 'Programas' ? (
							<Productos key={item.name} />
						) : (
							<Link
								key={item.name}
								to={item.href}
								className={`text-sm font-semibold leading-6 text-gray-900 ${
									item.isSpecial ?? false
										? 'hover:bg-brand-400 hover:text-gray-100 px-2 rounded'
										: ''
								}`}>
								{item.name}
							</Link>
						)
					)}
			</div>
			<div className='hidden lg:flex lg:flex-1 lg:justify-end'>
				{isPrivateRoute ? (
					<Link
						to={routes.HOME}
						className='text-sm font-semibold leading-6 text-gray-900'>
						Signout
					</Link>
				) : (
					<Link
						to={routes.AUTHENTICATION.LOGIN}
						onClick={() => signOut()}
						className='text-sm font-semibold leading-6 text-gray-900'>
						Acceder <span aria-hidden='true'>&rarr;</span>
					</Link>
				)}
			</div>
		</nav>
	);
};

export default Nav;
