import { useEffect, type SyntheticEvent } from 'react';
import { useFlashcardContext } from '../../../../api/FlashcardsContext';

interface UploadFlashcardImageProps {
	onUpload: (imgPath: string) => void;
}

const UploadFlashcardImage = ({ onUpload }: UploadFlashcardImageProps) => {
	const { uploaderProgress, setUploaderProgress, uploadFlashcardImage } =
		useFlashcardContext();

	const handleUpload = (e: SyntheticEvent) => {
		const target = e.target as HTMLInputElement;
		if (target.files !== null) {
			const image: File = target.files[0];
			onUpload(image.name);
			uploadFlashcardImage(image, image.name)
				.then(() => {
					// TODO: Image uploaded successfully
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	useEffect(() => {
		return () => {
			setUploaderProgress(0);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<>
			<div className='flex flex-col justify-center items-center mt-8'>
				<progress
					value={uploaderProgress}
					max='100'
					id='uploader'
					className='mb-4'>
					0%
				</progress>

				{uploaderProgress === 100 ? (
					// TODO: Enhance with success alert
					<div>Imagen guardada.</div>
				) : (
					<input
						id='fileButton'
						type='file'
						accept='image/jpeg, image/png'
						className='mt-1 border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50'
						name='fileUpload'
						onChange={handleUpload}
					/>
				)}
			</div>
		</>
	);
};

export default UploadFlashcardImage;
