import { useEffect, useState } from 'react';
import { useBusinessContext } from '../../../api/BusinessContext';
import Headline from '../../../components/ui/Headline';
import { useLoadingContext } from '../../../context/LoadingContext';
import { handleEventLog } from '../../../gcp/config';
import MainSection from '../../../layout/app/dashboard/MainSection';

const RecursosDashboard = () => {
	const { getRecursosDownloadURL } = useBusinessContext();
	const { showSpinner, hideSpinner } = useLoadingContext();
	const [guiaLink, setGuiaLink] = useState<string>('');

	useEffect(() => {
		handleDownloadFile();
	}, []);

	const handleDownloadFile = () => {
		showSpinner('Descargando Guía de Preparación...');
		const path = 'GUIA_DE_PREPARACION_PARA_EL_ENURM.pdf';
		getRecursosDownloadURL(path)
			.then((url) => {
				setGuiaLink(url);
				hideSpinner();
			})
			.catch((err) => {
				handleEventLog('DOWNLOAD_ERROR', { path, err });
				console.error('DOWNLOAD_ERROR', { path, err });
				hideSpinner();
			});
	};

	return (
		<MainSection title='Recursos'>
			<div className='border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between mt-4'>
				<div>
					<Headline title='Guía de Preparación' type='h6' />
					<p className='mt-2 max-w-4xl text-sm text-gray-500'>
						Nuestra &quot;Guía de Preparación para el Examen Nacional Único de
						Residencias Médicas (ENURM)&quot; es un documento{' '}
						<strong>completo</strong>, diseñado para ayudar a los candidatos a
						prepararse de manera efectiva para el examen estandarizado en la
						República Dominicana. En este documento los estudiantes recibirán
						pautas a seguir para mejorar la calidad de sus estudio y consejos
						para ser mejores opositores de pruebas estandarizadas como lo es el
						ENURM.
					</p>
				</div>
				<div className='mt-3 flex sm:ml-4 sm:mt-0'>
					{/* <button
						type='button'
						className='inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>
						Share
					</button> */}
					{guiaLink !== '' && (
						<a
							type='button'
							href={guiaLink}
							rel='noreferrer'
							target='_blank'
							className='ml-3 inline-flex items-center rounded-md bg-brand-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500'
							download>
							Descargar
						</a>
					)}
				</div>
			</div>
		</MainSection>
	);
};
export default RecursosDashboard;
