import { createContext, useContext, useState } from 'react';
import { db } from '../gcp/config';
import {
	doc,
	getDoc,
	setDoc,
	updateDoc,
	addDoc,
	collection,
	onSnapshot
} from 'firebase/firestore';
import type { ReactNode } from 'react';
import type {
	FreeTrial,
	PersonEducation,
	PersonName,
	User
} from '../data/interface-user';
import type {
	ProductData,
	QuestionBankID
} from '../data/interface-question-bank';
import { buildProductDataObject, buildUserObject } from './xBuildObjectUtils';
import routes from '../config/routes';
import type { Annnouncement } from '../data/marketing';
import { deleteAllCookies } from '../utils/helpers';
import { set } from 'lodash';

export type UserContextType = {
	announcement: Annnouncement | undefined;
	getMarketing: () => Promise<void>;
	setAnnouncement: React.Dispatch<
		React.SetStateAction<Annnouncement | undefined>
	>;
	currentUser: User | null;
	setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
	getUserFromFirestore: (
		uid: string,
		forceReqToDB?: boolean
	) => Promise<User | null>;
	getProductByID: (exam: QuestionBankID) => Promise<ProductData | null>;
	product: ProductData | null;
	createUserInFirestore: (user: User) => Promise<string>;
	updatePersonName: (uid: string, person: PersonName) => Promise<string>;
	updateExamTarget: (exam: QuestionBankID) => Promise<string>;
	updateEducationInfo: (education: PersonEducation) => Promise<string>;
	startFreeTrial: () => Promise<boolean>;
	endFreeTrial: () => Promise<boolean>;
	getStripeChekoutURL: (priceID: string) => Promise<string>;
	updateToPaidViaStripe: () => Promise<void>;
	updateToPaidViaPayPal: () => Promise<void>;
	updateIsLoggedIn: (uid: string, isLogged: boolean) => Promise<string>;
	[key: string | number | symbol]: unknown;
};

// Create the context
export const UserContext = createContext<UserContextType | null>(null);

export const UserContextProvider = ({ children }: { children: ReactNode }) => {
	const [currentUser, setCurrentUser] = useState<User | null>(null);
	const [product, setProduct] = useState<ProductData | null>(null);
	const [announcement, setAnnouncement] = useState<Annnouncement>();

	const getLoginCode = (): number => {
		let randomNum: number;
		const loginCode = localStorage.getItem('loginCode');
		if (loginCode === null) {
			// This code works by generating a random number between 0 (inclusive) and 1 (exclusive), multiplying it by 9000, and then adding 1000. The Math.floor() function is used to round down to the nearest whole number. This ensures that the result is a 4-digit number between 1000 (inclusive) and 9999 (inclusive).
			// randomNum = Math.floor(Math.random() * 9000) + 1000;
			randomNum = Number(navigator.userAgent.replaceAll(/\D/g, ''));
			localStorage.setItem('loginCode', randomNum.toString());
		} else {
			randomNum = Number(loginCode);
		}
		return randomNum;
	};

	const createUserInFirestore = async (user: User) => {
		if (user.uid === undefined) {
			// TODO: HANDLE NO UID FOUND
			return 'no-uid-found';
		}
		const userDocRef = doc(db, 'users', user.uid);
		const r = setDoc(userDocRef, {
			...user,
			loginCode: getLoginCode()
		})
			.then(() => {
				console.log('Document written with ID: ', user.uid);
				return 'user-created';
			})
			.catch((error) => {
				console.error('Error adding user document: ', error);
				return error;
			});

		return await r;
	};

	const updateIsLoggedIn = async (uid: string, isLogged: boolean) => {
		// https://firebase.google.com/docs/auth/admin/manage-sessions#revoke_refresh_tokens
		let result = '';
		const userRef = doc(db, 'users', uid);

		try {
			if (isLogged) {
				let code: number;
				const loginCode = localStorage.getItem('loginCode');
				if (loginCode === null) {
					code = getLoginCode();
					localStorage.setItem('loginCode', code.toString());
					await updateDoc(userRef, {
						isLoggedIn: true,
						loginCode: code
					});
					result = code.toString();
				} else {
					result = loginCode.toString();
				}
			} else {
				localStorage.removeItem('loginCode');
				await updateDoc(userRef, {
					isLoggedIn: false,
					loginCode: 0
				});

				result = 'user-updated';
			}
		} catch (error) {
			console.error('Error updating user document: ', error);
			result = 'unable-to-update-user';
		}
		return result;
	};

	const updatePersonName = async (uid: string, name: PersonName) => {
		const userRef = doc(db, 'users', uid);
		let person: PersonName = {
			firstName: name.firstName,
			lastName: name.lastName,
			initials: `${name.firstName[0]}${name.lastName[0]}`,
			dob: name.dob,
			cedula: name.cedula
		};

		if (name.middleName != null) {
			person = { ...person, middleName: name.middleName };
		}

		if (name.secondLastName != null) {
			person = { ...person, secondLastName: name.secondLastName };
		}

		let result = '';
		try {
			await updateDoc(userRef, {
				person
			});
			result = 'user-updated';
		} catch (error) {
			console.error('Error updating user document: ', error);
			result = 'unable-to-update-user';
		}
		return result;
	};

	const updateExamTarget = async (bank: QuestionBankID) => {
		let result = '';
		const prod: ProductData | null = await getProductByID(bank);

		if (currentUser !== null) {
			if (currentUser.uid !== undefined) {
				const userRef = doc(db, 'users', currentUser.uid);
				try {
					await updateDoc(userRef, {
						examTarget: bank,
						examTargetYear: prod?.nextExamTargetYear
					});
					result = 'exam-target-updated';
				} catch (error) {
					console.error('Error updating user document: ', error);
					result = 'unable-to-update-exam-target';
				}
			} else {
				// TODO: HANDLE NO UID FOUND
			}
		} else {
			// TODO: HANDLE NO USER FOUND
		}

		return result;
	};

	const updateEducationInfo = async (education: PersonEducation) => {
		let result = '';

		if (currentUser !== null) {
			if (currentUser.uid !== undefined) {
				const userRef = doc(db, 'users', currentUser.uid);
				try {
					await updateDoc(userRef, { education });
					result = 'user-updated';
				} catch (error) {
					console.error('Error updating user document: ', error);
					result = 'unable-to-update-exam-target';
				}
			} else {
				// TODO: HANDLE NO UID FOUND
			}
		} else {
			// TODO: HANDLE NO USER FOUND
		}
		return result;
	};

	// TODO: Can I get this from the Stripe product metadata?
	const getProductByID = async (
		examID: QuestionBankID
	): Promise<ProductData | null> => {
		let examData: ProductData | null = null;
		if (product !== null && product.examID === examID) {
			return product;
		} else {
			const examRef = doc(db, 'products', examID);
			const docSnap = await getDoc(examRef);

			if (docSnap.exists()) {
				const data = { ...docSnap.data() };
				examData = buildProductDataObject(examID, data);
			} else {
				// console.log('No such document!');
				examData = null;
			}
			setProduct(examData);
			return examData;
		}
	};

	const getUserFromFirestore = async (
		uid: string,
		forceReqToDB = false
	): Promise<User | null> => {
		let user: User | null = null;
		if (currentUser === null || currentUser === undefined || forceReqToDB) {
			const userRef = doc(db, 'users', uid);
			const docSnap = await getDoc(userRef);

			if (docSnap.exists()) {
				const userData = { ...docSnap.data() };
				const isLoggedIn: boolean | undefined = userData.isLoggedIn;

				let loginCode;
				if (isLoggedIn === true) {
					loginCode = localStorage.getItem('loginCode');
					if (
						Number(loginCode) === userData.loginCode &&
						Number(loginCode) !== 0
					) {
						// Keep on going
					} else {
						loginCode = await updateIsLoggedIn(uid, false);
					}
				} else {
					loginCode = await updateIsLoggedIn(uid, true);
				}

				user = buildUserObject(uid, {
					...userData,
					loginCode: Number(loginCode)
				});
			} else {
				user = null;
			}
			setCurrentUser(user);

			const unsubscribe = onSnapshot(
				userRef,
				(snapshot) => {
					const userSnapshot = snapshot.data();
					// const changes = snapshot.docChanges();
					// changes.forEach((change) => {
					// if (change.type === 'added') {
					// console.log('New city: ', change.doc.data());
					// }
					// if (change.type === 'modified') {
					// console.log('Modified city: ', change.doc.data());
					// // logOutUser();
					// }
					// if (change.type === 'removed') {
					// console.log('Removed city: ', change.doc.data());
					// // logOutUser();
					// }
					// });
					const loginCode = localStorage.getItem('loginCode');
					if (
						loginCode !== null &&
						userSnapshot?.loginCode !== Number(loginCode)
					) {
						localStorage.setItem(
							'loginCode',
							userSnapshot?.loginCode.toString()
						);
					}
				},
				(error) => {
					console.error('onSnapshot error: ', error);
				}
			);
		} else {
			user = currentUser;
		}

		return user;
	};

	// #region FREE TRIAL
	const startFreeTrial = async (): Promise<boolean> => {
		if (currentUser === null || currentUser.uid === undefined) return false;
		const userRef = doc(db, 'users', currentUser.uid);
		try {
			const freeTrial: FreeTrial = {
				isActive: true,
				startDate: new Date()
			};
			await updateDoc(userRef, { freeTrial });
			setCurrentUser({ ...currentUser, freeTrial });
			return true;
		} catch (error) {
			console.error('Error updating user document: ', error);
			return false;
		}
	};

	const endFreeTrial = async (): Promise<boolean> => {
		if (currentUser === null || currentUser.uid === undefined) return false;
		if (currentUser.freeTrial === undefined) return false;
		const userRef = doc(db, 'users', currentUser.uid);
		try {
			const freeTrial: FreeTrial = {
				isActive: false,
				endDate: new Date()
			};
			await updateDoc(userRef, { freeTrial });
			const user = { ...currentUser };
			delete user.freeTrial;
			setCurrentUser({ ...user });
			// deleteAllCookies();
			return true;
		} catch (error) {
			console.error('Error updating user document: ', error);
			return false;
		}
	};
	// #endregion

	// #region PAYMENT METHODS
	const updateToPaidViaStripe = async () => {
		if (
			currentUser === null ||
			currentUser.uid === undefined ||
			currentUser.examTarget === undefined
		) {
			throw new Error('User is not authenticated');
		}

		const membership = {
			isPaid: true,
			paymentMethod: 'Stripe',
			paymentDate: new Date(),
			type: 'Customer'
		};
		const userRef = doc(db, 'users', currentUser.uid);

		if (product === null) {
			const qBank = await getProductByID(currentUser.examTarget);
			if (qBank === null) throw new Error('Product not found');
			await updateDoc(userRef, {
				[`membership_${qBank?.nextExamTargetYear}`]: membership
			});
		} else {
			await updateDoc(userRef, {
				[`membership_${product?.nextExamTargetYear}`]: membership
			});
		}

		if (currentUser.freeTrial !== undefined) {
			await endFreeTrial();
		}
	};

	const updateToPaidViaPayPal = async () => {
		if (
			currentUser === null ||
			currentUser.uid === undefined ||
			currentUser.examTarget === undefined
		) {
			throw new Error('User is not authenticated');
		}

		const membership = {
			isPaid: true,
			paymentMethod: 'PayPal',
			paymentDate: new Date(),
			type: 'Customer'
		};
		const userRef = doc(db, 'users', currentUser.uid);

		if (product === null) {
			const qBank = await getProductByID(currentUser.examTarget);
			if (qBank === null) throw new Error('Product not found');
			await updateDoc(userRef, {
				[`membership_${qBank?.nextExamTargetYear}`]: membership
			});
		} else {
			await updateDoc(userRef, {
				[`membership_${product?.nextExamTargetYear}`]: membership
			});
		}

		if (currentUser.freeTrial !== undefined) {
			await endFreeTrial();
		}
	};

	const PRODUCT_PAYPAL = {
		name: 'PrepMedRD',
		description:
			'Con un pago exitoso tendrás acceso a la plataforma y herramientas de estudio que provee PrepMedRD para facilitar tu preparación para el ENURM: simulaciones, flashcards, preguntas corregidas y validadas con explicaciones, entre otras cosas...',
		price: 107.99,
		amount: 10,
		images:
			'https://firebasestorage.googleapis.com/v0/b/prep-med.appspot.com/o/PrepMedRD.png?alt=media&token=cee40a46-90a9-466f-8a13-a33ad84c995a'
	};

	const getStripeChekoutURL = async (priceID: string): Promise<string> => {
		if (currentUser === null) throw new Error('User is not authenticated');
		const userID = currentUser.uid;
		if (userID === undefined) throw new Error('User is not authenticated');
		if (product === null) throw new Error('Product not found');
		const checkoutSessionRef = collection(
			db,
			'users',
			userID,
			'checkout_sessions'
		);

		const checkoutSessionDocRef = await addDoc(checkoutSessionRef, {
			mode: 'payment',
			price: product.stripePriceID, // One-time price created in Stripe
			success_url:
				`${window.location.origin}${routes.CHECKOUT.CHECKOUT_SUCCESS}${userID}` +
				'?pymt_source=stripe',
			cancel_url: `${window.location.origin}${routes.CHECKOUT.CHECKOUT_FAIL}`
		});
		// console.log('Document written with ID: ', checkoutSessionRef.id);

		return await new Promise<string>((resolve, reject) => {
			const unsubscribe = onSnapshot(checkoutSessionDocRef, (snap) => {
				const { error, url } = snap.data() as {
					error?: { message: string };
					url?: string;
				};
				if (error !== undefined) {
					unsubscribe();
					reject(new Error(`An error occurred: ${error.message}`));
				}
				if (url !== undefined) {
					console.log('Stripe Checkout URL:', url);
					unsubscribe();
					resolve(url);
				}
			});
		});
	};
	// #endregion

	// #region ANNOUNCEMENT
	const getMarketing = async () => {
		const marketingRef = doc(db, 'business', 'marketing');
		const docSnap = await getDoc(marketingRef);

		if (docSnap.exists()) {
			const data = { ...docSnap.data() };
			if (data.announcement !== undefined) {
				setAnnouncement(data.announcement);
			}
		}
	};

	// #endregion

	return (
		<UserContext.Provider
			value={{
				currentUser,
				setCurrentUser,
				getUserFromFirestore,
				getProductByID,
				product,
				updateIsLoggedIn,
				updatePersonName,
				updateExamTarget,
				updateEducationInfo,
				startFreeTrial,
				endFreeTrial,
				createUserInFirestore,
				getStripeChekoutURL,
				updateToPaidViaStripe,
				updateToPaidViaPayPal,
				// ANNOUNCEMENTS
				announcement,
				getMarketing,
				setAnnouncement
			}}>
			{children}
		</UserContext.Provider>
	);
};
const useUserContext = () => {
	const context = useContext(UserContext);
	if (context === undefined || context === null) {
		throw new Error('useUserContext must be used inside a CounterProvider');
	}
	return context;
};
export default useUserContext;
