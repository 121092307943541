import Tooltip from '../ui/Tooltip';

interface InputCheckboxTypes {
	guideMessage?: string;
	label: string;
	labelAdditionlInfo?: string;
	id: string;
	infoMessage?: string;
	isChecked: boolean;
	parentClass?: string;
	maxLength?: number;
	minLength?: number;
	pattern?: string;
	placeholder?: string;
	tooltip?: string;
	handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}
const InputCheckbox = ({
	guideMessage = '',
	id,
	infoMessage,
	isChecked,
	label,
	labelAdditionlInfo,
	maxLength,
	minLength,
	parentClass = 'relative flex items-start my-4',
	pattern,
	placeholder,
	handleChange,
	tooltip
}: InputCheckboxTypes) => {
	return (
		<div className={parentClass}>
			<div className='flex h-6 items-center'>
				<input
					id={id}
					aria-describedby='comments-description'
					name='comments'
					type='checkbox'
					checked={isChecked}
					className='h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-600'
					onChange={(e) => handleChange(e)}
				/>
			</div>
			<div className='flex ml-3 text-sm leading-6'>
				<label htmlFor={id} className='font-medium text-gray-900 mr-2'>
					{label}
				</label>
				{labelAdditionlInfo !== undefined && (
					<span id='comments-description' className='text-gray-500 mr-2'>
						<span className='sr-only'>{label} </span> {labelAdditionlInfo}
					</span>
				)}
				{tooltip !== undefined && <Tooltip message={tooltip} />}
			</div>
		</div>
	);
};
export default InputCheckbox;
