import FormLabel from './FormLabel';
import TextInput from './TextInput';

interface TextInputTypes {
	guideMessage?: string;
	icon?: string | null;
	iconFam?: string;
	iconPosition?: 'left' | 'right';
	id: string;
	infoMessage?: string;
	label?: string;
	maxLength?: number;
	minLength?: number;
	parentClass?: string;
	pattern?: string;
	placeholder?: string;
	isRequired?: boolean;
	handleChange: (value: string) => void;
	handleValidity?: (value: boolean) => void;
}

const TextInputForm = ({
	guideMessage,
	icon = null,
	iconPosition = 'left',
	iconFam = 'material-icons',
	id,
	infoMessage,
	label,
	parentClass = 'block w-full mb-4',
	pattern,
	maxLength,
	minLength,
	placeholder,
	isRequired = false,
	handleChange,
	handleValidity
}: TextInputTypes) => {
	return (
		<div className={parentClass}>
			{label !== undefined && (
				<FormLabel
					icon={icon}
					iconFam='material-icons'
					iconPosition={iconPosition}
					label={label}
					isRequired={isRequired}
				/>
			)}
			<TextInput
				guideMessage={guideMessage}
				id={id}
				infoMessage={infoMessage}
				label={label !== undefined ? label : ''}
				parentClass={parentClass}
				pattern={pattern}
				maxLength={maxLength}
				minLength={minLength}
				placeholder={placeholder}
				isRequired={isRequired}
				handleChange={(e: string) => handleChange(e)}
				handleValidity={(v: boolean) => handleValidity?.(v)}
			/>
		</div>
	);
};

export default TextInputForm;
