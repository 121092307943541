import Logo from '../../components/Logo';
import Button from '../../components/ui/Button';
import Headline from '../../components/ui/Headline';
import MaterialIcons from '../../components/ui/icons/MaterialIcons';
import { useLoadingContext } from '../../context/LoadingContext';
import MainTag from '../../layout/Main';
import { deleteAllCookies } from '../../utils/helpers';

const Contactanos = () => {
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const handleReload = () => {
		setLoadingMessage('Un momento por favor en lo que recarga la aplicación.');
		setLoading(true);
		deleteAllCookies();
		setTimeout(() => {
			setLoading(false);
			window.location.reload();
		}, 3000);
	};
	return (
		<>
			<MainTag>
				<div className='flex flex-col items-center mb-4 mt-10'>
					<MaterialIcons icon='contact_support' classes='text-5xl mr-2' />
					<Headline
						classes='text-sky-800 mb-8 '
						title='¿Necesitas comunicarte con nosotros?'
						type='h1'></Headline>
					<div className='w-1/2 '>
						<p>
							¿Tienes alguna pregunta, duda, observación o queja? ¡Por favor no
							dudes en contactarnos! Si tienes fotos o videos que enviarnos por
							favor escríbenos a nuestro{' '}
							<a href='mailto:ayuda@prepmed.org'>correo de soporte</a> al
							cliente: ayuda@prepmed.org.
						</p>

						<p>
							<strong>Nota:</strong> Si estás teniendo dificultades para cargar
							alguna página, trata el botón debajo para recargar la aplicación e
							intenta de nuevo. Si todavía estás teniendo inconvenientes, por
							favor escríbenos.
						</p>
					</div>
					<Button
						classes='mt-4'
						bgColor='bg-brand-500 flex items-center justify-center'
						hoverBgColor='hover:bg-brand-700'
						icon='refresh'
						iconPosition='left'
						title='Recargar App'
						handleClick={handleReload}
					/>
				</div>
			</MainTag>
		</>
	);
};
export default Contactanos;
