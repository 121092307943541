import { Link } from 'react-router-dom';

const FooterCopyright = () => {
	return (
		<div className='pt-2'>
			<div className='flex pb-5 px-3 m-auto pt-5 border-t border-gray-500 text-gray-400 text-sm flex-col md:flex-row max-w-6xl'>
				<div className='mt-2'>
					PrepMed © Copyright {new Date().getFullYear()} | Todos los derechos
					reservados. PrepMed LLC
				</div>

				{/* <!-- Required Unicons (if you want) --> */}
				<div className='md:flex-auto md:flex-row-reverse mt-2 flex-row flex text-white'>
					<Link to='!#' className='w-6 mx-1'>
						<i className='uil uil-instagram'></i>
					</Link>
					<Link to='!#' className='w-6 mx-1'>
						<i className='uil uil-facebook-f'></i>
					</Link>
					{/* <Link to='!#' className='w-6 mx-1'>
							<i className='uil uil-twitter-alt'></i>
						</Link> */}
					<Link to='!#' className='w-6 mx-1'>
						<i className='uil uil-youtube'></i>
					</Link>
					{/* <Link to='!#' className='w-6 mx-1'>
							<i className='uil uil-linkedin'></i>
						</Link> */}
				</div>
			</div>
		</div>
	);
};
export default FooterCopyright;
