import ContentSection from './ContentSection';
import HeroSection from './HeroSection';

const About = () => {
	return (
		<main className='isolate'>
			<HeroSection />
			<ContentSection />
		</main>
	);
};
export default About;
