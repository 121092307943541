import { useEffect } from 'react';

/**
 * Hides the first Element within the document that matches the specified selector and unhides it when the component is unmounted
 */
export default function useHideElementOnMount(elementSelector: string) {
	useEffect(() => {
		const elem = document.querySelector(`${elementSelector}`);
		if (elem != null && elem instanceof HTMLElement) {
			elem?.classList.add('hidden');
		}

		return () => {
			if (elem != null && elem instanceof HTMLElement) {
				elem?.classList.remove('hidden');
			}
		};
	}, [elementSelector]);
}
