import { Link } from 'react-router-dom';

const Announcement = () => {
	return (
		<div className='hidden sm:mb-8 sm:flex sm:justify-center'>
			<div className='relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20'>
				Lista de Espera para Cirugia Maxilofacial -{' '}
				<Link to='#' className='font-semibold text-purple-500'>
					<span className='absolute inset-0' aria-hidden='true' />
					Conoce Más <span aria-hidden='true'>&rarr;</span>
				</Link>
			</div>
		</div>
	);
};

export default Announcement;
