// ROUTING
import { Routes, Route } from 'react-router-dom';

// ROUTES
import routes from './config/routes';

// COMPONENTS
import Footer from './layout/footer/Footer';
import Header from './layout/Header';
import Home from './pages/public/home/Home';
import Login from './pages/public/Login';
import ComingSoon from './pages/ComingSoon';
import Signup from './pages/public/signup/Signup';
import LoadingFull from './components/loaders/LoadingFull';
import About from './pages/public/about/About';
import DashboardContainer from './pages/private/Dashboard';
import Contactanos from './pages/public/Contactanos';
import NuevoPassword from './pages/public/NuevoPassword';
import useUserContext from './api/UserContext';
import Checkout from './pages/private/sales/Checkout';
import PrivateRoutes from './config/routes/PrivateRoutes';
import { useEffect, useState } from 'react';

function PrepMed() {
	const [isPrivateRoute, setIsPrivateRoute] = useState(false);
	useEffect(() => {
		setIsPrivateRoute(window.location.pathname.includes('/app'));
	}, [isPrivateRoute]);

	return (
		<>
			<LoadingFull />
			{!isPrivateRoute && <Header />}
			<Routes>
				{/* PUBLIC PAGES */}
				<Route path={routes.PUBLIC.COMING_SOON} element={<ComingSoon />} />
				<Route path={routes.HOME} element={<Home />} />
				<Route path={routes.AUTHENTICATION.LOGIN} element={<Login />} />
				<Route
					path={routes.AUTHENTICATION.NEW_PASSWORD}
					element={<NuevoPassword />}
				/>
				<Route path={routes.AUTHENTICATION.SIGNUP} element={<Signup />} />
				<Route path={routes.PUBLIC.ABOUT_US} element={<About />} />
				<Route path={routes.PUBLIC.CONTACT_US} element={<Contactanos />} />
				{/* PRIVATE PAGES */}
				{/* // https://youtu.be/Ul3y1LXxzdU?t=1008 */}
				<Route path={routes.CHECKOUT.CHECKOUT} element={<Checkout />} />
				{/* 404 Page
				<Route path="*" element={404Page} /> */}
			</Routes>
			<PrivateRoutes />
			{!isPrivateRoute && <Footer />}
		</>
	);
}

export default PrepMed;
