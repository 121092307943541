import { useEffect, useState } from 'react';
import useQuestionBankContext from '../../../api/QuestionBankContext';
import { set } from 'lodash';
import { useLoadingContext } from '../../../context/LoadingContext';
import { handleEventLog } from '../../../gcp/config';

interface ExplanationWithAudioProps {
	audioFileName: string;
}

const AudioExplanation = ({ audioFileName }: ExplanationWithAudioProps) => {
	const { getQuestionAudioURL } = useQuestionBankContext();
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const [audioURL, setAudioURL] = useState<string | undefined>();

	useEffect(() => {
		if (audioFileName === '' || audioFileName === undefined) return;
		setLoadingMessage('Cargando exclipación en audio...');
		setLoading(true);
		getQuestionAudioURL(audioFileName)
			.then((url) => {
				setAudioURL(url);
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				handleEventLog('AUDIO_DISPLAY_ERROR', { audioFileName, err });
				console.error('AUDIO_DISPLAY_ERROR', { audioFileName, err });
			});

		// CLEANUP
		return () => {
			setAudioURL(undefined);
		};
	}, [audioFileName]);

	if (audioURL === undefined) return null;
	return (
		// TODO: Enhancement - Can I style this audio player?
		<div className='my-4'>
			<audio controls>
				<source src={audioURL} type='audio/mpeg' />
				Your browser does not support the audio element.
			</audio>
		</div>
	);
};
export default AudioExplanation;
